@import "src/styles/colors.scss";


.course_card {
	&__buy_button {
		width: 100%;
		margin-bottom: 10px;
	}

	&__secondary {
		background-color: $secondary;
	}
}
