@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.goals_list {
	@include scrollbar(4px, rgba(#DCDCDC, 0.5));
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: auto;
	margin-bottom: 20px;
}
