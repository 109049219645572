@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.player_video {
	position: relative;
	aspect-ratio: 16/9;
	max-height: 640px;
	margin: 0 auto;
	overflow: hidden;
	background-color: $background-player;

	@include media-extra-small-down() {
		max-height: 230px;
	}

	@include media-extra-small-only2() {
		max-height: 386px;
	}

	&__wrap {
		position: relative;
	  }
	  
	  &__inner {
		position: absolute;
		left: 0;
	  }

	&__alert {
		top: 100px;
	}

	&__pause_click_zone {
		position: absolute;
		width: 100%;
    	height: calc(100% - 65px);
    	z-index: 10;
		cursor: pointer;

	}
}
