@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/tile.scss";
@import "src/styles/mixins";

.influencer_layout {
	&__submenu {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $background-layout-header;
		width: 100%;
		padding: 11px 60px;

		@include media-extra-small-down() {
			padding: 11px 16px;
		}

		@include media-extra-small-only2() {
			padding: 11px 32px;
		}
	}

	&__icon {
		cursor: pointer;
		path {
			stroke: $text-layout-header;
		}
	}
}

.influencer {
	padding: 0 74px 30px 74px;

	@include media-extra-small-down() {
		padding: 15px;
	}

	@include media-extra-small-only2() {
		padding: 15px;
	}

	&__title {
		font-weight: 700;
		font-size: 36px;
		line-height: 36px;
		color: $text-general;
		margin: 0;
		padding-bottom: 24px;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 400px);
		gap: 32px;

		@include media-medium-only {
			grid-template-columns: repeat(3, 400px);
		}

		@include media-small-only {
			grid-template-columns: repeat(2, 400px);
		}

		@include media-extra-small-down() {
			grid-template-columns: repeat(1, 100vw);
			row-gap: 16px;
		}

		@include media-extra-small-only2() {
			grid-template-columns: repeat(1, 100vw);
			row-gap: 16px;
		}
	}

	&__loading {
		min-height: 415px;
	}

	&__tile_horiz {
		grid-column: auto / span 2;
	}

	&__tile_vert {
		grid-row: auto / span 2;
	}
}
