@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.player_quiz {
	position: relative;
	overflow: hidden;
	height: 640px;
	display: grid;
	align-items: center;
	white-space: pre-line;

	@include media-extra-small-down() {
		height: 50vh;
		padding: 10px;
	}

	@include media-extra-small-only2() {
		height: 50vh;
	}

	&__theme__light {
		background-color: $background-white;
	}

	&__theme__dark {
		background-color: #151515;
	}

	&__theme_switch {
		position: absolute;
		right: 24px;
		top: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;

		@include media-extra-small-down() {
			right: 10px;
		}
	}

	&__theme_label {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.15px;
		margin-right: 8px;
	}

	&__theme_label__light {
		color: $text-general;
	}

	&__theme_label__dark {
		color: $common-gray;
	}

	&__wrap {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		min-width: 526px;
		margin: 0 auto;
		max-height: 80%;
		overflow-y: auto;
		max-width: 90%;

		@include media-extra-small-down() {
			min-width: 100%;
			max-height: 100%;
		}
	}

	&__wrap__small {
		width: 526px;

		@include media-extra-small-down() {
			width: 100%;
			min-width: 100%;
			max-height: 100%;
		}
	}

	&__header {
		font-weight: 700;
		font-size: 24px;
		line-height: 36px;
		padding-bottom: 24px;
	}

	&__body {
		padding-bottom: 24px;
	}

	&__footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include media-extra-small-down() {
			padding-bottom: 62px;
		}
	}

	&__footer__small {
		gap: 8px;

		@include media-extra-small-down() {
			padding-bottom: 0;
		}

		@include media-extra-small-only2() {
			padding-bottom: 0;
		}
	}

	&__footer__center {
		justify-content: center;
		align-items: flex-start;
	}

	&__footer_start {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		row-gap: 5px;
	}

	&__name {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
		padding-bottom: 28px;
	}

	&__form {
		width: 100%;
	}

	&__inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 24px;

		&:last-child {
			padding-bottom: 0;
		}
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__answer_name {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.15px;
		text-align: left;
	}

	&__input_error {
		padding-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		left: 0;
		bottom: -22px;

		&_box {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}

		&_text {
			font-family: $font-family-base;
			font-size: 13px;
			color: $error-main;
			cursor: default;
		}
	}

	&__rating_box {
		position: relative;
	}

	&__description {
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.15px;
		margin-bottom: 15px;
	}

	&__single_attempt {
		color: $primary;
	}

	&__wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		width: 100%;
	}

	&__btn_result {
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.15px;
		margin-bottom: 15px;
		font-weight: normal;
		color: $primary;
		text-decoration: underline;
	}

	&__disabled {
		pointer-events: none;
		opacity: 0.4;
	}
}


	.checkbox {
		border-radius: 2px;
		background: transparent;
		filter: grayscale(1);

		&__correct{
			filter: hue-rotate(120deg);

		}

		&__incorrect {
			filter: grayscale(0.1);

		}
	}
