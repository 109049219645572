@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/header";

.inf_layout {
	@include header;
	@include header_line;
	flex-direction: column;
	justify-content: flex-start;

	&__header {
		padding-top: 40px;
		height: auto !important;
	}

	&__influencers {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		width: 100%;
		overflow: hidden;

		@include media-extra-small-down() {
			width: 100%;
		}
	}


	&__nav {
		width: 100%;
		background-color: $background-layout-header;
		display: flex;
		align-items: center;
		padding-left: 10px;
	}

	& #{&}__menu {
		@include media-extra-small-down() {
			justify-content: center;
			gap: 8px;
			padding-top: 0;
		}

		@include media-extra-small-down() {
		}
	}
}
