@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/tile.scss";
@import "src/styles/mixins.scss";

.influencer_card {
	@include common-tile();
	width: 100%;
	padding: 0 40px 32px;

	@include media-small-down() {
		padding: 0 16px 16px;
		border-radius: 0;
		width: 100%;
	}

	@include media-extra-small-down() {
		padding: 0 10px 10px;
		border-radius: 0;
		width: 100%;
	}

	&__wrapper {
		width: 100%;
		height: 100%;
		position: relative;
		color: $text-general;
		overflow: hidden;
	}

	&__right_section {
		@include media-extra-small-down() {
			flex-direction: row;
			justify-content: space-between;
			position: absolute;
			top: 35px;
			right: 20px;
		}

		@include media-little-mobile-down() {
			top: 30px;
			right: 15px;
		}
	}

	&__container {
		display: flex;
		justify-content: center;
		margin: 32px clamp(10vw, 13vw, 15vw) 45px;

		@include media-small-down() {
			margin: 0;
		}

		@include media-extra-small-down() {
			margin: 0;
		}
	}

	&__description_container {
		display: flex;
		margin-bottom: 25px;
		width: 100%;

		@include media-small-down() {
			flex-direction: column;
		}
	}

	&__color_bar {
		height: 6px;
		border-radius: 63px;
	}

	&__main_info_block {
		display: flex;
		justify-content: space-between;
		padding-top: 50px;

		@include media-small-down() {
			padding: 20px 32px 0 37px;
		}

		@include media-extra-small-down() {
			display: block;
			padding: 15px 11px 0 12px;
		}
	}

	&__image_container {
		opacity: 1;
		margin-right: 32px;
		border-radius: 74px;
		width: 250px;
		height: 176px;
		transition: 0.5s ease;
		position: relative;
		background-color: $background-dark;
		min-width: 250px;

		img {
			border-radius: 74px;
		}

		@include media-small-down() {
			width: 191px;
			height: 128px;
			min-width: auto;
		}

		@include media-extra-small-down() {
			width: 173px;
			height: 116px;
			margin-right: 24px;
			min-width: auto;
		}
	}

	&__box_title {
		margin-bottom: 20px;
	}

	&__left_section {
		margin-right: 20px;
		max-width: calc(100% - 166px);

		@include media-extra-small-down() {
			margin-right: 0;
			width: 100%;
			max-width: 100%;
		}
	}

	&__influencer_name {
		@include ellipsis;
		font-weight: 500;
		font-size: 48px;
		margin-bottom: 8px;


		@include media-extra-small-down() {
			font-size: 36px;
			max-width: 100%;
		}

	}

	&__details_container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: calc(100% - 282px);

		@include media-extra-small-down() {
			max-width: 100%;
		}

		@include media-extra-small-only2() {
			max-width: 100%;
		}

	}

	&__description {
		@include ellipsis;
		font-size: 18px;
		line-height: 26px;

		@include media-extra-small-down() {
			font-size: 16px;
			line-height: 24px;
			max-width: 100%;
		}

		@include media-extra-small-only2() {
		}
	}

	&__likes_followers {
		display: flex;
	}

	&__likes,
	&__followers {
		color: $common-gray;
		line-height: 24px;
	}

	&__likes_number,
	&__followers_number {
		font-size: 24px;
		line-height: 36px;

		@include media-extra-compact-only() {
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__button {
		width: 100px;
	}

	&__button_follow {
		margin-bottom: 20px;
	}

	&__content_info_section {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;

		:last-child {
			margin-right: 0;
		}
	}

	&__content_info {
		margin: 0 8px 4px 0;
		padding: 0 12px;
		background: $secondary;
		border-radius: 37px;
		font-size: 14px;
		line-height: 24px;
		color: $text-secondary;
	}

	&__button_empty {
		background-color: $background-tile;
		color: $text-primary;
		border: 2px solid $text-primary;
		transition: background-color, color .3s ease-in-out;

		&:hover {
			color: white;
		}
	}

	&__nodata {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		min-height: 420px;

		font-family: $font-family-base;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;
		color: $error-dark;
		width: 100%;
	}

	&__empty {
		min-height: 30vh;
	}

	&__divider {
		filter: brightness(2);
		border-right: 2px solid $background-tile;
		height: 44px;
		margin: 7px 32px 5px 32px;
		@include media-extra-small-down {
			margin: 6px;
		}
	}
}
