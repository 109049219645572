@import 'src/styles/colors';
@import 'src/styles/variables';

.custom_checkbox {
	position: relative;
	display: flex;
	width: fit-content;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	&__input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		&:not(:disabled):not(:checked) + .custom-checkbox__label:hover::before {
			background: $common-white;
		}
	}

	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		cursor: pointer;
		line-height: 18px;

		&::before {
			content: '';
			box-sizing: border-box;
		}
	}

	&__disabled {
		color: $other-disable-gray;

		.custom_checkbox__label {
			cursor: default;
		}

		.custom_checkbox__label::before {
			pointer-events: none;
			opacity: 0.4;
		}
	}

	&__theme_default {
		.custom_checkbox__input + .custom_checkbox__label::before {
			width: 18px;
			height: 18px;
			border-width: 1px;
			border-style: solid;
			margin-right: 12px;
			background: $common-white;
			border-radius: 2px;
		}

		.custom_checkbox__input:checked + .custom_checkbox__label::before {
			background: no-repeat 3px 4px url("../../../assets/icons/ic_check.svg"), $common-white;
		}

	}

	&__theme_transparent {
		.custom_checkbox__input + .custom_checkbox__label::before {
			width: 18px;
			height: 18px;
			border-radius: 4px;
		}

		.custom_checkbox__input:checked + .custom_checkbox__label::before {
			background: no-repeat -7px url("../../../assets/icons/ic_quiz_checkbox_check.svg"), transparent;
		}

	}

	&__theme_quiz {
		.custom_checkbox__input + .custom_checkbox__label::before {
			width: 32px;
			height: 32px;
			margin-right: 12px;
			background: $common-white;
		}

		.custom_checkbox__input[type=radio] + .custom_checkbox__label::before {
			background: no-repeat 0 0 url("../../../assets/icons/ic_quiz_radio.svg"), transparent;
		}

		.custom_checkbox__input[type=radio]:checked + .custom_checkbox__label::before {
			background: no-repeat 0 0 url("../../../assets/icons/ic_quiz_radio_check.svg"), transparent;
		}

		.custom_checkbox__input[type=checkbox] + .custom_checkbox__label::before {
			background: no-repeat 0 0 url("../../../assets/icons/ic_quiz_checkbox.svg"), transparent;
		}

		.custom_checkbox__input[type=checkbox]:checked + .custom_checkbox__label::before {
			background: no-repeat 0 0 url("../../../assets/icons/ic_quiz_checkbox_check.svg"), transparent;
		}
	}

	&__input_error {
		padding-top: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&_icon {
			margin-right: 10px;
		}

		&_text {
			font-family: $font-family-base;
			font-size: 14px;
			color: $error-main;
			cursor: default;
			padding-top: 2px;
		}
	}

	&__theme_cms, &__theme_assignments,&__theme_locked {
		.custom_checkbox__label {
			position: relative;
			height: 20px;
			width: 20px;
			background: transparent;
			cursor: pointer;
		}

		.custom_checkbox__input + .custom_checkbox__label::before {
			width: 18px;
			height: 18px;
			border: $secondary 1px solid;
			background: transparent;
			border-radius: 4px;
		}

		.custom_checkbox__input:checked + .custom_checkbox__label::before {
			content: '';
			display: block;
			position: absolute;
			left: 8px;
			top: 4px;
			width: 4px;
			height: 10px;
			border: solid $primary;
			border-width: 0 1px 1px 0;
			transform: rotate(45deg);
			border-radius: 0;
			background-color: transparent;
		}

		.custom_checkbox__input:checked + .custom_checkbox__label::after {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			border: $primary 1px solid;
			background: transparent;
			border-radius: 4px;
		}
	}

	&__theme_assignments, &__theme_locked {
		.custom_checkbox__input:checked + .custom_checkbox__label::before {
			left: 7px;
			top: 1.5px;
			width: 7px;
			height: 14px;
			border-width: 0 2px 2px 0;
		}

		.custom_checkbox__input + .custom_checkbox__label::before {
			border: none;
		}

		.custom_checkbox__input:checked + .custom_checkbox__label::after {
			border: none;
		}
	}

	&__theme_locked {
		.custom_checkbox__input:checked + .custom_checkbox__label::before {
			border: solid $common-gray;
			border-width: 0 2px 2px 0;
		}
	}
}
