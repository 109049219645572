@import "src/styles/colors.scss";
@import "src/styles/variables.scss";

.button_like {
	&__like_icon {
		&__likable {
			cursor: pointer;
		}
		&:hover {
			scale: $icons-hover-scale;
		}
		path {
			fill: $primary;
			stroke: $primary;
		}

		&__disliked {
			path {
				fill: none;
				stroke: $text-tiles;
			}
		}

		&__secondary {
			path {
				fill: $primary;
				stroke: $primary;
			}

			&__disliked {
				path {
					fill: none;
					stroke: $secondary;
				}
			}
		}
	}
}
