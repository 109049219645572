@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.resetpassword_page {
	background: $background-layout;
	flex: 1;
	border-radius: 20px;
	max-width: 578px;
	padding: 48px 68px;

	@include login-form();

	@include media-extra-small-down() {
		padding: 9px 16px;
		border-radius: 0;
		min-height: 100vh;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
	}

	&__text_inner {
		padding-right: 5px;
	}

	&__link {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		background: none;
		border: none;
		padding: 0;
		color: $text-general;
		text-decoration: underline;
		cursor: pointer;
	}

	&__link_inner {
		padding-right: 5px;
		padding-left: 5px;
	}

	&__fields_wrap {
		padding-bottom: 32px;
	}

	&__field_inner {
		padding-bottom: 0;
	}

	&__field_inner_two {
		padding-bottom: 18px;
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	& #{&}__btn_sign_in {
		background: $primary;
		padding: 12px;
		border-radius: 8px;
		text-transform: none;
		margin-bottom: 24px;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.06em;
		color: $text-primary;
		line-height: 24px;
		width: 100%;
		border: 0;
		cursor: pointer;
	}

	&__text_block {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 50%;
			display: inline-block;
			z-index: 0;
			border-bottom: 1px solid $background-dark;
		}
	}

	&__spiral {
		display: block;
		user-select: none;
		cursor: default;
		width: 224px;
		height: 103px;
		margin: 0 0 33px 0;
		mix-blend-mode: lighten;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
