@import "./colors.scss";
@import "./variables";
@import "./adaptive";

@mixin status {
	&__status {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 14px;
		color: $text-general;
		background-color: $background-gray;
		border-radius: 13px;
		padding: 3px 16px;
		text-transform: uppercase;
		height: fit-content;
		text-align: center;
	}

	&__status_success {
		background-color: $success-light;
		color: $success-dark;
	}

	&__status_error {
		background-color: $background-gray;
		color: $error-dark;
	}
}

@mixin cell_name {
	&__cell_name {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		cursor: pointer;
		@include media-extra-small-down() {
			font-family: $font-family-base;
			font-weight: 500;
			font-size: 16px;
			padding-bottom: 8px;
		}
	}
}

@mixin cell_avatar {
	&__cell_avatar {
		margin-right: 12px;

		& > img {
			background: none;
		}

		@include media-extra-small-only2() {
			margin-right: 8px;
		}

		@include media-extra-small-down() {
			margin-right: 0;
		}
	}
}

@mixin cell_text {
	&__cell_text {
		@include ellipsis();
		max-width: 150px;

		@include media-extra-small-down() {
			width: 100%;
			max-width: 170px;
		}

		@include media-extra-small-only2() {
			max-width: 125px;
		}
	}
}
