@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/adaptive";

.submenu {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 auto;

	@include media-small-down() {
		width: 100%;
		padding-top: 5px;
		display: flex;
		flex-direction: column;

		&__default {
			flex-direction: row;
		}
	}

	&__link {
		position: relative;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $text-layout-header;
		opacity: 0.6;
		text-transform: uppercase;
		padding: 12px 20px;
		cursor: pointer;
		text-decoration: none;

		@include media-extra-small-down() {
			padding: 4px;
			font-size: 12px;
		}

		@include media-extra-small-only2() {
			padding: 10px 5px;
			font-size: 16px;
		}

		@include media-compact-only() {
			padding: 10px 10px;
			font-size: 16px;
		}

		@include media-middle-only(){
			padding: 10px 15px;
		}
	}

	&__active {
		color: $primary;
		opacity: 1;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 0;
			display: inline-block;
			z-index: 10;
			border-bottom: 3px solid $primary;
		}
	}
}

.mobile_menu {
		position: absolute;
		z-index: 1000;
		display: flex;
		font-size: 16px;
		font-weight: 500;
		background-color: $input-background;
		width: 90vw;
		left: 5%;
		min-width: 250px;
		margin-top: 5px;

	&__hide_menu {
		display: none;
	}

	&__icon {
		path {
			fill: $primary;
		}
	}

	&__button_container {
		width: fit-content;
	}
}
