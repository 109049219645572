@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/notifications";

.bulk_cloning_popup {
	width: 100%;

	&__tenant {
		width: 40vw;
		max-width: 50vw;

		@include media-extra-small-down() {
			max-width: 80vw;
		}

		@include media-extra-small-only2() {
			max-width: 70vw;
		}

	}

	&__tenant_placeholder {
		top: -6px;
	}

	&__checkbox {
		margin-top: 10px;
	}


}
