@import "./colors.scss";
@import "./variables";
@import "src/styles/adaptive";

@mixin nodata {
	&__nodata {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		min-height: 640px;

		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: normal;
		margin-right: auto;
		color: $common-white;
		text-align: center;

		@include media-extra-small-down() {
			font-size: 24px;
			min-height: 230px;
		}

		@include media-extra-small-only2() {
			font-size: 24px;
			min-height: 386px;
		}
	}
}

@mixin playerArrows {
	height: 55px;
	top: 50%;
	background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 75%);
}
