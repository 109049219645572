@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.my_certificates {
		@include status;
		@include cell_name;
		@include cell_avatar;
		@include cell_text;

		padding: 4vh 5vw;

		@include media-extra-small-down() {
			padding-top: 2vh;
	}

		&__header {
				display: flex;
				align-items: center;
				justify-content: center;

				@include media-extra-small-down() {
						padding: 0 16px;
						justify-content: space-between;
				}

				@include media-extra-small-only2() {
						padding: 0 32px;
				}
		}

		&__title {
				font-family: $font-family-base;
				font-size: 36px;
				font-weight: 700;
				line-height: 36px;
				margin-right: auto;

				@include media-extra-small-down() {
					font-size: 26px;
					font-weight: 500;
					line-height: 26px;
				}

		}

		&__tooltip {
			transform: translate(20px, -70px);
		}

		&__play_icon {
			path {
				stroke: $common-black;
			}
		}

		&__cell_picture {
			margin-right: 12px;
		}

		&__filters_group {
			display: flex;
		}

		&__export_button {
			color: $text-primary;
			background-color: $primary;
			margin-left: 8px;
		}

		&__search {
				margin-right: 24px;
				min-width: 413px;

				@include media-extra-small-down() {
						min-width: auto;
						margin-right: 16px;
						flex: 1;
				}

				@include media-extra-small-only2() {
						min-width: 170px;
						margin-right: 16px;
				}
		}

		&__body {
				padding: 28px 0;

				@include media-extra-small-down() {
						padding: 24px 0;
				}

				@include media-extra-small-only2() {
				}
		}

		&__table {
				th {
						&:nth-child(3), &:nth-child(4), &:nth-child(5) {
							text-align: center;
							padding: 9px;
						}
						&:nth-child(6) {
							padding-left: 9px;
						}
				}
				td {
						&:nth-child(1) {
							width: 40%;
    						padding: 0 18px;
							max-width: 200px;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							white-space: break-spaces;
					}
						&:nth-child(2) {
							max-width: 200px;
							width: 5%;
							padding-right: 18px;
							text-align: center;
						}
						&:nth-child(3) {
							width: 8%;
							padding: 0 9px;
						}
						&:nth-child(4) {
							width: 8%;
							padding: 0 9px;
						}
						&:nth-child(5) {
							width: 7%;
							text-align: center;
						}
						&:nth-child(6) {
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							width: 31%;
							white-space: break-spaces;
							padding-left: 9px;
							padding-right: 18px;
						}



						@include media-extra-small-only2() {
								&:nth-child(1) {
										padding-left: 24px;
								}

								&:nth-child(2) {
										padding-right: 24px;
								}

								&:nth-child(3) {
										padding-right: 24px;
								}
						}
				}
		}
}

.share_icon {
	cursor: pointer;
}

.cms_tile_certificate {
	padding: 10px;
	border-bottom: 2px dashed $other-outline-gray;
	font-size: 16px;

	div {
		margin-bottom: 8px;
	}

	&__course_title_container {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	&__cert_id_row {
		overflow-wrap: anywhere;
		font-size: 12px;
	}
}
