@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";

.banner {
	width: 100%;
	object-fit: fill;
	cursor: pointer;
	min-height: 250px;

	&__image {
		object-fit: cover;
		max-height: 400px;

		@include media-small-down() {
			object-fit: fill;
		}

		@include media-extra-small-down() {
			object-fit: fill;
		}
	}
}
