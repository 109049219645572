@import "src/styles/colors.scss";
@import "src/styles/popup.scss";
@import "src/styles/adaptive";

.confirmation_popup {
	&__container {
		background: $background-layout;
		border-radius: 16px;
		border: 1px solid $text-general;
		position: relative;
		padding: 0 20px;

		@include media-small-down() {
			width: 90vw;
			padding: 0 4px
		}
	}

	&__header {
		@include popup-header();

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__divider {
		color: gray;
		width: 95%
	}

	&__body {
		@include popup-content();
		font-weight: 400;
		font-size: 18px;
		line-height: 1.8;
		color: $text-general;
	}

	&__contentNoTitle {
		padding-top: 20px;
	}

	&__controls {
		margin-top: 15px;
	}

	&__footer {
		@include popup-footer();
		gap: 10px;
	}

	&__reversed {
		flex-direction: row-reverse;
	}

	&__logo_box {
		display: flex;
		justify-content: center;
		width: 100%;
	}
}

.dialog_button {
	height: 40px;

	&__cancel {
		height: 40px;
		border: none;
	}

	&__wide {
		width: 47%;
	}

}
