@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.cms_content {
	@include status;
	@include cell_name;

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-extra-small-down() {
			justify-content: flex-end;
		}

		@include media-extra-small-only2() {
			padding: 0 32px;
		}
	}

	&__cell_name {
		cursor: pointer;
		overflow: visible;
	}

	&__title {
		font-family: $font-family-base;
		font-size: 28px;
		font-weight: 600;
		line-height: 36px;
		margin-right: auto;
		margin-left: 20px;
		color: $text-general;

		@include media-extra-small-down() {
			display: none;
		}

		@include media-extra-small-only2() {
		}
	}

	&__filters_group {
		display: flex;
		justify-content: flex-end;
	}

	&__date_filter {
		color: $text-general;
		display: flex;
		margin-right: 5px;
		@include media-extra-small-down() {
			margin-right: 0;
		}

		@include media-extra-small-only2() {
			margin-right: 0;
		}
	}

	&__search {
		margin-right: 24px;
		min-width: 413px;

		@include media-extra-small-down() {
			min-width: auto;
			margin-right: 16px;
			flex: 1;
		}

		@include media-extra-small-only2() {
			min-width: 170px;
			margin-right: 16px;
		}
	}

	&__body {
		padding: 28px 0;

		@include media-extra-small-down() {
			padding: 24px 0;
		}

		@include media-extra-small-only2() {
		}
	}

	&__controls_container {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	&__play_icon {
		cursor: pointer;

		path {
			stroke: $common-black;
		}
	}

	&__disabled_icon {
		pointer-events: none;

		path {
			stroke: $other-outline-gray;
		}
	}


	&__master_disabled_label {
		margin: 0;
		color: $error-dark;
		width: 50px;
	}

	&__status {
		font-size: 12px;
		padding: 3px 12px;
	}

	&__table {
		td {
			&:nth-child(1) {
				padding-left: 12px;

				@include media-extra-small-only2() {
					width: auto;
				}
			}

			&:nth-child(2) {
    			text-align: center;
				width: 15%;
				@include media-extra-small-only2() {
					width: auto;
				}
			}

			&:nth-child(3) {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				max-height: 72px;
				white-space: break-spaces;
				padding-right: 4px;
				padding-left: 8px;

			}

			&:nth-child(4) {
				max-width: 200px;
				width: 15%;
				padding-right: 12px;

				@include media-extra-small-only2() {
				 	text-align: center;
				}
			}

			&:nth-child(5) {
				max-width: 200px;
				width: 10%;
				padding-right: 12px;

				@include media-extra-small-only2() {
					text-align: center;
			   }
			}

			&:nth-child(6) {
				max-width: 200px;
				width: 15%;
				padding-right: 12px;
				text-align: center
			}

			@include media-extra-small-only2() {
				&:nth-child(1) {
					padding-left: 24px;
				}

				&:nth-child(2) {
					padding-right: 24px;
				}

				&:nth-child(3) {
					padding-right: 24px;
				}
			}
		}

	}

	&__cell_picture {
		width: 114px;
		height: 76px;
		margin-right: 16px;
		min-width: 114px;
	}

	&__cell_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $common-white;
	}

	&__ref_content_icon {
		circle {
			fill: $common-white;
		}

		svg {
			border: 2px solid;
			border-radius: 50%;
		}
	}

	&__cell_icon_wrap {
		margin-right: 20px;

		@include media-extra-small-down() {
			margin-right: 4px;
		}
	}

	&__cell_title {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 72px;
		white-space: break-spaces;
		flex: 1;
		overflow: hidden;
	}

	& #{&}__tooltip {
		transform: translate(50px, -40px);
		width: fit-content;

		& div {
			cursor: pointer;
			white-space: nowrap;
		}
	}

	&__error_tooltip {
		top: 50%;
		transform: translate(30%,-100%);
		width: fit-content;
	}

	& #{&}__tooltip_activate {
		width: 120px;
		transform: translate(30px, -40px);
		& div {
			padding: 10px 8px;
		}
	}

	&__icon_segment {
		width: 40px;
		height: 40px;

		path {
			fill: $common-white;
			stroke: $text-grid;
		}

	}
}


.disabled_pointer {
	pointer-events: none;
	cursor: defaults;
}

.active_pointer {
	cursor: pointer;
	&:hover {
		font-weight: 500;
	}
}

.archive_box_icon {
	margin: 0 5px;
	transform: scale(0.9);
	cursor: pointer;
}

.retry_loading {
	background-color: $error-light;
	color: $error-dark;
	border: 1px solid $error-dark;
	width: fit-content;
	margin: 0 auto;
	padding: 3px 15px;
	border-radius: 13px;
	text-transform: uppercase;
	font-size: 14px;
}
