@import "src/styles/colors.scss";
@import "src/styles/variables";

.tooltip {
	position: relative;


	&__hover_component {
		display: flex;
		align-items: center;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	&__hover_component:hover + &__label {
		opacity: 1;
		display: block;
		animation-name: fadeIn;
		animation-duration: 0.3s;
	}

	&__label {
		opacity: 0;
		transition: opacity .3s;
		position: absolute;
		left: 0;
		display: none;
		z-index: 1000;

		&:hover {
			opacity: 1;
			display: block;
			animation-name: fadeIn;
			animation-duration: 0.3s;
		}
	}

	&__content {
		padding: 8px 12px;
		cursor: default;

		min-width: 110px;
		max-width: 500px;

		background: $background-white;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
		border-radius: 10px;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: $common-black;
	}
}
