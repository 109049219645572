@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.course_card_notes {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
	animation-name: fadeIn;
	animation-duration: 0.7s;

	&__button_icon {
		path {
			stroke: $text-tiles;
		}
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	width: 100%;
	margin: 24px 0 12px;

}

.body {
	@include scrollbar(4px, rgba(#DCDCDC, 0.5));
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.note_input_area {
	width: 100%;
	margin-bottom: 8px;
	height: 200px;
}
