@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.ai_summary {
	position: absolute;
	top: 0;
	right: 0px;
	z-index: 101;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 50%;
	height: 20%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 80%, transparent 100%);
	padding: 24px 16px;
	flex-direction: column;
	opacity: 0;
	visibility: hidden;
	transition: .7s ease;
	color: $text-secondary;
	font-size: medium;
	line-height: 24px;

	&__show {
		height: calc(100% - 62px);
		opacity: 1;
		visibility: visible;
	}

	&__close_icon {
		width: 24px;
		height: 24px;
		cursor: pointer;
		margin-bottom: 12px;
		align-self: flex-end;
		min-width: 24px;
		min-height: 24px;

		path {
			stroke: $text-secondary;
		}

		&:hover {
			path {
				stroke-width: 2;
			}
		}
	}

	&__controls {
		display: flex;
		width: 98%;
		justify-content: flex-end;
	}

	&__tabs {
		display: flex;
		width: 100%;
	}

	&__wrap {
		@include scrollbar(4px, rgba($background-gray, 0.5));
		overflow-y: scroll;
		overflow-x: hidden;
		width: 100%;
	}
}
