@mixin spinner {
	animation: spinner 0.75s linear infinite;

	@keyframes spinner {
		to {
			transform: rotate(1turn);
		}
	}
}

@mixin fadeIn($duration) {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	animation-name: fadeIn;
	animation-duration: $duration;
}
