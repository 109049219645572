@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.course_card_timestamps {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
	animation-name: fadeIn;
	animation-duration: 0.7s;

	&__no_data {
		width: 100%;
		font-weight: 500;
		font-size: 16px;
		padding-top: 25px;
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding-top: 20px;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding-bottom: 24px;
	}

	&__text {
		font-weight: 700;
		font-size: 24px;
		line-height: 36px;
	}

	&__remove_btn {
		padding: 0;
	}

	&__button_icon {
		margin-right: 0;

		path {
			stroke: $text-tiles;
		}
	}

	&__body {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		gap: 8px;
		max-height: 125px;
		overflow-y: auto;
	}

	&__segment {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 24px;
		padding-right: 5px;
	}

	&__video_icon {
		width: 24px;
		height: 24px;
		cursor: pointer;

		path {
			stroke: $text-tiles;
		}
	}

	&__name {
		@include ellipsis();
		flex: 1;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		cursor: pointer;
	}

	&__duration {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		min-width: 42px;
	}

	&__share_icon {
		cursor: pointer;

		path {
			fill: $text-tiles;
		}
	}
}
