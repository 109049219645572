//Primary colors
$primary-red: #e50914;
$primary-orange: #ff9721;
$primary-purple: #C60CF4;
$primary-green: #27de6a;
$primary-blue: #0ab3ff;
$primary: var(--primary, #e50914);
$secondary: var(--secondary, #151515);

//Text colors
$text-general: var(--text-general, #151515);
$text-primary: var(--text-primary, #151515);
$text-secondary: var(--text-secondary, #B6B6B6);
$text-disabled: #a6a6a6;
$text-layout-header: var(--text-layout-header, #FFFFFF);
$text-input-primary: var(--text-input-primary, #FFFFFF);
$text-tiles: var(--text-tiles, #FFFFFF);
$text-dropdown-hover: var(--text-dropdown-hover, #FFFFFF);
$text-grid: var(--text-grid, #151515);

//Error colors
$error-main: #D74221;
$error-dark: #A2160D;
$error-light: #FFA8B2;

//Success colors
$success-main: #27DE6A;
$success-dark: #009436;
$success-light: #A3FFC4;

//Background colors
$background-black: #151515;
$background-dark: #212121;
$background-gray: #E8E8E8;
$background-white: #ffffff;
$background-navbar: var(--background-navbar, #151515);
$background-tile: var(--background-tile, #151515);
$background-layout: var(--background-layout, #ffffff);
$background-layout-header: var(--background-layout-header, #212121);
$background-profile-body: var(--background-profile-body, #ffffff);
$background-dropdown-hover: var(--background-dropdown-hover, #B6B6B6);
$background-grid: var(--grid, #ffffff);
$background-player: var(--background-desk-player, #3c3c3c);

//Navbar colors
$navbar-home: var(--navbar-home, #e50914);
$navbar-desk: var(--navbar-desk, #27de6a);
$navbar-peers: var(--navbar-peers, #C60CF4);
$navbar-influencers: var(--navbar-influencers, #0ab3ff);
$navbar-cms: var(--navbar-cms, #ff9721);
$navbar-myData: var(--navbar-myData, #EF5DA8);
$navbar-reports: var(--navbar-reports, #D462FD);

//Forms colors
$input-background: var(--input-background, #333333);

//Avatar colors
$avatar-empty: var(--avatar-empty, #9020E8);

//Common colors
$common-black: #151515;
$common-white: #ffffff;
$common-gray: #B6B6B6;
$common-gray-light: #f7f7f7;


//Other colors
$other-disable-gray: #8a8a8a;
$other-background-dark: #333333;
$other-outline-gray: #7e7e7e;
$other-medium-gray: #464646;
$other-gray: #cccccc;

//Current specific colors
$ocean-blue: #0077B7;
$eucaliptus: #44D7B6;
$traffic-yellow: #F7B500;
$vivid-orange: #FA6400;
$barbie-pink: #EF5DA8;
$magenta: #E203D9;
$heliotrope: #D462FD;
$violet: #9020E8;
$ultramarine: #5D5FEF;
$sky-blue: #3AACFF;
$facebook-blue: #3B5998;



