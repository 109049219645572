@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/adaptive";

.cms_footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $input-background;
	color: $text-input-primary;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 0 0 16px 16px;
	position: relative;

	@include media-extra-small-down() {
		background-color: $input-background;
	}

	&__wrap {
		position: relative;
	}

	&__left_group {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 24px;

		@include media-extra-small-down() {
			padding-left: 16px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__right_group {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 24px;

		@include media-extra-small-down() {
			padding-right: 16px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__text {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}

	&__text_alert {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		color: $error-main;
	}

	&__text_indent {
		padding-right: 8px;
	}

	&__text_indent_two {
		padding-right: 24px;

		@include media-extra-small-down() {
			padding-right: 2px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__icon {
		cursor: pointer;

		path {
			stroke: $text-input-primary;
		}
	}

	&__icon_disable {
		pointer-events: none;

		path {
			stroke: $other-disable-gray;
		}
	}

	&__icon_box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 24px;
		height: 24px;
		cursor: pointer;
	}

	&__icon_spinner {
		animation: spinner-border .75s linear infinite;
		width: 24px;
		height: 24px;

		path {
			fill: $ocean-blue;
		}
	}

	@keyframes spinner-border {
		to {
			transform: rotate(1turn)
		}
	}

	&__select_pages {
		padding-right: 13px;
		padding-bottom: 2px;

		@include media-extra-small-down() {
			padding-right: 8px;
		}

		@include media-extra-small-only2() {

		}
	}
}
