@import "src/styles/colors.scss";
@import "src/styles/mixins";



.chip {
	cursor: default;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	box-sizing: border-box;
	padding: 0 12px;
	white-space: nowrap;
	letter-spacing: 0.06em;
	width: fit-content;
	height: 34px;
	border-radius: 100px;
	background-color: $input-background;
	color: $text-input-primary;

	&__main {
		font-weight: 500;
		text-decoration: underline;
		filter: opacity(0.9);
	}

	&__label {
		@include ellipsis();

		&__hoverable {
			cursor: pointer;
			&:hover {
				filter: brightness(0.9);
			}
		}
	}
}

.chip_icon {
	cursor: pointer;
	margin-left: 4px;

	&:hover {
		transform: scale(1.1);
		filter: brightness(0.9);

	}

	path {
		stroke: currentColor;
	}
}

.chip_disabled {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
	color: $text-disabled;
}
