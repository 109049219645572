@import "src/styles/colors.scss";
@import "src/styles/variables.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/header.scss";

.common_header {
	@include header;
	@include header_line;
	@include header_dart;
	background-color: $background-layout-header;
	width: 100%;
	margin-bottom: $private-layout-margin-small;
	height: $private-header-height;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $text-layout-header;

	@include media-extra-small-only {
		height: $mobile-header-height;
	}

	&__back_btn_group {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__content {
		max-width: $content-width-max;
		width: 100%;
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		justify-content: space-between;

		padding-left: $private-layout-margin-medium;
		padding-right: $private-layout-margin-medium;

		@include media-medium-only {
			padding-left: $private-layout-margin-extra-small;
			padding-right: $private-layout-margin-extra-small;
		}

		@include media-small-only {
			padding-left: $private-layout-margin-extra-small;
			padding-right: $private-layout-margin-extra-small;
		}

		@include media-extra-small-only {
			padding-left: $private-layout-margin-extra-small;
			padding-right: $private-layout-margin-extra-small;
		}
	}

	&__label {
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-weight: bold;
		font-size: 48px;
		line-height: 48px;
		margin-bottom: 40px;

		@include media-extra-small-only {
			font-size: 24px;
			line-height: 36px;
			padding-top: 6px;
			margin-bottom: 14px;
		}

		@include media-small-only {
			padding-left: $public-layout-margin-extra-small;
		}

		@include media-medium-only {
			padding-left: $public-layout-margin-medium;
		}
	}

	&__btn_back_mobile {
		padding: 0;
	}
}

.logout_button {
	border-color: $text-secondary;
	background-color: $secondary;

	& span {
		color: $text-secondary;
	}
}
