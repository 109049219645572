@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.note_table_tile {
	display: flex;
	flex-direction: column;
	margin-bottom: 18px;
}

.content_info {
	display: flex;
	margin-bottom: 4px;
}

.content_tile {
	display: flex;
    flex-direction: column;
    justify-content: space-around;
	font-weight: bold;
}

.icons_container {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
}

.note {
	display: flex;
	max-height: 30vh;
}

.updated_date {
	font-weight: normal;
	color: $text-secondary;
}

.play_icon {
	path {
		stroke: $common-black;
	}
}

.scrollbar {
	@include scrollbar(4px, rgba($background-gray, 0.5));
	overflow-y: scroll;
}
