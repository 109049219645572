@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

$section_margin: 10px 50px 20px 70px;

.footer {
	height: 14vh;
	width: 100%;
	background-color: $background-navbar;

	display: flex;
	justify-content: space-between;

	@include media-extra-small-down() {
		height: 100%;
		padding-bottom: 7vh;
	}

	@include media-extra-small-only2() {
		height: 100%;
		padding-bottom: 7vh;
	}


	&__section {
		margin: $section_margin;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include media-extra-small-down() {
			margin: 10px;
		}
	}

	&__left_section {
		align-items: flex-start;
	}

	&__right_section {
		align-items: flex-end;
	}

	&__top_container {
		margin-bottom: 10px;
	}

	&__text, &__versions {
		color: $background-navbar;
		filter: invert(0.6) brightness(120%);
	}

	&__versions {
		text-align: right;
		margin-right: 10px;
	}

	&__bottom_container {
		@include media-extra-small-down() {
			display: flex;
			flex-wrap: wrap;
		}
	}


	&__link {
		color: $primary;
		margin-right: 10px;
	}
}

.footer_public {
	@include media-extra-small-down() {
		padding-bottom: 0;
	}
}
