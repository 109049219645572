@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.cms_threads {
	@include status;
	@include cell_name;

	@include media-extra-small-down() {
		padding: 24px 0;
	}

	&__body {
		padding: 28px 0;

		@include media-extra-small-down() {
			padding: 24px 0;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		@include media-extra-small-down() {
			padding: 0 16px;
			justify-content: space-between;
		}

		@include media-extra-small-only2() {
			padding: 0 32px;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;

		@include media-extra-small-down() {
			display: none;
		}

		@include media-extra-small-only2() {
		}
	}



	&__btn_add_mobile {
		display: none;

		@include media-extra-small-down() {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			@include primary_button();
			align-self: flex-end;
		}
	}

	&__bulk {
		margin-right: 10px;
	}

	&__filters_group {
		display: flex;
		gap: 10px;
	}

	&__checkbox {
		display: flex;
		align-content: center;
		justify-content: center;
	}

	&__search {
		@include search_field();
	}

	&__table {
		td {
			&:nth-child(1) {
				padding-left: 24px;
				width: 40%;

				@include media-extra-small-only2() {
					width: auto;
				}
			}

			&:nth-child(2) {
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				max-height: 72px;
				white-space: break-spaces;
				padding-right: 24px;
				@include media-extra-small-only2() {
					display: none;
				}
			}

			&:nth-child(3) {
				max-width: 200px;
				width: 20%;
				padding-right: 24px;
			}

			&:nth-child(4) {
				max-width: 200px;
				width: 10%;
				padding-right: 24px;
			}

			@include media-extra-small-only2() {
				&:nth-child(1) {
					padding-left: 32px;
				}

				&:nth-child(2) {
					padding-right: 32px;
				}

				&:nth-child(3) {
					padding-right: 32px;
				}
			}
		}

		th {
			&:nth-child(2) {
				@include media-extra-small-down() {
					display: none;
				}

				@include media-extra-small-only2() {
					display: none;
				}
			}
		}
	}

	&__cell_picture {
		width: 114px;
		height: 76px;
		margin-right: 16px;
		min-width: 114px;
	}

	&__cell_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $common-white;
	}

	&__cell_icon_wrap {
		margin-right: 24px;
	}

	&__cell_title {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 72px;
		white-space: break-spaces;
		flex: 1;
		overflow: hidden;
	}

	& #{&}__tooltip {
		left: 50%;
		top: calc(100% + 4px);
		transform: translateX(-46%);
	}
}

.delete_icon {
	padding: 5px;
	font-size: 20px;
	cursor: pointer;
}

a.btn_add {
	text-decoration: none;
	@include primary_button();
	@include hoverable();
	padding: 8px 24px;

	@include media-extra-small-down() {
		display: none;
	}

}
