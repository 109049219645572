@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.users_actions_submenu {
	display: flex;
	position: relative;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	text-decoration: none;
	padding: 8px 24px;

	@include primary_button();

	@include media-extra-small-down() {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		@include primary_button();
		align-self: flex-end;
	}

	&__details {
		max-width: 180px;

		&__subtitle {
			color: $text-secondary;
		}

		@include media-extra-small-down() {
			display: none;
		}
	}

	&__submenu {
		background-color: rgba($background-dark, 0.95);
		border-radius: 10px;
		position: absolute;
		top: 50px;
		right: 0;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		transform: translateY(-20px);

		&_active {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
			z-index: 100;
		}

		&_inactive {
			display: none;
		}

		&__list {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		&__item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $input-background;
			padding: 0 10px;
			background-color: $input-background;

			&:hover,
			&:focus {
				background-color: $background-dropdown-hover;
			}

			&:hover a {
				color: $text-dropdown-hover;
			}

			&:last-child {
				border-bottom: none;
			}

			&__icon {
				margin-right: 8px;
				height: 16px;
			}

			&__btn {
				text-decoration: none;
				font-weight: 500;
				color: $text-input-primary;
				padding: 11px 0;
				width: 100%;
			}
		}
	}

	&__disabled {
		opacity: 0.6;
		pointer-events: none;
	}
}

.spinner {
	&__loading_spinner {
		animation: spinner 0.75s linear infinite;
		width: 24px;
		height: 24px;

		path {
			fill: $text-primary;
		}
	}

	@keyframes spinner {
		to {
			transform: rotate(1turn);
		}
	}

	&__spinner_box {
		width: 24px;
		height: 24px;
	}
}
