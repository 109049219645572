@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.goals_brain {
	background-color: $background-tile;
	width: 100%;
	border-radius: 16px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 40px 40px 0 40px;
	column-gap: 22px;
	flex-direction: column;

	@include media-extra-small-down() {
		padding: 20px 20px 0 20px;
		min-width: auto;
		margin-bottom: 20px;
	}

	@include media-extra-small-only2() {
		padding: 20px 20px 0 20px;
		min-width: auto;
		margin-bottom: 20px;
	}

	&__picture {
		width: 100%;
		margin: 0;
		height: 100%;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__box {
		height: 240px;
		position: relative;
		background: top center / contain no-repeat;
		max-width: 240px;


		@include media-extra-small-down() {
			width: 100%;
			max-width: unset;
			height: 340px;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__head {
		height: 240px;
		position: absolute;
		max-width: 240px;
		path {
			fill: $background-layout;
		}


		@include media-extra-small-down() {
			width: 100%;
			max-width: unset;
			height: 340px;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__container {
		width: 100%;
		display: grid;
		align-items: flex-start;
		justify-content: center;
		gap: 16px;
		grid-template-columns: 240px minmax(200px, 1fr);

		@include media-extra-small-down() {
			grid-template-columns: minmax(300px, 1fr);
		}

		@include media-extra-small-only2() {

		}
	}

	&__box_right {
		flex-direction: column;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		max-width: unset;
	}

	&__title {
		color: $text-tiles;
		font-weight: 500;
		font-size: 36px;
		line-height: 36px;
		margin: 0;

		@include media-extra-small-down() {
			font-size: 18px;
			font-weight: 500;
			line-height: 26px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__info_tooltip {
		width: 400px;
    	transform: translate(7%, -100%);

		@include media-extra-small-down() {
			width: 90vw;
			transform: translate(-50%, 5%);
		}

		@include media-extra-small-only2() {
			width: 400px;
			transform: translate(-50%, 5%);
		}
	}

	&__tooltip__content {
		border: 1px solid $common-black;
		font-weight: 500;
		font-size: 14px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		background-color: $input-background;
		color: $text-input-primary;
	}

	&__info_icon {
		z-index: 101;
		path {
			fill: $text-tiles;
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 25px;
		padding-bottom: 24px;
		width: 100%;
		gap: 25px;
	}

	&__drop_list {
		padding-bottom: 24px;
		width: 100%;
	}

	&__drop_list_error {
		color: white;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;
		padding-bottom: 20px;
	}

	&__pie_box {
		margin: 0 auto;
		& svg {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -15%);
		}
	}
}
