@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.cms_tile_sale {
	@include status;

	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	width: 100%;
	background-color: $background-white;
	color: $text-general;
	padding: 16px;
	position: relative;

	&:first-child {
		border-top: 1px solid $background-gray;
	}

	&__wrap {
		display: flex;
		max-width: 90%;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		flex: 1;
	}

	&__cell_picture {
		margin-right: 16px;
	}

	&__cell_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $common-white;
	}

	&__cell_title {

		white-space: break-spaces;
		overflow: hidden;
		font-weight: 500;
		font-size: 14px;
		color: $text-general;
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
	}

	&__status {
		font-size: 14px;
		padding: 3px 12px;
		margin-right: 12px;
	}

	&__icon_wrap {
		margin-right: 12px;
		width: 24px;
		height: 24px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__date {
		@include ellipsis;
		font-family: $font-family-base;
		font-size: 14px;
		color: $text-secondary
	}
}

.course_title {
	overflow-wrap: anywhere;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-weight: normal;
	margin-left: 10px;
}

.play_icon {
	path {
		stroke: $common-black;
	}
}
