@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.page_pdf {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	justify-content: center;

	&:fullscreen {
	canvas {
		width: auto !important;
		height: auto !important;
	}}
}
