@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.courses_filters_group {
	display: flex;
	align-content: center;
	justify-content: center;
	gap: 10px;

	&__search {
		@include search_field();
	}
}
