@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";

.select_company {
	position: relative;
}

.select_company__select {

		appearance: none;
		outline: none;
		height: 53px;
		background-color: $input-background;
		border: 1px solid $common-black;
		border-radius: 8px;
		display: flex;
}

.select_company__label {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	transform-origin: 0 0;
	transform: translate3d(16px, 17px, 0);
	transition: transform 0.2s;
	font-family: $font-family-base;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;
	color: $text-input-primary;
	opacity: 0.6;
}

.select_company__select:focus + .select_company__label,
.select_company__select:-webkit-autofill + .select_company__label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.select_company__select:not(:placeholder-shown) + .select_company__label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.select_company__error {
	padding-top: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	left: 0;
	bottom: -22px;

	&_box {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&_text {
		font-family: $font-family-base;
		font-size: 14px;
		color: $error-main;
		cursor: default;
	}
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
