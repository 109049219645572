@import "src/styles/adaptive";
@import "src/styles/profile.scss";
@import "src/styles/mixins";

.subjects_chips_selector {
	@include profile;

	&__subjects_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__chips_block {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 5px;
	}

	&__wide {
		width: 100%;
	}
}
