@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/header";
@import "src/styles/profile";

.thread_profile {
	@include profile;
	@include header;
	@include header_line;
	@include header_dart;


	&__header_box {
		width: 100%;
		border-top: 1px solid $background-dark;
		position: absolute;
		bottom: 0;
		left: 0;
		padding-left: 74px;

		@include media-extra-small-down() {
			position: static;
			padding-left: 16px;
		}

		@include media-extra-small-only2() {
			padding-left: 32px;
		}
	}

	&__icon {
		cursor: pointer;

		path {
			stroke: $background-layout-header;
			filter: invert(0.6) brightness(120%);
		}
	}


	&__tile_label {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 24px;
		margin: 0;
		padding-bottom: 24px;

		@include media-extra-small-down() {
			padding-bottom: 8px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 8px;
		}
	}

	&__wide {
		width: 100%;
	}

	&__half {
		width: 49%;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__chips_container {
		width: 100%;
	}

	&__tags_label_container {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	&__tags_tooltip {
		width: 50vw;
		transform: translate(5%, -110%);
	}

	&__tags_button_icon {
		path {
			stroke: $text-input-primary;
		}
	}

	&__subjects_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__chips_block {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		@include media-extra-small-down() {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 8px;
			width: 100%;
		}
	}

	&__tags_remove_btn {
		background-color: $secondary;
		padding: 4px 12px;
		justify-content: space-between;
		font-weight: normal;
		font-size: 14px;
		line-height: normal;
		width: auto;
		height: 32px;
		color: $text-secondary;

		@include media-extra-small-down() {
			width: 100%;
			margin-bottom: 0;
		}
	}

	&__tags_remove_btn span {
		@include ellipsis();
		max-width: 200px;

		@include media-extra-small-down() {
			max-width: 60px;
		}
	}

	&__tags_remove_btn div {
		height: auto;
	}

	&__form_field {
		margin-bottom: 24px;

		@include media-extra-small-only {
			width: 100%;
			margin-bottom: 16px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 24px;
		}
	}

	&__form_field__tags {
		margin-bottom: 16px;

		@include media-extra-small-only {
			width: 100%;
			margin-bottom: 12px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__tile {
		width: 100%;
		height: fit-content;
		border-radius: 16px;
		border: 1px solid $other-outline-gray;
		padding: 32px;
		margin-bottom: 16px;

		@include media-extra-small-down() {
			padding: 24px 16px;
		}
	}

	&__image_upload_container {
		width: fit-content;
		position: relative;

		@include media-extra-small-down() {
			margin-bottom: 0;
		}
	}

	&__thumbnail {
		cursor: pointer;
		border-radius: 8px;
		width: 170px;
		height: 114px;
		transition: 0.5s ease;
		position: relative;
		background: $common-black;

		img {
			border-radius: 8px;
			background: $common-black;
			opacity: 0.5;
		}

		@include media-extra-small-down() {
			width: 170px;
			height: 114px;
		}
	}

	&__image_cover {
		color: $common-white;
		transition: 0.5s ease;
		opacity: 1;
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-radius: 8px;
		font-size: 16px;
		line-height: 24px;

		@include media-extra-small-down() {
			font-size: 14px;
			font-weight: 500;
		}
	}

	&__input_area {
		width: 100%;
		margin-bottom: 24px;

		@include media-extra-small-down() {
			margin-bottom: 16px;
		}
	}

	&__input_area textarea {
		min-height: 148px;
	}

	&__input_box {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@include media-extra-small-down() {
			flex-direction: column;
			column-gap: 0;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			column-gap: 0;
		}
	}

	&__upload_group {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 16px;
		margin-bottom: 16px;

		@include media-extra-small-down() {
			flex-direction: column;
			column-gap: 0;
			align-items: flex-start;
		}
	}

	&__images_group {
		color: $common-gray;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 16px;

		@include media-extra-small-down() {
			gap: 16px;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__uploaded_image {
		border-radius: 14px;
		background: transparent;
		opacity: 0.9;
		position: relative;
		:hover {
			filter: brightness(40%);
		}
	}

	&__uploaded_image img {
		border-radius: 14px;;
		background: transparent;
		opacity: 0.5;
	}

	&__upload_button {
		// background-color: $background-black;
		padding: 4px 16px;

		@include media-extra-small-down() {
			margin-bottom: 16px;
			max-width: 128px;
		}
	}

	&__upload_button span {
		font-family: $font-family-base;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.06em;
		text-align: left;
		line-height: 24px;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__save_button {
		text-decoration: none;
		padding: 12px 40px;
		border-radius: 24px;

		@include media-extra-small-down() {
			width: 100%;
		}
	}

	&__uploaded_block {
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__uploaded_icon {
		path {
			stroke: $common-white;
		}
	}

	&__error {
		color: $error-dark;
		padding-top: 5px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		left: 0;
		bottom: -21px;
	}

	&__error_icon {
		margin-right: 10px;
	}

	&__dropdown {
		@include media-extra-small-down() {
			padding-bottom: 16px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 24px;
		}
	}

	&__upload_target {
		@include media-extra-small-down() {
			padding-bottom: 16px;
		}
	}

	&__tile_control {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__activation_group {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}

	&__toggler {
		margin-right: 0;
		margin-bottom: 0;

		@include media-extra-small-down() {
			padding-left: 50px;
		}
	}

	&__tooltip {
		width: 300px;
		transform: translate(-100%, -140%);
	}
}

.tags_label {
	margin: 0 10px 0 0;
	padding-bottom: 0;
}
