@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.all_reports {
	@include cell_name;
	@include cell_text;

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-extra-small-down() {
			padding: 0 16px;
			justify-content: space-between;
		}

		@include media-extra-small-only2() {
			padding: 0 32px;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;

		@include media-extra-small-down() {
			display: none;
		}
	}

	&__export_button {
		height: 30px;
		margin-right: 8px;
		cursor: pointer;
	}

	&__body {
		padding: 28px 0;

		@include media-extra-small-down() {
			padding: 8px 4px;
		}

		@include media-extra-small-only2() {
			padding: 14px 4px;
		}
	}

	&__table {
		td {
			&:nth-child(1) {
				width: 90%;
				padding: 0 18px;
			}

			&:nth-child(2) {
				width: 10%;
				padding-right: 18px;
			}

			@include media-extra-small-only2() {
				&:nth-child(1) {
					padding-left: 24px;
				}

				&:nth-child(2) {
					padding-right: 24px;
				}

			}
		}
	}
}

