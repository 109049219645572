@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.widget_form {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-direction: column;

	&__form {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
	}

	&__fields_wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 24px;
		margin-bottom: 24px;
	}

	&__form_field {
		width: 100%;
	}

	&__btn_save {
		padding: 12px 40px;
		width: 127px;
	}
}
