@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";
@import "src/styles/submenu";
@import "src/styles/animation";

.button_submenu {
	position: relative;
	@include fadeIn(0.5s);

	&__btn {
		padding: 8px 16px;
		background-color: $secondary;
		color: $text-secondary;
	}

	@include submenu();

	&__submenu {
		width: 100%;
		top: 43px;
		border: 1px solid;

		&__item {
			padding: 12px 10px;
			color: $text-primary;
			cursor: pointer;
			font-weight: 500;
			border-bottom: none;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				width: 90%;
				height: 100%;
				display: inline-block;
				z-index: 10;
				border-bottom: 1px solid $text-primary;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0.2;
			}

			&:last-child:after {
				content: none;
			}
		}
	}

	&__icon {
		stroke: $text-secondary;
	}

	&__disabled {
		cursor: default;
		color: $text-disabled;
	}
}
