@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

$description_font_weight: 400;
$description_line_height: 150%;
$title_font_weight: 500;
$title_line_height: 120%;

.login_page {

	@include login-form();

	background: $background-layout;
	flex: 1;
	border-radius: 20px;
	max-width: 578px;
	padding: 48px 68px;
	border: 1px solid $text-general;

	@include media-extra-small-down() {
		padding: 9px 16px;
		border-radius: 0;
	}


	&__text {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
	}

	&__text_inner {
		padding-right: 5px;
	}

	&__link {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		background: none;
		border: none;
		padding: 0;
		color: $text-general;
		text-decoration: underline;
		cursor: pointer;
	}

	&__form {
		padding-bottom: 16px;
	}

	&__email_field {
		margin-bottom: 26px;
	}

	&__password_field {
		margin-bottom: 12px;

		& label {
			color: $text-input-primary;
		}
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	& #{&}__btn_sign_in {
		background-color: $primary;
		padding: 12px;
		border-radius: 8px;
		text-transform: none;
		margin-bottom: 16px;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.06em;
		color: $text-primary;
		line-height: 24px;
		width: 100%;
		border: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__text_block {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 50%;
			display: inline-block;
			z-index: 0;
			border-bottom: 1px solid $background-dark;
		}
	}

	&__desc {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		text-transform: uppercase;
		color: $text-general;
		padding: 0 26px;
		background: $background-layout;
		z-index: 1;
	}

	&__checkbox_inner {
		padding-top: 4px;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
	}

	&__spiral {
		display: block;
		user-select: none;
		cursor: default;
		width: 224px;
		height: 103px;
		margin: 0 0 33px 0;
		mix-blend-mode: lighten;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__icon {
		animation: spinner-border .75s linear infinite;
	}

	@keyframes spinner-border {
		to {
			transform: rotate(1turn)
		}
	}

	&__btn_text {
		position: relative;
		color: $text-primary;
	}

	& #{&}__btn_disable {
		pointer-events: none;
		opacity: 0.6;
		background-color: $primary;
	}

	&__socials_block {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> span {
			width: 100%;
		}
	}
}

.conditions_popup {
	cursor: default;
	width: 30vw;
	max-height: 50vh;

	@include media-extra-small-down() {
		width: 80vw;
		max-height: 100%;

	}

	&__title {
		font-weight: $title_font_weight;
		font-size: 24px;
		line-height: $title_line_height;
		margin-bottom: 8px;
	}

	&__description {
		font-weight: $description_font_weight;
		font-size: 16px;
		line-height: $description_line_height;
		margin: 8px 0 16px;
	}

	&__terms_section {
		font-weight: $title_font_weight;
		font-size: 16px;
		line-height: $title_line_height;
		margin: 16px 0;

		:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}

	&__terms_description {
		font-weight: $description_font_weight;
		font-size: 14px;
		line-height: $description_line_height;
		margin-top: 4px;
		display: flex;
		align-items: center;
	}

	&__icon {
		path {
			stroke: $text-general;;
		}
		margin: 5px;
		width: 20%;
	}
}
