/// Mixin to place items on a circle
@import "src/styles/adaptive";

@mixin on-circle($item-count, $interval) {
	$max-angle: 135; //radius of the arc of the circle
	position: relative;
	width: 155px;
	height: 155px;

	> * {
		display: block;
		position: absolute;
		top: 0;
		left: 0;

		$angle: (calc($max-angle / $item-count));
		$rot: 0;

		@for $i from 1 through $item-count {
			@keyframes translating#{$i} {
				from {
					opacity: 0;
					transform: rotate($rot * 1deg) translate(0) rotate(calc(($rot * -1deg) + 10deg));
				}
				to {
					opacity: 1;
					transform: rotate($rot - 10deg) translate($interval) rotate(calc(($rot * -1deg) + 10deg));
				}
			}

			@include media-small-down() {
				@keyframes translating#{$i} {
					from {
						opacity: 0;
						transform: rotate($rot * 1deg) translate(0) rotate(calc(($rot * -1deg) + 10deg));
					}
					to {
						opacity: 1;
						transform: rotate($rot - 10deg) translate(calc($interval / 2)) rotate(calc(($rot * -1deg) + 10deg));
					}
				}
			}

			&:nth-of-type(#{$i}) {
				transform: rotate($rot - 10deg) translate($interval) rotate(calc(($rot * -1deg) + 10deg));
				animation: translating#{$i} .5s ease-in-out;

				@include media-small-down() {
					transform: rotate($rot - 5deg) translate(calc($interval / 2)) rotate($rot * -1deg);
				}
			}

			$rot: $rot + $angle;
		}
	}
}
