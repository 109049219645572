@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/animation";

.peer_answer {
	color: $text-tiles;
	padding: 32px;
	border-radius: 16px 16px 16px 0;
	position: relative;
	margin-bottom: 24px;
	width: 100%;

	&__secondary {
		color: $text-general;
		padding: 8px 32px 16px 32px;
		margin-bottom: 0;
		border-radius: unset;
	}

	&__compact {
		padding: 16px;
	}

	&__plain_secondary {
		color: $text-general;
		padding: 16px;
		margin-bottom: 0;
		border-radius: unset;
	}

	@include media-extra-small-down() {
		padding: 16px;
	}

	@include media-extra-small-only2() {
		padding: 16px;
	}

	&__wrap {
		margin: 0 8px 16px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;

		&__secondary {
			margin-bottom: 0;
			border-left: 1.5px solid $common-gray;
			margin-left: 44px;

			@include media-extra-small-down() {
				margin-left: 28px;
			}

			@include media-extra-small-only2() {
				margin-left: 28px;
			}
		}

		&__secondary__l1 {
			width: calc(100% - 44px);

			@include media-extra-small-down() {
				width: calc(100% - 28px);
			}

			@include media-extra-small-only2() {
				width: calc(100% - 28px);
			}
		}

		&__secondary__l2 {
			width: calc(100% - 76px);
		}

		&__plain_secondary {
			margin-bottom: 0;
			border-left: 1.5px solid $common-gray;
			margin-left: 28px;
		}

		&__secondary__plain_l1 {
			width: calc(100% - 28px);
		}

		&__secondary__plain_l2 {
			width: calc(100% - 56px);
		}

		&__compact {
			margin-bottom: 16px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;
		margin-bottom: 16px;
	}

	&__fullname {
		font-size: 18px;
		font-weight: 500;
		line-height: 26px;
		margin-right: 10px;
	}

	&__datetime {
		font-size: 14px;
		font-weight: 400;
		line-height: 14px;
		color: $text-tiles;

		&__secondary {
			color: $common-gray;
		}
	}

	&__body {
		margin-bottom: 16px;
	}

	&__text {
		font-weight: 400;
		line-height: 26px;
		margin-bottom: 16px;
		word-break: break-word;
		white-space: pre-line;
		max-height: 250px;
		overflow-y: auto;
	}

	&__text_expand {
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 13px;
	}

	&__group {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
	}

	&__delete_icon {
		cursor: pointer;
			path {
				stroke: $text-tiles;
			}

			&__secondary {
				path {
					stroke: $secondary;
				}
			}
	}

	&__corner {
		position: absolute;
		left: 0;
		bottom: -24px;
		height: 24px;
		width: 24px;
		transform: scaleY(-1);
	}

	&__btn_reply {
		background-color: $secondary;
		padding: 4px 14px;

		& span {
			color: $text-secondary;
		}
	}

	&__btn_delete {
		background-color: $secondary;
		padding: 8px 10px 8px 8px;
		border-radius: 16px;
	}

	&__expand {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		margin-left: 32px;

		&__compact {
			margin-left: 17px;
		}

		@include media-extra-small-down() {
			margin-left: 17px;
		}

		@include media-extra-small-only2() {
			margin-left: 17px;
		}
	}


	&__btn_expand {
		height: auto;
		width: auto;
		padding: 0;
		border: none;

		& > div {
			margin-right: 0;
		}
	}

	&__share_icon,
	&__edit_icon,
	&__expand_icon,
	&__collapse_icon {
		cursor: pointer;

		path {
			fill: $text-tiles;
		}

		&__secondary {
			path {
				fill: $secondary;
			}
		}
	}

	&__comment_add {
		margin-bottom: 20px;
		width: calc(100% - 32px);

		&__compact {
			width: 100%;
		}

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__comment_edit {
		width: 100%;

		@include media-extra-small-down() {
			border: none;
			border-radius: 0;
			padding: 0;
		}

		@include media-extra-small-only2() {
			border: none;
			border-radius: 0;
			padding: 0;
		}
	}

	&__hide {
		display: none;
	}

	&__uploaded_images {
		@include scrollbar(2px, $text-tiles);

		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 8px;
		width: 100%;
		overflow-x: scroll;
		padding-bottom: 10px;


		@include media-extra-small-down() {
			padding-bottom: 16px;
			flex-wrap: wrap;
		}

		@include media-extra-small-only2() {
			padding-bottom: 16px;
			flex-wrap: wrap;
		}
	}

	&__thumbnail {
		width: $comment-attachment-pic-height;
		height: $comment-attachment-pic-height;
		border-radius: 14px;
		background-color: transparent;
		cursor: pointer;

		&__compact {
			height: $comment-attachment-pic-height-mobile;
			width:$comment-attachment-pic-height-mobile;
		}

		@include media-extra-small-down() {
			height: $comment-attachment-pic-height-mobile;
			width: $comment-attachment-pic-height-mobile;
			margin-bottom: 4px;
		}

	}

	&__thumbnail img {
		border-radius: 14px;
		background-color: transparent;
	}

	&__wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}

	&__replies_list {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
		height: auto;
		transition: max-height .7s ease-in-out;
		max-height: 9999px;
		max-width: 1169px;

		&__hide {
			max-height: 0;
		}
	}

	&__cell_avatar {
		&__compact {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}

		@include media-extra-small-down() {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}

		@include media-extra-small-only2() {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}
	}

	&__header_group {
		display: flex;
		align-items: center;
		justify-content: center;

		&__compact {
			justify-content: space-between;
			width: 100%;
			align-items: center;
		}

		@include media-extra-small-down() {
			flex-direction: column;
			align-items: flex-start;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
