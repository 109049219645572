@import "src/styles/adaptive";
@import "src/styles/colors";
@import "src/styles/on-circle";

.submenu {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 148px;
	top: 32px;
	left: 32px;
	z-index: 11;

	@include media-small-down() {
		max-width: 96px;
		left: 16px;
		transition: .5s;
	}

	&__open {
		position: absolute;
		top: 32px;
		left: 32px;
		z-index: 11;

		@include media-small-down() {
			width: 32px;
			height: 32px;
			top: 16px;
			left: 16px;
		}
	}

	&__circle_box {
		@include on-circle($item-count: 5, $interval: 125px);
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		width: 48px;
		height: 48px;
		background-color: $background-white;
		border-radius: 50%;
		cursor: pointer;
		z-index: 11;
		border: lightgrey 1px solid;

		&:hover {
			background-color: $common-gray;
		}

		@include media-small-down() {
			width: 32px;
			height: 32px;
		}
	}

	&__notes_icon {
		svg {
			width: 24px;
			height: 24px;
		}
		path {
			fill: $common-black;
		}
	}

	&__quiz_icon {
		svg {
			width: 24px;
			height: 24px;
			scale: 1.8;
		}
		circle {
			fill: transparent;
		}
	}

	&__empty {
		display: none;
	}

	&__wrap {
		position: relative;
		width: 155px;
		height: 155px;
	}

	&__icon_close {
		position: absolute;
		left: 0;
		top: 0;
	}

	&__blur {
		opacity: .3;
	}
}
