@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";

$disabled-opacity: 0.4;

.input_search {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;

	&__input {
		background: $input-background;
		box-sizing: border-box;
		border-radius: 16px;
		padding: 7px 60px 7px 16px;
		border: 1px solid $other-outline-gray;
		font-family: $font-family-base;
		font-size: 16px;
		line-height: 24px;
		color: $text-input-primary;
		width: 100%;

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			-webkit-appearance: none;
		}

		&:focus {
			background-color: $input-background;
			border: 1px solid $common-black;
			outline: none;
		}

		&:disabled {
			opacity: $disabled-opacity;
		}
	}

	&__btn {
		position: absolute;
		right: 15px;
		background-color: transparent;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.06em;
		cursor: pointer;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 50%;
		top: 50%;
		transform: translateY(-50%);

		&:disabled {
			cursor: default;
			pointer-events: none;
			opacity: $disabled-opacity;
		}
	}

	&__icon {
		@include hoverable();
		width: 20px;
		height: 20px;
		path {
			stroke: $text-input-primary;
		}

		&__close {
			margin-right: 4px;
		}
	}
}
