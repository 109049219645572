
@import "src/styles/colors.scss";

.zStats_container {
	display: flex;
	color: $text-primary;
	align-items: center;

	:last-child {
		margin-left: 8px;
	}

	&__zStat {
		width: 74px;
		height: 24px;
		background: $primary;
		border-radius: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.version_tooltip {
	transform: translate(-110px, -60px);
	max-width: 300px;
	width: max-content;

	&__container {
		display: flex;
		margin-right: 6px;
	}
}


