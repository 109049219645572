@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.search_results {
	min-height: 300px;

	&__content {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		color: $text-general;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		@include media-extra-small-down() {
			padding: 10px;
		}

		&:first-child {
			margin-top: 16px;
		}
	}

	&__section_grid {
		padding: 0 74px 30px 74px;

		@include media-extra-small-down() {
			padding: 0 0 32px 0;
			width: 100%;
		}

		@include media-extra-small-only2() {
			padding: 0 0 32px 0;
			width: 100%;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 400px);
		gap: 32px;

		@include media-medium-only {
			grid-template-columns: repeat(3, 400px);
		}

		@include media-small-only {
			grid-template-columns: repeat(2, 400px);
		}

		@include media-extra-small-down() {
			grid-template-columns: repeat(1, 100%);
			row-gap: 16px;
		}

		@include media-extra-small-only2() {
			grid-template-columns: repeat(1, 100%);
			row-gap: 16px;
		}
	}

	&__tile_horiz {
		grid-column: auto / span 2;
	}

	&__tile_vert {
		grid-row: auto / span 2;
	}
}
