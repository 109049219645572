@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/colors";

.recommendations_item {
	border-radius: 16px;
	background-color: $background-tile;
	margin-bottom: 16px;

	&__picture {
		width: 148px;
		height: 72px;
		cursor: pointer;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__header {
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__body {
		padding: 8px;
	}

	&__title {
		font-weight: 500;
		font-size: 14px;
		color: $text-secondary;
		line-height: 120%;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-height: 66px;
	}

	&__icon {
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
	}

	&__course_icon {
		path {
			stroke: $text-secondary;
			fill: $background-tile;
		}
	}

	&__thread_icon {
		path {
			fill: $text-secondary;
		}
	}

	&__influencer_icon {
		path {
			fill: $text-secondary;
		}
	}

	&__small_icon {
		width: 25px;
		height: 25px;
		min-width: 25px;
		min-height: 25px;
		margin-left: -5px;

		circle {
			fill: transparent;
		}

		rect {
			fill: transparent;
		}

		path {
			fill: none;
			stroke: $text-secondary;
		}
	}

	&__little_icon {
		path {
			stroke: none;
			fill: $text-secondary;
		}
	}

	&__segment_icon {
		circle {
			fill: transparent;
		}

		path {
			stroke: $text-secondary;
		}
	}

	&__follow_button {
		font-weight: 500;
		font-size: 14px;
		color: $text-secondary;
		margin-left: auto;
		padding: 4px 12px;
	}

	&__bookmark_button {
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		margin-left: auto;
	}
}
