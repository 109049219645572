@import "src/styles/colors.scss";
@import "src/styles/animation.scss";

.loading_dots {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 16px 0;
    overflow: hidden;
	width: 100%;

	@keyframes dot-pulse-before {
		0% {
		  box-shadow: 9984px 0 0 -5px;
		}
		30% {
		  box-shadow: 9984px 0 0 2px;
		}
		60%, 100% {
		  box-shadow: 9984px 0 0 -5px;
		}
	  }
	  @keyframes dot-pulse {
		0% {
		  box-shadow: 9999px 0 0 -5px;
		}
		30% {
		  box-shadow: 9999px 0 0 2px;
		}
		60%, 100% {
		  box-shadow: 9999px 0 0 -5px;
		}
	  }
	  @keyframes dot-pulse-after {
		0% {
		  box-shadow: 10014px 0 0 -5px;
		}
		30% {
		  box-shadow: 10014px 0 0 2px;
		}
		60%, 100% {
		  box-shadow: 10014px 0 0 -5px;
		}
	  }

	&__pulse {
		position: relative;
		left: -9999px;
		width: 5px;
		height: 5px;
		border-radius: 5px;
		background-color: transparent;
		color: $primary;
		box-shadow: 9999px 0 0 -5px;
		animation: dot-pulse 1.5s infinite linear;
		animation-delay: 0.25s;
		transform: translateX(20px);

		&:before, &:after {
		  content: "";
		  display: inline-block;
		  position: absolute;
		  top: 0;
		  width: 5px;
		  height: 5px;
		  border-radius: 5px;
		  background-color: transparent;
		  color: $primary;
		}
		&:before {
		  box-shadow: 9984px 0 0 -5px;
		  animation: dot-pulse-before 1.5s infinite linear;
		  animation-delay: 0s;
		}
		&:after {
		  box-shadow: 10014px 0 0 -5px;
		  animation: dot-pulse-after 1.5s infinite linear;
		  animation-delay: 0.5s;
		}
	  }
}