@import "src/styles/colors.scss";
@import "src/styles/variables";

@mixin login-form {
	&__logo_box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	&__title {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 4px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 12px;
	}
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@mixin no_ellipsis {
	white-space: normal;
	overflow: unset;
	text-overflow: unset;
}

@mixin primary_button {
	border-radius: 20px;
	background-color: $primary;
	font-family: $font-family-base;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.06em;
	color: $text-primary;
	border: none;
	cursor: pointer;
	white-space: nowrap;
}

@mixin hoverable {
	&:hover {
		opacity: $button-hover-opacity;
	}
}

@mixin scrollbar($size, $background-color) {
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $background-color;
		border-radius: $size;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
		border-radius: $size;
	}
}

@mixin search_field () {
		margin-right: 12px;
		min-width: 413px;

		@include media-extra-small-down() {
			min-width: auto;
			margin-right: 16px;
			flex: 1;
		}

		@include media-extra-small-only2() {
			min-width: 170px;
			margin-right: 16px;
		}
}
