@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.player_zoom {
	position: absolute;
	right: 10px;
	bottom: 70px;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	border-radius: 8px;
	align-items: center;
	justify-content: flex-end;
	width: 260px;
	z-index: 10;
	cursor: pointer;

	@include media-small-down() {
		width: auto;
		top: -200px;
		height: 200px;
	}

	@include media-extra-small-down() {
		width: auto;
		top: -110px;
		height: 110px;
	}

	&__header {
		font-family: $font-family-base;
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: $common-white;
		padding: 14px 36px;
		border-bottom: $common-white 1px solid;

		@include media-small-down() {
			font-size: 15px;
			line-height: 20px;
			padding: 5px 25px;
		}

		@include media-extra-small-down() {
			display: none;
		}
	}

	&__body {
		padding: 7px 0;

		@include media-small-down() {
			padding: 3px 0;
			height: 155px;
			overflow: scroll;
		}

		@include media-extra-small-down() {
			padding: 3px 0;
			height: 110px;
			overflow: scroll;
		}
	}

	&__list {
		padding: 0;
		margin: 0;
	}

	&__item {
		position: relative;
		list-style: none;
		font-family: $font-family-base;
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: $common-white;
		width: 100%;
		padding: 7px 36px;

		&:hover {
			backdrop-filter: blur(15px);
		}

		@include media-small-down() {
			font-size: 15px;
			line-height: 20px;
			padding: 3px 30px;
		}

		@include media-extra-small-down() {
			font-size: 13px;
			line-height: normal;
			padding: 3px 25px;
		}
	}

	&__icon {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);

		@include media-extra-small-down() {
			left: 5px;
		}
	}
}
