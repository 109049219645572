@import "src/styles/colors.scss";
@import "src/styles/adaptive";

.filter_button {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    width: -moz-fit-content;
    width: 100%;
	border-radius: 15px;
    background-color: $input-background;
	@include media-extra-small-down() {
		margin: 2px 0;
		width: 100%;
	}

	@include media-extra-small-only2() {
		margin: 2px 0;
	}
}
.arrow_up {
	path {
		stroke: $common-black;
		stroke-width: 2;
	}
		transform: rotate(90deg);
}

.arrow_down {
	path {
		stroke: $common-black;
		stroke-width: 2;
	}
		transform: rotate(270deg);
}

.selected_option {
	display: flex;
	justify-content: space-between;
}

