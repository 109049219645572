@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";

.private_layout {
	min-height: 100vh;
	color: $text-general;
	padding-top: $navbar-height;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: $background-layout;

	@include media-extra-small-only() {
		padding-bottom: 20px;
		width: 100vw;
	}
}
