@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.all_reports_tile {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: $background-white;
	color: $text-general;
	padding: 16px;

	&:first-child {
		border-top: 1px solid $background-gray;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&__cell_title {
		overflow: hidden;
		font-weight: 500;
		font-size: 14px;
		color: $text-general;
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}


	&__export_button {
		color: $text-primary;
			background-color: $input-background;
			height: 30px;
	}

}


