@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/tile";
@import "src/styles/mixins";

.peer_tile {
	@include common-tile();
	display: grid;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: unset;
	padding: 18px 24px;


	&__default {
		height: 475px;
		grid-template-rows: 54px 240px 1fr;
		grid-template-columns: 100%;

		&:hover {
			scale: $tiles-hover-scale;
		}
	}

	&__horiz {
		min-width: 100%;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 54px 1fr 104px;
		height: 475px;
	}

	&__vert {
		min-width: 100%;
		grid-template-rows: 54px 0.3fr 1fr;
		grid-template-columns: 100%;
	}

	@include media-extra-small-down() {
		width: calc(100vw - 40px);
		place-self: center;
	}

	@include media-extra-small-only2() {
		margin: 0 auto;
	}

	&__header {
		padding: 0 0 18px 0;
		width: 100%;
		column-gap: 18px;

		&__horiz {
			grid-column: 1/-1;
			padding: 0 0 32px 0;
		}

		@include media-extra-small-down() {
			column-gap: 10px;
		}

		@include media-extra-small-only2() {
			column-gap: 10px;
		}
	}

	&__rating_block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 18px;

		@include media-extra-small-down() {
			column-gap: 10px;
		}
	}

	&__rating button {
		padding: 0 2px;

		@include media-extra-small-down() {
			padding: 0;
		}

		@include media-extra-small-only2() {
			padding: 0;
		}
	}

	&__content {
		width: 100%;

		&__default {
			margin: 0 -24px 24px -24px;
			height: 216px;
			padding: 0;
			width: calc(100% + 48px);
		}

		&__horiz {
			height: 100%;
			grid-column-start: 1;
			padding-right: 32px;
			margin-left: -24px;
			width: calc(100% + 24px);
		}

		&__vert {
			margin: 0 -24px 10px -24px;
			width: calc(100% + 48px);
			height: 100%;
		}
	}

	&__content__thumbnail {
		width: 100%;
		aspect-ratio: 16/9;
		object-fit: cover;
		cursor: pointer;
		background-color: $background-dark;

		&__horiz {
			object-fit: cover;
		}

		&__vert {
			object-fit: cover;
		}

		@include media-extra-small-down() {
			object-fit: cover;
		}

		@include media-extra-small-only2() {
			object-fit: cover;
		}
	}

	&__no_image {
		object-fit: none;
	}

	&__name_container {
		overflow: hidden;

		&__default {
			margin-bottom: 16px;
		}

		&__horiz {
			padding-bottom: 16px;
		}

		&__vert {
			padding-bottom: 16px;
		}
	}

	&__name {
		@include ellipsis();
		text-decoration: none;
		color: $text-tiles;
		display: block;
		font-family: $font-family-base;

		&__dark {
			color: $text-general;
		}

		&__default {
			font-size: 24px;
			font-weight: 500;
			line-height: 26px;
		}

		&__horiz {
			font-size: 28px;
			font-weight: 700;
			line-height: 48px;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			white-space: unset;
		}

		&__vert {
			font-size: 28px;
			font-weight: 700;
			line-height: 48px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			white-space: unset;
		}
	}

	&__info_container {
		white-space: pre-line;
		padding: 0;
		height: 100%;

		&__horiz {
			display: flex;
			align-items: center;
			grid-column-start: 2;
		}
	}

	&__desc_container {
		overflow: hidden;
		&__horiz {
			grid-row-start: 3;
			grid-column: 1/-1;
		}
	}

	&__desc {
		text-decoration: none;
		color: $text-tiles;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		white-space: pre-line;
		font-family: $font-family-base;
		font-size: 16px;
		line-height: 24px;
		overflow-y: auto;
		cursor: default;
		&::-webkit-scrollbar{
			display: none;
		}

		&__dark {
			color: $text-general;
		}

		&__default {
			font-weight: 400;
		}

		&__horiz {
			font-weight: 400;
			height: 100%;
			max-height: 240px;
		}

		&__vert {

			font-weight: 400;
			display: block;
			height: 100%;
			max-height: 540px;

		}
	}

	&__color_bar {
		width: 100%;
		height: 8px;
		border-radius: 63px;
		margin: 0;
		flex: 1;
	}
}
