@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.player_stream_box {
	position: relative;
	overflow: hidden;
	height: 640px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $background-player;

	@include media-extra-small-down() {
		height: auto;
		min-height: 230px;
		padding: 30px 0;
	}

	@include media-extra-small-only2() {
		height: 386px;
	}

	&__color_bar {
		width: calc(100% - 80px);
		height: 5px;
		border-radius: 0 0 10px 10px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		background-color: $primary-green;
	}

	&__wrap {
		background-color: $background-tile;
		color: $text-tiles;
		border-radius: 16px;
		position: relative;
		padding: 45px 40px 40px 40px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 70%;

		@include media-extra-small-down() {
			flex-direction: column;
			padding: 24px 16px;
			width: calc(100% - 20px);
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			padding: 24px 16px;
			width: calc(100% - 20px);
		}
	}

	&__body {
		width: 100%;
		max-height: 80%;
		position: relative;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		@include media-extra-small-down() {
			flex-direction: column;
			gap: 20px;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			gap: 20px;
		}
	}

	&__title {
		@include ellipsis();
		font-weight: 700;
		font-size: 36px;
		margin-bottom: 16px;
		font-style: normal;
		text-align: center;

		@include media-extra-small-down() {
			font-size: 20px;
			white-space: normal;
		}

		@include media-extra-small-only2() {
			font-size: 20px;
			white-space: normal;
		}
	}

	&__btn {
		padding: 12px 40px;
		background: $primary-red;
		border-radius: 24px;
		color: white;
		text-decoration: none;
		font-weight: 500;
		font-size: 20px;
		text-align: center;
		letter-spacing: 0.06em;
		display: block;

		@include media-extra-small-down() {
			font-size: 16px;
		}

		@include media-extra-small-only2() {
			font-size: 16px;
		}
	}

	&__btn__disable {
		background-color: $text-secondary;
		color: $common-white;
		pointer-events: none;
	}

	&__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__picture {
		height: 205px;
		width: 100%;
		margin-bottom: 16px;

		@include media-extra-small-down() {
			height: 100px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__countdown {
		width: 100%;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0.15px;
		color: #B6B6B6;
		margin-bottom: 24px;
	}

	&__countdown__disable {
		display: none;
	}
}
