@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";
@import "src/styles/animation";

.motifs_statistics {
		@include cell_name;
		@include cell_avatar;

		padding: 0 5vw;

		&__header {
				display: flex;
				align-items: center;
				justify-content: center;

				@include media-extra-small-down() {

						justify-content: space-between;
						margin-bottom: 6px
				}

				@include media-extra-small-only() {
						margin-bottom: 10px
				}
		}

		&__title {
				font-family: $font-family-base;
				font-size: 36px;
				font-weight: 700;
				line-height: 36px;
				margin-right: auto;

				@include media-extra-small-only {
					font-size: 24px;
				}

				@include media-extra-small-down() {
						display: none;
				}
		}

		&__filters_group {
			display: flex;
			gap: 10px;
			height: 40px;

			@include media-extra-small-only {
				width: 60%;
			}

			@include media-extra-small-down() {
				width: 100%;
			}
		}

		&__body {
			padding: 28px 0;

			@include media-extra-small-down() {
					padding: 8px 0;
			}

			@include media-extra-small-only {
				padding: 12px 0;
			}

		}

		&__sessions_cell {
			display: flex;
			overflow-x: scroll;
			gap: 8px;
			padding-bottom: 8px;
			cursor: grab;


			:active {
				cursor: grabbing;
			}


			-ms-overflow-style: none;  /* Internet Explorer 10+ */
			scrollbar-width: none;  /* Firefox */
			&::-webkit-scrollbar {
				display: none;  /* Safari and Chrome */
			}
		}


		&__table {
			td {
				&:nth-child(1) {
					padding-left: 20px;
					width: 30%;
					white-space: break-spaces;
					overflow: hidden;
				}

				&:nth-child(2), &:nth-child(4) {
					width: 6%;
					text-align: center;
				}

				&:nth-child(3) {
					width: 8%;
					text-align: center;
				}

				&:nth-child(5) {
					max-width: 40vw;
					padding: 6px 0 0;
				}
			}

		}

}
