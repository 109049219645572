@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.simple_comments {
	display: grid;
	grid-template-rows: auto auto 1fr;
	grid-template-columns: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	border-left: $text-input-primary 1px solid;
	border-bottom: $text-input-primary 1px solid;
	margin-left: 4px;
	border-bottom-left-radius: 8px;
	overflow: hidden;

	&__alert {
		top: 100px;
	}

	@include media-extra-small-down() {
		width: 100%;
		max-height: unset;
		min-width: unset;
		max-width: unset;
		padding: 5px;
		border-left: none;
		border-bottom: none;
	}

	@include media-extra-small-only2() {
		width: 100%;
		max-height: unset;
		min-width: unset;
		max-width: unset;
		border-left: none;
		border-bottom: none;
	}

	@include media-compact-only() {
		width: 100%;
		max-height: unset;
		min-width: unset;
		max-width: unset;
	}

	&__comment_add {
		margin: 8px 8px 8px 4px;
		width: calc(100% - 10px);

		@include media-extra-small-down() {
			width: calc(100% - 16px);
		}

		@include media-extra-small-only2() {
			width: calc(100% - 16px);
		}

		@include media-compact-only() {
			width: calc(100% - 16px);
		}
	}

	&__box {
		width: 100%;
	}
}

.borderless {
	border: none;
	height: auto;
	width: 100%;
	max-width: 1220px;
	margin: 0 auto;

	@include media-small-down() {
		width: 100%;
		margin: 4px;
	}

}
