@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.player_thread_box {
	position: relative;
	overflow: hidden;
	height: 640px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $background-player;

	@include media-extra-small-down() {
		height: auto;
		padding: 30px 0;
	}

	@include media-extra-small-only2() {
		height: 386px;
	}

	&__color_bar {
		width: calc(100% - 80px);
		height: 5px;
		border-radius: 0 0 10px 10px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	&__wrap {
		background-color: $background-tile;
		color: $text-tiles;
		border-radius: 16px;
		position: relative;
		padding: 45px 40px 32px 40px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 70%;

		@include media-extra-small-down() {
			flex-direction: column;
			padding: 24px 16px;
			width: calc(100% - 20px);
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			padding: 24px 16px;
			width: calc(100% - 20px);
		}
	}

	&__body {
		width: 100%;
		max-height: 80%;
		position: relative;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		@include media-extra-small-down() {
			flex-direction: column;
			gap: 20px;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			gap: 20px;
		}
	}

	&__title {
		@include ellipsis();
		font-weight: 700;
		font-size: 36px;
		margin-bottom: 16px;

		@include media-extra-small-down() {
			font-size: 20px;
			white-space: normal;
		}

		@include media-extra-small-only2() {
			font-size: 20px;
			white-space: normal;
		}
	}

	&__desc {
		position: relative;
		margin-bottom: 8px;
	}

	&__delimiter {
		position: absolute;
		left: 0;
		bottom: 8px;
		width: 100%;
		height: 1px;
		background-color: $text-tiles;
		opacity: 0.1;
	}

	&__desc_text {
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0.15px;
		padding-bottom: 24px;
		word-wrap: break-word;
		word-break: break-word;
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		max-height: 350px;
		overflow-y: auto;

		@include media-extra-small-down() {
			font-size: 12px;
			padding-bottom: 12px;
		}

		@include media-extra-small-only2() {
			font-size: 12px;
			padding-bottom: 12px;
		}
	}

	&__btn {
		padding: 12px 40px;
		background: #E50914;
		border-radius: 24px;
		color: white;
		text-decoration: none;
		font-weight: 500;
		font-size: 20px;
		text-align: center;
		letter-spacing: 0.06em;
		display: block;

		@include media-extra-small-down() {
			font-size: 16px;
		}

		@include media-extra-small-only2() {
			font-size: 16px;
		}
	}

	&__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
