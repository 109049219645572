@import "src/styles/colors";

@mixin submenu {
	&__submenu {
		background-color: rgba($background-dark, 0.95);
		border-radius: 10px;
		position: absolute;
		top: 50px;
		right: 0;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		transform: translateY(-20px);

		&_active {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
			z-index: 1001;
		}

		&_inactive {
			display: none;
		}

		&__list {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		&__item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $input-background;
			padding: 0 10px;
			background-color: $input-background;

			&:hover,
			&:focus {
				background-color: $background-dropdown-hover;
			}

			&:hover a {
				color: $text-dropdown-hover;
			}

			&:last-child {
				border-bottom: none;
			}

			&__icon {
				margin-right: 8px;
				height: 16px;
			}

			&__btn {
				text-decoration: none;
				font-weight: 500;
				color: $text-input-primary;
				padding: 11px 0;
				width: 100%;
			}
		}
	}
}
