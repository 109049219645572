@import "src/styles/adaptive";
@import "src/styles/colors";

.slider_tray {
	display: flex;
	width: unset !important;
}

.slider_container {
	display: flex;
	height: 500px;
	align-items: center;

	&__small {
		height: 350px;
	}
}

.slide {
	padding-bottom: 0 !important;

	@include media-small-down() {
		width: 100%;
		margin-left: 8px;
		margin-right: 8px;
	}
}

.carousel {
	height: 510px;
	align-items: center;
}

.carousel_animation {
	transition: all 300ms linear;
}

.carousel_button {
	position: absolute;
	height: inherit;
	background: transparent;
	z-index: 2;
	border: none;
	cursor: pointer;
	backdrop-filter: blur(2px) opacity(10%);

	@include media-small-down() {
		width: 30px;
		padding: 0;
	}

	&__left {
		left: -1px;
		background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 140%);

		@include media-small-down() {
			border-radius: 30px 0 0 30px;
		}
	}
	&__right {
		right: -1px;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 140%);

		@include media-small-down() {
			border-radius: 0 30px 30px 0;
		}
	}

	&:disabled {
		display: none;
	}
}

.button_icon {
	z-index: 3;
	border-radius: 50%;
	width: 35px;
	height: 35px;
}
