@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/header";

.myDesk_layout {
	@include header;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: $background-layout;

	&__submenu {
		display: flex;
		align-items: center;
		width: 100%;

	}

	&__back_button{
		cursor: pointer;
		path {
			stroke: $text-layout-header;
			opacity: 0.7
		}
		margin-right: 10px;
	}

	&__body {
		flex: 1;
		width: 100%;
		background-color: $background-layout;
		color: $text-general;
		max-width: 1930px;

		@include media-extra-small-down() {
			padding-top: 10px;
		}

		@include media-extra-small-only2() {
			padding-top: 20px;
		}
	}
}
