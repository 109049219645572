@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/profile.scss";
@import "src/styles/header.scss";
@import "src/styles/mixins.scss";

.user_profile {
	@include profile;
	@include header;
	@include header_line;
	@include header_dart;

	@include media-extra-small-down() {
		margin-top: 8ypx;
	}

	@include media-extra-small-only2() {
		margin-top: 10px;
	}

	&__avatar_error {
		color: $error-dark;
		margin: 0 16px;
	}

	&__current_user {
		margin-top: 30px;
	}

	&__buttons_container {
		display: flex;
	}

	&__password_button {
		width: 191px;
		background-color: $secondary;
		color: $text-secondary;
		border: 1px solid $secondary;
		@include media-extra-small-only {
			width: 100%;
		}
	}

	&__password_message {
		font-size: 16px;
		line-height: 24px;
		color: $text-general;
		margin: 0 12px;
	}

	&__tile_label {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 24px;
		margin: 0;
		padding-bottom: 24px;
	}

	&__tile_label_big {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 36px;
		margin: 0;
		padding-bottom: 0;
	}

	&__tile_label_new {
		font-size: 24px;

		@include media-extra-small-down() {
			font-size: 18px;
		}
	}

	&__avatar {
		@include media-extra-small-down() {
			min-width: 58px;
		}

	}

	&__label_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&__pictures_container {
		justify-content: flex-start;
	}

	&__activation_group {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-left: 10px;
	}

	&__toggler {
		margin-right: 0;
		margin-bottom: 0;

		@include media-extra-small-down() {
			padding-left: 50px;
		}
	}

	&__tooltip {
		width: 300px;
		transform: translate(-100%, -140%);
	}

	&__content_container_details {
		flex-direction: column;
	}

	&__form_field_short_desc {
		width: 100%;
	}

	&__input_area {
		height: 180px;
	}

	&__wide {
		width: 100%;
	}

	&__areas_container {
		width: 100%;
	}

	&__areas_block {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding-top: 20px;

		@include media-extra-small-down() {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 8px;
			width: 100%;
		}
	}

	&__areas_button_icon {
		width: 15px;
		height: 15px;
		path {
			stroke: $text-secondary;
		}
	}

	&__areas_remove_btn {
		background-color: $secondary;
		color: $text-secondary;
		padding: 0 12px 0 20px;
		justify-content: space-between;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		width: auto;

		@include media-extra-small-down() {
			width: 100%;
			margin-bottom: 0;
		}
	}

	&__areas_remove_btn span {
		@include ellipsis();
		max-width: 200px;

		@include media-extra-small-down() {
			max-width: 60px;
		}
	}

	&__content_box {
		display: grid;
		grid-template-columns: repeat(2, minmax(50%, 1fr));
		gap: 24px;

		@include media-extra-small-only {
			width: 100%;
		}

		@include media-extra-small-down {
			margin: 10px 0;
			width: 100%;
			grid-template-columns: minmax(50%, 1fr);
		}
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-extra-small-down() {
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			flex-direction: row;
		}
	}

	&__dropdown_wrap {
		min-width: 20vw;

		@include media-extra-small-only {
			width: 40vw;
		}

		@include media-extra-small-down {
			margin: 10px 0;
			width: 100%;
		}
	}

	&__dropdown_role {
		label {
			color: $text-input-primary;
		}
	}

	&__change_avatar_button {
		color: $text-secondary;
		background-color: $secondary;
	}

	&__drop_list {
		label {
			color: $text-input-primary;
		}
	}
}

.group_tooltip {
	width: 300px;
	transform: translate(-100%, -100%);
}

.group_container {
	display: grid;
	grid-template-columns: minmax(50%, 1fr) 24px;
	max-width: 100%;
}

.group_dropdown {
	width: 100%;
}

.assign_groups_popup {
	max-width: 700px;

	&__content_container {
		display: flex;
		justify-content: space-between;
	}
}
