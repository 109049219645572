@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.no_access {
	font-size: 18px;
	padding: 20px;
	font-weight: 500;
	color: $text-primary;
	background: $primary;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
}
