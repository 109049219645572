@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/tile.scss";
@import "src/styles/mixins";

.course_tile {
	@include common-tile();
	overflow: hidden;
	&:hover {
		scale: $tiles-hover-scale;
	}

	@include media-extra-small-down() {
		margin: 0 auto;
	}

	@include media-extra-small-only2() {
		margin: 0 auto;
	}

	&:hover {
		filter: brightness(1);
	}

	&__not_active {
		filter: brightness(0.5);
	}

	&__horiz {
		display: grid;
		min-width: 100%;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 60px 1.1fr 120px;
		height: 500px;
	}

	&__vert {
		grid-template-rows: 10px 0.3fr 1fr;
		grid-template-columns: 100%;
		gap: 18px;
		height: 1050px;
		width: 100%;
	}

	&__header {
		width: 100%;
		grid-column: 1 / -1;
		padding-left: 9px;

		&__horiz {
			margin-top: -14px;
		}
	}

	&__duration_block {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
	}

	&__duration {
		margin-left: 8px;
	}

	&__type_icon {
		circle {
			fill: none;
		}

		path {
			fill: none;
			stroke: $text-tiles;
		}

		&__dark {
			path {
				fill: none;
				stroke: $background-tile;
			}
		}
	}

	&__course_icon {
		path {
			fill: $background-tile;
			stroke: $text-tiles;
		}

		&__dark {
			path {
				fill: $text-tiles;
				stroke: $background-tile;
			}
		}
	}


	&__rating_block {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__bookmark {
		position: relative;
		margin-left: 23px;
		z-index: 3;
		@include media-extra-small-down() {
			margin-left: 10px;
		}
	}

	&__content {
		margin: 18px 0;
		background-color: $background-dark;

		&__horiz {
			height: calc(100% + 34px);
			grid-column-start: 1;
			grid-row: 2 / -1;
			margin: 0;
			height: 90%;
			background-color: $background-tile;
		}

		&__compact {
			margin: 6px 0;
		}

		&__thumbnail {
			width: 100%;
			object-fit: cover;
			cursor: pointer;
			height: 216px;

			&__horiz {
				height: 77.8%;
				margin: 0 32px 0 0;
			}

			&__vert {
				height: 100%;
				padding: 0;
			}

			&__compact {
				height: 100px;
				cursor: grab;
			}
		}
	}

	&__lesson_name_container {
		@include ellipsis();

		&__horiz {
			height: 100%;
			display: flex;
    		align-items: center;
			white-space: unset;
			overflow: unset;
			text-overflow: unset;
		}

		&__vert {
			margin-top: 20px;
			width: 100%;
			white-space: unset;
			overflow: unset;
			text-overflow: unset;
		}
	}

	&__lesson_name {
		font-size: 18px;
		line-height: 26px;
		text-decoration: none;
		color: $text-tiles;

		&:hover {
			font-weight: 500;
		}

		&__dark {
			color: $background-tile;
		}

		&__horiz {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			margin-bottom: 11px;
		}

		&__vert {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			margin-bottom: 11px;
		}

		&__compact {
			pointer-events: none;
			font-size: 16px;
		}
	}

	&__color_bar {
		width: 100%;
		height: 8px;
		border-radius: 63px;
		margin: 12px 0 16px;

		&__compact {
			margin: 6px 0;
		}
	}

	&__stats_section {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__stats_left_block {
		display: flex;
		justify-content: space-between;
	}

	&__likes {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	&__views {
		display: flex;
		align-items: center;
	}


	&__stats_icon {
		margin-right: 9px;
	}

	&__content_info_section {
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__compact {
			margin-top: 8px;
		}
	}

	&__info_box {
		flex-direction: column;
	}

	&__content_info {
		margin: 4px 0;
		max-width: 275px;
		@include ellipsis();
	}

	&__wrap {
		width: 100%;

		&__horiz {
			height: 139px;
			margin-top: auto;
		}

		&__vert {
			height: 127px;
			margin-top: auto;
		}
	}

	&__info_container {
		&__horiz {
			display: flex;
			grid-row: 2 / 3;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
		}

		&__vert {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 690px;
			flex: 1;
		}

	}

	&__desc {
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
		display: none;
		overflow: hidden;
		&::-webkit-scrollbar{
			display: none;
		}

		&__horiz {
			display: -webkit-box;
			grid-column: 1/-1;
			grid-row: 3/-1;
			padding: 10px 20px;
			-webkit-line-clamp: 8;
			-webkit-box-orient: vertical;
			overflow-y: auto;
		}

		&__vert {
			display: -webkit-box;
			-webkit-line-clamp: 11;
			-webkit-box-orient: vertical;
			margin-top: 20px;
			height: 500px;
			overflow-y: auto;
		}
	}

	&__price_box {
		font-size: 24px;
		font-weight: 500;
		line-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-left: 10px;
	}

	&__price_title {
		font-size: 14px;
		text-transform: uppercase;
		color: $text-disabled;
	}

	&__price_free {
		font-size: 28px;
	}

	&__phantom {
		background-color: transparent;
		border: 3px dashed $text-tiles;
    	border-radius: 20px;
	}
}

.no_image {
	object-fit: none;
}

.default_cursor {
	cursor: default;
	pointer-events: none;
}

.nested_icon {
	circle {
		fill: none;
	}

	rect {
		fill: none;
	}

	path {
		stroke: none;
		fill: $text-tiles;
	}

}

.nested_icon_dark {
	path {
		fill: $background-tile;
	}
}

