@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.invalid_tenant {
	padding: 40px;
	display: flex;

	&__message {
		width: 100%;
		font-size: 28px;
	}
}
