@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/tile";

.chapter_tile {
	@include common-tile();
	padding: 16px 0;
	cursor: pointer;
	transition: filter .3s ease-in-out;

	&:hover {
		filter: brightness(1);
		scale: $tiles-hover-scale;
	}

	&__not_active {
		filter: brightness(0.5);
	}

	&__inner_group {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
	}

	&__inner_icon {
		width: 24px;
		height: 24px;
	}

	&__icon_video {
		path {
			stroke: $text-tiles;
		}
	}

	&__head {
		display: flex;
		justify-content: space-between;
		padding: 0 16px 16px 16px;
	}

	&__header_text {
		font-weight: 400;
		font-size: 14px;
		color: $text-tiles;
		text-transform: uppercase;
	}

	&__description_picture {
		height: 216px;
	}

	&__description_img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__footer {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 16px 16px 0 16px;
		gap: 4px;
	}

	&__chapter_text {
		font-weight: 500;
		font-size: 18px;
		color: $common-gray;
		flex-basis: 90px;
	}

	&__chapter_desc {
		font-weight: 500;
		font-size: 18px;
		color: $text-tiles;
		flex: 1;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 45px;
	}

	&__outdated_tooltip {
		width: 300px;
		transform: translate(-100%,-75px);

	}
}
