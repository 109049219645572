@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";

.peers {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden;

	&__header {
		padding: 40px 74px;
		height: auto;

		@include media-extra-small-down() {
			padding: 40px 16px 32px;
		}

		@include media-extra-small-only2() {
			padding: 40px 32px;
		}
	}

	&__header_content {
		padding: 0;
	}

	&__header_label {
		margin-bottom: 0;
		line-height: 56px;

		@include media-extra-small-down() {
			font-family: $font-family-base;
			font-size: 48px;
			font-weight: 700;
			line-height: 56px;
			padding-top: 0;
		}

		@include media-extra-small-only2() {
			font-family: $font-family-base;
			font-size: 48px;
			font-weight: 700;
			line-height: 56px;
			padding-top: 0;
		}
	}

	&__page {
		color: $text-general;
		padding-top: 56px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		@include media-extra-small-down() {
			padding-top: 24px;
		}

		@include media-extra-small-only2() {
			padding-top: 32px;
		}
	}



	&__btn_add_mobile {
		display: none;

		@include media-extra-small-down() {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			@include primary_button();
			width: 48px;
			height: 48px;
			border-radius: 50%;
		}
	}

	&__section_label {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 48px;
		color: $text-general;
		margin: 0;
		padding-bottom: 24px;

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: 36px;
			padding-bottom: 16px;

		}

		@include media-extra-small-only2() {
			font-size: 24px;
			line-height: 36px;
			padding-bottom: 16px;
		}
	}

	&__section_label__curated {
		@include media-extra-small-down() {
			padding-left: 24px;
		}

		@include media-extra-small-only2() {
			padding-left: 16px;
		}
	}

	&__section__invert {
		background-color: $background-tile;
	}

	&__section_save {
		width: 100%;
		margin-bottom: 56px;

		@include media-extra-small-down() {
			padding: 32px 16px;
		}

		@include media-extra-small-only2() {
			padding: 32px 16px;
		}
	}

	&__gap_tile {
		column-gap: 32px;

		@include media-extra-small-down() {
			column-gap: 16px;
		}

		@include media-extra-small-only2() {
			column-gap: 16px;
		}
	}

	&__tiles_section {
		display: flex;
	}

	&__section {
		padding: 0 74px 30px 74px;
		overflow: hidden;
		position: relative;

		@include media-extra-small-down() {
			padding: 0 16px 32px 16px;
			width: 100vw;
		}

		@include media-extra-small-only2() {
			padding: 0 16px 32px 16px;
			width: 100vw;
		}
	}

	&__section_grid {
		padding: 0 74px 30px 74px;

		@include media-extra-small-down() {
			padding: 0 0 32px 0;
		}

		@include media-extra-small-only2() {
			padding: 0 0 32px 0;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 400px);
		gap: 32px;

		@include media-medium-only {
			grid-template-columns: repeat(3, 400px);
		}

		@include media-small-only {
			grid-template-columns: repeat(2, 400px);
		}

		@include media-extra-small-down() {
			grid-template-columns: repeat(1, 100vw);
			row-gap: 16px;
		}

		@include media-extra-small-only2() {
			grid-template-columns: repeat(1, 100vw);
			row-gap: 16px;
		}
	}

	&__section_curated {
		padding-bottom: 56px;

		@include media-extra-small-down() {
			padding-bottom: 16px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 16px;
		}
	}

	&__tile_curated_horiz {
		grid-column: 1 / span 2;
		grid-row: 2;
	}

	&__second_grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, 1fr);
		gap: 32px;
	}

	&__second_vert {
		grid-row: auto / span 2;
	}

	&__second_horiz {
		grid-column: auto / span 2;
	}

	&__carousel {
		height: 450px;
	}

	&__no_data {
		width: 100%;
		font-weight: 400;
		font-size: 16px;
		padding: 0;
		margin: 0;
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}


	&__picture {
		height: 446px;
		width: 100%;
		margin: 0 0 40px 0;

		@include media-extra-small-down() {
			height: 376px;
			margin: 0 0 24px 0;
		}

		@include media-extra-small-only2() {
			height: 266px;
			margin: 0 0 32px 0;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		padding-right: 72px;

		@include media-extra-small-down() {
			margin: 0 auto;
			padding-right: 0;
		}

		@include media-extra-small-only2() {
			margin: 0 auto;
			padding-right: 0;
		}
	}
}
.peers_banner {
	margin-bottom: 20px;
}

a.btn_add {
	text-decoration: none;
	@include primary_button();
	@include hoverable();
	padding: 12px 40px;
	display: block;
	max-width: 274px;
	font-size: 20px;
	text-align: center;
	border-radius: 24px;
	margin-left: auto;

	@include media-extra-small-down() {
		max-width: 100%;
		padding: 12px 80px;
	}

	@include media-extra-small-only2() {
		max-width: 100%;
		padding: 12px 260px;
	}
}
