@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.recipients_selector {
	width: 100%;

	&__chips_block {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 8px;

		@include media-extra-small-down {
			justify-content: space-between;
		}
	}

	&__remove_btn {
		background-color: $secondary;
		padding: 0 12px 0 20px;
		justify-content: space-between;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		width: auto;
		max-width: 50%;

		@include media-extra-small-down() {
			max-width: 100%;
		}

		@include media-extra-small-only2() {
			max-width: 100%;
		}
	}

	&__form_field {
		margin-bottom: 8px;
	}
}
