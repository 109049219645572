@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";

.peer_card {
	background-color: $background-tile;
	color: $text-tiles;
	border-radius: 0 0 16px 16px;
	position: relative;
	padding: 45px 40px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	column-gap: 32px;
	margin-bottom: 28px;

	@include media-extra-small-down() {
		flex-direction: column;
		padding: 24px 16px;
	}

	@include media-extra-small-only2() {
		flex-direction: column;
		padding: 24px 16px;
	}

	&__bookmark {
		margin: 0;

		@include media-extra-small-down() {
			margin-left: 10px;
		}
	}

	&__color_bar {
		width: calc(100% - 80px);
		height: 5px;
		border-radius: 0 0 10px 10px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	&__corner {
		position: absolute;
		left: 0;
		top: -40px;
		height: 40px;
		width: 40px;
	}

	&__icon {
		path {
			fill: $background-tile;
		}
	}

	&__icon_card {
		path {
			fill: $text-tiles;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid $text-tiles;
		padding-top: 24px;
		width: 100%;

		@include media-extra-small-down() {
			padding-top: 16px;
			flex-direction: column;
			align-items: flex-start;
		}

		@include media-extra-small-only2() {
			padding-top: 16px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__col {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 40px;

		@include media-extra-small-down() {
			flex-wrap: wrap;
			justify-content: flex-start;
			column-gap: 34px;
			grid-row-gap: 14px;
			margin-bottom: 26px;
		}

		@include media-extra-small-only2() {
			flex-wrap: wrap;
			justify-content: flex-start;
			column-gap: 34px;
			grid-row-gap: 14px;
			margin-bottom: 26px;
		}
	}

	&__fullname {
		@include ellipsis();
		font-family: $font-family-base;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		max-width: 170px;
		color: $text-tiles;
	}

	&__datetime {
		font-family: $font-family-base;
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
		opacity: .7;
		color: $text-tiles;
	}

	&__box {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		overflow-x: hidden;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 36px;
		line-height: 48px;
		margin: 0;
		padding-bottom: 16px;
		color: $text-tiles;

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: normal;
		}

		@include media-extra-small-only2() {
			font-size: 24px;
			line-height: normal;
		}
	}

	&__description {
		font-family: $font-family-base;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
		padding-bottom: 16px;
		word-wrap: break-word;
		word-break: break-word;
		white-space: pre-line;
		color: $text-tiles;
	}

	&__group {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 8px;

		font-family: $font-family-base;
		font-size: 14px;
		font-weight: 500;
		line-height: 14px;
		color: $text-tiles;
	}

	&__group__share {
		cursor: pointer;
		path {
			fill: $text-tiles;
		}
	}

	&__group__edit {
		cursor: pointer;
	}

	&__uploaded_attachments {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 24px;
		column-gap: 16px;
		width: 100%;
		overflow-x: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		@include media-extra-small-down() {
			padding-bottom: 16px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 16px;
		}
	}

	&__thumbnail {
		width: $comment-attachment-pic-height;
		height: $comment-attachment-pic-height;
		border-radius: 14px;
		background: transparent;
		cursor: pointer;

		@include media-extra-small-down() {
			width: 100%;
			&:not(:first-child) {
				display: none;
			}
		}

		@include media-extra-small-down() {
			width: $comment-attachment-pic-height-mobile;
			height: $comment-attachment-pic-height-mobile;
		}

	}

	&__thumbnail img {
		border-radius: 14px;
		background: transparent;
	}

	&__attachment_pic {
		width: 100%;
		height: inherit;
	}

	&__hide {
		display: none;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 16px;
	}

	&__picture{
		height: 446px;
		width: 100%;
		margin: 0;
		border-radius: 16px 16px 0 0;
		overflow: hidden;

		@include media-extra-small-down() {
			height: 200px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
