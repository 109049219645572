@import "src/styles/colors.scss";
@import "src/styles/variables.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/profile.scss";
@import "src/styles/header.scss";
@import "src/styles/mixins.scss";

.profile_layout {
	@include header;

	display: flex;
	flex-direction: column;
	min-width: $content-width-min;
	width: 100%;
	background-color: $background-layout;

	&__submenu {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;

		@include media-small-down() {
			justify-content: space-between;
			flex-wrap: wrap;
		}

	}

	&__subheader {
		margin: 30px $private-layout-margin-big 0;
		@include media-medium-only {

		}
		@include media-small-only {
			margin: 20px $private-layout-margin-small 0;
		}
		@include media-extra-small-only {
			margin: 14px  $private-layout-margin-extra-small 0;
		}
	}

	&__caption {
		@include ellipsis;
		margin-left: 30px;
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 36px;
		color: $text-general;
		padding-bottom: 20px;
		max-width: 100%;

		@include media-extra-small-down() {
			font-size: 28px;
			padding-bottom: 8px;
			margin-left: 0;
		}
	}

	&__body {
		flex: 1 1 auto;
		width: 100%;
	}

	&__scroll {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1 1 auto;
	}
}
.marginless {
	margin: 0;
}
