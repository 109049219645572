@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

$toggle-background-color-on: $primary-green;
$toggle-background-color-off: $background-gray;
$toggle-control-color: $common-white;
$toggle-width: 50px;
$toggle-height: 28px;
$toggle-gutter: 3px;
$toggle-radius: 50%;
$toggle-control-speed: 0.15s;
$toggle-control-ease: ease-in;

$toggle-radius: calc($toggle-height / 2);
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.toggler {
	display: flex;
	position: relative;
	padding-left: $toggle-width;
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
	height: 28px;
	z-index: 5;

	.toggler_input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	&__theme__small {
		height: 16px;
		margin-bottom: 0;
	}

	:checked ~ .control {
		background-color: $toggle-background-color-on;

		&:after {
			left: $toggle-width - $toggle-control-size - $toggle-gutter;
		}
	}

	.control {
		position: absolute;
		top: 0;
		left: 0;
		height: $toggle-height;
		width: $toggle-width;
		border-radius: $toggle-radius;
		background-color: $toggle-background-color-off;
		transition: background-color $toggle-control-speed $toggle-control-ease;

		&:after {
			content: "";
			position: absolute;
			left: $toggle-gutter;
			top: $toggle-gutter;
			width: $toggle-control-size;
			height: $toggle-control-size;
			border-radius: $toggle-radius;
			background: $toggle-control-color;
			transition: left $toggle-control-speed $toggle-control-ease;
		}
	}

	.control__theme__small {
		height: 16px;
		width: 27px;

		&:after {
			width: 12px;
			height: 12px;
			top: 2px;
		}
	}

	:checked ~ .control__theme__small {
		&:after {
			left: 12px;
		}
	}

	&__is_disabled {
		pointer-events: none;
		opacity: 0.7;

		:checked ~ .control {
			background-color: $toggle-background-color-off;
		}
	}
}
