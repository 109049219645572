@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/animation";
@import "src/styles/mixins";

.header_dashboard_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $background-grid;
	color: $text-grid;
	border-radius: 16px 16px 0 0;
	padding: 16px;
	width: 100%;

	&__title {
		font-size: 36px;
		font-weight: 700;
		@include ellipsis();

		@include media-extra-small-down() {
			font-size: 24px;
		}

		@include media-extra-small-only2() {
			font-size: 24px;
		}
	}

	&__icon_edit {
		@include fadeIn(1s);
		cursor: pointer;
		margin-right: 10px;

		path {
			fill: $text-grid;
		}

		@include media-extra-small-only2() {
			margin-right: 0;
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
		@include fadeIn(1s);
	}
}
