@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.simple {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&__header {
		display: flex;
		width: 100%;
		padding: 0 48px;
		justify-content: center;
		align-items: center;
		background-color: $background-navbar;
		color: $text-layout-header;
		height: 165px;
		border-bottom: 1px solid $text-layout-header;

		@include media-extra-small-down() {
			padding: 6px 16px;
			width: 100vw;
			height: auto;
		}

		@include media-extra-small-only2() {
			padding: 12px 32px;
			width: 100vw;
			height: auto;
		}
	}

	&__logo {
		min-height: 69px;
		min-width: 69px;

	}

	&__article {
		flex: 1;
		margin: 0 auto;
	}
}
