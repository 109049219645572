@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.cms_tile_user {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	background-color: $background-white;
	color: $text-general;
	padding: 16px;
	position: relative;
	z-index: 0;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: inline-block;
		z-index: 1;
		border-bottom: 1px solid $background-gray;
	}

	&:first-child {
		border-top: 1px solid $background-gray;
	}

	&__avatar {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		padding-right: 16px;
	}

	&__middle_part {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		flex: 1;
	}

	&__company {
		@include ellipsis();
		flex-basis: 100px;
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		color: $text-secondary;
		text-align: right;
	}

	&__email {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		@include ellipsis();
		padding-bottom: 8px;
		width: 100%;
		max-width: 170px;
	}

	&__name {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $text-general;
		@include ellipsis();
		padding-bottom: 8px;
		width: 100%;
		max-width: 170px;
	}

	&__status {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		color: $text-general;
		background: $background-gray;
		border-radius: 13px;
		padding: 3px 16px;
		text-transform: uppercase;
	}

	&__status_success {
		background-color: $success-light;
		color: $success-dark;
	}
}
