@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/profile.scss";

.carousels_array {

	&__lessons_section {
		margin-bottom: 40px;
		&__dark {
			padding: 30px 0;
			background-color: $background-tile;

			@include media-small-down() {
				padding: 30px 0;
			}

			@include media-extra-small-down() {
				padding: 16px 0 40px;
				margin-bottom: 32px;
			}
		}
	}

	&__section_type {
		margin: 0 0 6px 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		letter-spacing: 0.06em;
		padding: 0 70px;
		color: $primary;

		@include media-extra-small-down() {
			padding: 0 8px;
		}

		@include media-extra-small-only2() {
			padding: 0 16px;
		}

		&__primary {
			color: $primary;
		}
	}

	&__section_label {
		margin: 0 0 32px 0;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
		padding: 0 70px;
		color: $text-general;

		&__dark {
			color: $text-tiles;
		}

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: 30px;
			padding: 0 32px;
		}

		@include media-extra-small-only2() {
			padding: 0 16px;
		}
	}

	&__tiles_section {
		display: flex;
		overflow: hidden;
		padding: 0 70px;

		@include media-extra-small-down() {
			overflow: unset;
			padding: 0 8px;
		}

		@include media-extra-small-only2() {
			overflow: unset;
			padding: 0 16px;
		}
	}

	&__lesson_tile {
		margin-right: 32px;
	}

}
