@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.adv_search_form {

	margin-bottom: 20px;
	@include media-extra-small-down() {
		margin-bottom: 10px;
	}

	& #{&}__search_field {
		& input {
			border-radius: 26px;
			width: 973px;
			height: 45px;

			@include media-extra-small-down() {
				width: 95vw;
			}

			@include media-extra-small-only2() {
				width: 95vw;
			}

			@include media-compact-only() {
				width: 70vw;
			}
		}

		& label {
			transform: translate(17px, 15px);
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		padding-top: 12px;
	}

	&__search_btn {
		padding: 14px 20px;
		border-radius: 24px;
		font-size: 16px;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 95vw;
		}
	}

	@include media-extra-small-down() {
		&__search_field {
			& input {
				width: 80vw;
			}
		}

		&__row {
			flex-wrap: wrap;
			flex-direction: column;
		}

		&__search_btn {
			padding: 5px 20px;
			font-size: 14px;
		}

	}

	@include media-extra-small-only2() {
		& input {
			width: 65vw;
		}
		&__row {
			flex-wrap: wrap;
		}

	}

	@include media-small-only(){
		&__search_field {
			& input {
				width: 50vw;
			}
		}
	}

}
