@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.quiz_results {
		@include status;
		@include cell_name;
		@include cell_avatar;
		@include cell_text;

		padding: 4vh 3vw;

		&__status {
			text-align: center;
    		width: fit-content;
			white-space: nowrap;
		}

		&__carousel {

				overflow: hidden;
				position: relative;
				padding-bottom: 36px;

				@include media-extra-small-down() {
					width: 95vw;
				}

				@include media-extra-small-only2() {
					width: 95vw;
				}

		}

		&__lesson_tile {
		margin-right: 32px;
		}

		&__cell_picture {
			margin-right: 12px;
		}

		&__body {
				padding: 28px 0;

				@include media-extra-small-down() {
						padding: 24px 0;
				}

				@include media-extra-small-only2() {
				}
		}

		&__table {
			td {
				vertical-align: middle;
				&:nth-child(1) {
					width: 5%;
					padding: 10px;
				}

				&:nth-child(3) {
					padding: 18px 12px 0 0;
					width: 15%;
					max-width: 25vw;
					white-space: break-spaces;
					@include media-extra-small-only2() {
						width: 25vw;
					}
				}

				&:nth-child(4) {
					padding: 18px 0;
					max-width: 10vw;
					width: 20%;
				}

				&:nth-child(5) {
					width: 5%;
					padding-top: 18px;
				}

				&:nth-child(6) {
					width: 45%;
					padding: 10px;

				}


				@include media-extra-small-only2() {
					&:nth-child(1) {
						padding-left: 24px;
					}

					&:nth-child(2) {
						padding-right: 24px;
					}

					&:nth-child(3) {
						padding-right: 24px;
					}
				}
			}

			th {
				&:nth-child(3) {
					@include media-extra-small-only2() {
						display: none;
					}
				}
			}
		}

}

.filter_menu {
	width: fit-content;
}

.controll_cell {
	display: flex;
	justify-content: space-around;
    align-items: center;
	max-width: 10vw;
}

.play_icon {
	path {
		stroke: $common-black;
	}
}

.note_cell {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.show_more_btn {
	color: $sky-blue;
	font-weight: 500;
	padding: 0;
	margin-top: 5px;
}

.content_type_tooltip {
	transform: translate(30%, -150%);
    text-align: center;
}

.question_cell {
	padding: 5px 0 5px 8px;
	border-bottom: 1px solid $background-gray;
	display: flex;
	justify-content: space-between;
	align-items: center;
    max-height: fit-content;
    white-space: break-spaces;
	&:last-child {
		border: none;
	}

}

.question_text {
	font-size: 14px;
}

.quiz_icon {
	display: flex;
	justify-content: center;
	align-items: center;
}
