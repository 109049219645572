@import "src/styles/colors.scss";
@import "src/styles/variables.scss";

@mixin common-tile {
	width: $carousel-slide-width;
	min-width: 330px;
	border-radius: 16px;
	padding: 18px 0;
	background-color: $background-tile;
	color: $text-tiles;

	@include media-extra-small-down() {
		width: auto;
	}

	@media screen and (max-width: 500px) {
		width: calc(100vw - 30px);
	}

	&__light {
		background-color: $text-tiles;
		color: $background-tile;
	}

	&__header {
		padding: 0 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__info_container {
		padding: 0 18px;

		&__compact {
			padding: 0 8px;
		}
	}

	&__compact {
		$compact_width: 315px;
		$compact_heigth: 285px;
		min-width: $compact_width;
		width: $compact_width;
		height: $compact_heigth;
		padding: 8px 0;
	}
}
