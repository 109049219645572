@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.goals_carousels {
	padding-top: 36px;
	min-height: 630px;

	&__title {
		font-weight: 700;
		font-size:  28px;
		line-height: 36px;
		margin: 0;
		padding-bottom: 22px;
	}

	&__section {
		overflow: hidden;
		position: relative;
		padding-bottom: 36px;

		@include media-extra-small-down() {
			width: 100vw;
		}

		@include media-extra-small-only2() {
			width: 100vw;
		}
	}

	&__tiles_section {
		display: flex;
	}

	&__gap_tile {
		column-gap: 32px;

		@include media-extra-small-down() {
			column-gap: 16px;
		}

		@include media-extra-small-only2() {
			column-gap: 16px;
		}
	}
}
