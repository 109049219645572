@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.description {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	letter-spacing: 0.15px;


	&__wrap {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}

	&__compact {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 72px;
		overflow: hidden;
		text-align: justify;
	}

	&__full {
		text-align: left;
		word-break: break-word;
		white-space: pre-line;
		overflow-y: auto;
	}

	&__show_more {
		display: block;
		font-size: 16px;
		font-weight: 400;
		padding: 0;
		margin-top: 10px;

		&:hover {
			background-color: transparent;
		}
	}

	&__hide {
		display: none;
	}
}
