@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.motif_statistics_tile {
	display: flex;
	flex-direction: column;
	padding: 6px 10px;
	border-bottom: 1px solid $background-gray;
}

.data_section {
	display: flex;
	justify-content: space-between;
}

.course_title {
	font-weight: bold;
	margin-bottom: 6px;
}

