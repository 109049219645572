@import "src/styles/profile.scss";
@import "src/styles/mixins.scss";

.group_profile {
	@include profile;

	&__tooltip {
		width: 275px;
	}

	&__tile {
		width: 80%;
		margin-left: 9%;

		&_wrapper {
			width: 80%;
			margin: 2% 9%;
		}

		&_label {
			@include ellipsis;
			font-family: $font-family-base;
			font-weight: bold;
			font-size: 36px;
			margin: 0;
			line-height: unset;
			
			@include media-extra-small-down() {
				font-size: 24px;
			}

			&_wrapper {
				display: flex;
				align-items: center;
			}

			&_small {
				font-size: 16px;
				margin-right: 10px;
			}
		}

		&_control {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;

		@include media-extra-small-down() {
			display: none;
		}

		@include media-extra-small-only2() {
		}
	}

	&__button, &__no_data {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: $text-layout-header;
		border: none;
		text-transform: uppercase;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 20px;
		}

		@include media-extra-small-down() {
			padding: 4px;
			font-size: 12px;
		}

		@include media-extra-small-only2() {
			padding: 10px 5px;
			font-size: 16px;
		}

		@include media-compact-only() {
			padding: 10px 15px;
			font-size: 16px;
		}

		@include media-middle-only(){
			padding: 10px 20px;
		}

		&_active {
			color: $primary;
			border-top: 3px solid;
		}
	}
}


