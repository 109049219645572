@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.player_external_link_box {
	position: relative;
	overflow: hidden;
	height: 640px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $background-player;

	@include media-extra-small-down() {
		height: auto;
		min-height: 230px;
		padding: 30px 0;
	}

	@include media-extra-small-only2() {
		height: 386px;
	}

	&__color_bar {
		width: calc(100% - 80px);
		height: 5px;
		border-radius: 0 0 10px 10px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		background-color: $primary-purple;
	}

	&__wrap {
		background-color: $background-tile;
		color: $text-tiles;
		border-radius: 16px;
		position: relative;
		padding: 45px 40px 40px 40px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 70%;

		@include media-extra-small-down() {
			flex-direction: column;
			padding: 24px 16px;
			width: calc(100% - 20px);
		}
	}

	&__body {
		width: 100%;
		max-height: 80%;
		position: relative;
	}

	&__footer {
		margin-top: 24px;
		padding: 12px 32px 0 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		width: 100%;
		border-top: 2px solid #212121;

		@include media-extra-small-down() {
			padding: 12px 0 0 0;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 20px;
		}
	}

	&__title {
		@include ellipsis();
		font-weight: 700;
		font-size: 36px;
		margin-bottom: 16px;
		font-style: normal;

		@include media-extra-small-down() {
			font-size: 20px;
			white-space: normal;
		}
	}

	&__description {
		color: #B6B6B6;
		line-height: 24px;
		white-space: pre-line;
		max-height: 300px;
		overflow-y: auto;
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
	}

	&__btn {
		padding: 12px 40px;
		background: $primary-red;
		border-radius: 24px;
		color: white;
		text-decoration: none;
		font-weight: 500;
		font-size: 20px;
		text-align: center;
		letter-spacing: 0.06em;
		display: block;

		@include media-extra-small-down() {
			font-size: 16px;
		}
	}
}
