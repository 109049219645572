@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.quizResult_table_tile {
	display: flex;
	flex-direction: column;
	margin-bottom: 18px;
}

.quiz_info {
	display: flex;
	margin-bottom: 4px;
}

.quiz_tile {
	display: flex;
	justify-content: space-between;
    font-weight: bold;
    width: 100%;
	border-bottom: 1px solid $background-gray;
}

.question_cell {
	border-bottom: 1px solid $background-gray;
	padding: 10px 5px;
	display: flex;
    justify-content: space-between;
	align-items: center;
	max-height: fit-content;
	white-space: break-spaces;

}

.question_result {
	@include status;
}

.updated_date {
	font-weight: normal;
	color: $text-secondary;
}

.scrollbar {
	@include scrollbar(4px, rgba($background-gray, 0.5));
	overflow-y: scroll;
}
