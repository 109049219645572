@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.register_page {
	background: $background-layout;
	border-radius: 20px;
	width: 615px;
	padding: 36px;

	@include login-form();

	@include media-extra-small-down() {
		padding: 9px 16px;
		border-radius: 0;
		min-height: 100vh;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
	}

	&__text_inner {
		padding-right: 5px;
	}

	&__link {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		background: none;
		border: none;
		padding: 0;
		color: $text-general;
		text-decoration: underline;
		cursor: pointer;
	}

	&__link_inner {
		padding-right: 5px;
		padding-left: 5px;
	}

	&__fields_wrap {
		padding-bottom: 24px;
	}

	&__fields_container {
		:nth-child(5) {
			margin-bottom: 10px;
		}

	}


	&__password_text {
		margin-bottom: 12px;
		padding: 0 6px;
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	& #{&}__btn_sign_in {
		background: $primary;
		padding: 12px;
		border-radius: 8px;
		text-transform: none;
		margin-bottom: 0;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.06em;
		color: $text-primary;
		line-height: 24px;
		width: 100%;
		border: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__text_block {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 50%;
			display: inline-block;
			z-index: 0;
			border-bottom: 1px solid $background-dark;
		}
	}

	&__checkbox_inner {
		padding-top: 4px;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: $text-general;
	}

	&__spiral {
		display: block;
		user-select: none;
		cursor: default;
		width: 224px;
		height: 103px;
		margin: 0 0 33px 0;
		mix-blend-mode: lighten;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__icon_box {
		position: absolute;
		left: -26px;
		top: 2px;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	&__icon {
		animation: spinner-border .75s linear infinite;
	}

	@keyframes spinner-border {
		to {
			transform: rotate(1turn)
		}
	}

	& #{&}__btn_disable {
		pointer-events: none;
		background-color: $error-dark;
	}

	&__field_inner {
		margin-bottom: clamp(24px, 26px, 28px);
	}
}
