@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";
@import "src/styles/animation";

.motif_session_item {
	display: flex;
	height: 80px;
	flex-direction: column;
	padding: 2px 10px;
	border-radius: 20px;
	background-color: $common-gray-light;
	color: $other-outline-gray;
	font-size: 14px;
	font-weight: 500;

		&__title_container {
			display: flex;
			align-items: center;
			margin-bottom: 4px;
		}

		&__title {
			text-overflow: ellipsis;
			max-width: 400px;
    		overflow: hidden;

			&__dark {
				color: $text-general;
			}
		}

		&__short {
			max-width: 100px;
		}

		&__details_container {
			display: flex;
			gap: 8px;
		}

		&__details_item {
			display: flex;
			flex-direction: column;
		}

		&__details_chip {
			color: $text-grid;
			width: 80px;
			background-color: rgba(93, 95, 239, 0.2);
			border-radius: 12px;
			text-align: center;

			&__negative {
				background-color: rgba(239, 93, 168, 0.2);
			}
		}

		&__details_time {
			padding-left: 4px;
		}
}

.tooltip {
	transform: translate(10%, -15px);

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__title {
		max-width: 450px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__body {
		font-weight: 400;
	}
}

.tooltip_icon {
	margin-left: 4px;
	height: 20px;
	path {
		fill: $other-outline-gray;
	}
}
