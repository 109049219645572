@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/player";
@import "src/styles/adaptive";

.player_layout {
	position: relative;
	@include nodata;

	background-color: $background-player;
	width: 100%;

	@include media-extra-small-down() {
		min-height: 230px;
	}

	@include media-extra-small-only2() {
		min-height: 386px;
	}

	&__min_height {
		min-height: 640px;
	}

	&__min_height_scorm {
		min-height: 672px;

		@include media-extra-small-down() {
			min-height: auto;
		}

		@include media-extra-small-only2() {
			min-height: auto;
		}
	}
}
