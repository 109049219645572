.views_counter {
	&__container {
		display: flex;
		align-items: center;
	}


	&__icon {
		margin-right: 9px;
	}
}
