@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.adv_search_filter {

	&__row{
		padding-top: 12px;
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;

		@include media-extra-small-down() {
			flex-wrap: wrap;
		}
	}

	&__col {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 38px;
		gap: 12px;
		background-color: $input-background !important;
		color: $text-tiles;
		margin: 0;

		@include media-extra-small-down() {
			font-size: 12px;
		}
	}

	&__select_container_category {
		max-width: 21% !important;
	}

	&__select_container_source {
		width: 19%;
	}

	&__select_container{
		border-radius: 110px;
		max-width: 20%;
		height: 38px;
		z-index: 11;
		@include media-extra-small-down() {
			max-width: 30%;
		}
	}

	.filter_button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		height: 35px;
		background-color: $input-background;
		border: none;
		padding: 0 4px 0 5px;

		@include media-extra-small-down() {
			margin: 2px 0;
			width: 100%;
		}

		@include media-extra-small-only2() {
			margin: 2px 0;
			width: 100%;
		}
	}

}

