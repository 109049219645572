@import "./colors.scss";
@import "./adaptive.scss";
@import "./variables.scss";

$popup-side-padding: 20px;
$popup-side-padding-small: 10px;
$popup-header-letter-spacing: -0.02em;

@mixin popup-header() {
	font-family: $font-family-base;
	font-weight: 500;
	font-size: 26px;
	line-height: 1.2;
	letter-spacing: $popup-header-letter-spacing;
	padding-top: 15px;
	margin: 10px $popup-side-padding 0;
	color: $text-general;
	flex: 0 0 auto;

	@include media-extra-small-only() {
		padding: 10px 0;
		margin: 0 $popup-side-padding-small;
		font-size: 20px;
	}
}

@mixin popup-content() {
	flex: 1 1 auto;
	padding: 20px $popup-side-padding 0;

	@include media-extra-small-only() {
		padding: 10px $popup-side-padding-small 0;
	}
}

@mixin popup-footer() {
	padding: 30px $popup-side-padding;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.button {
		margin-left: 0;
		margin-right: 10px;
	}

	@include media-extra-small-only() {
		padding: 10px $popup-side-padding-small;

		.button {
			margin-left: 0;
			margin-right: 5px;
		}
	}
}
