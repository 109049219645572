@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.two_factor_auth_form {
	background: $background-layout;
	flex: 1;
	border-radius: 20px;
	max-width: 500px;
	padding: 3rem 2rem;
	border: 1px solid $text-general;

	@include media-extra-small-down() {
		padding: 9px 16px;
		min-height: 100vh;
	}

	&__logo_box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		flex-direction: column;
	}

	&__2fa_codes_section {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__qr_code {
		margin-top: 10px;
		height: 200px;
	}

	&__auth_code_container {
		padding: 0 10px;
	}

	&__button {
		width: 100%;
		margin-top: 30px;
		border-radius: 8px;
	}

}
