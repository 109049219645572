@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";

.body_dashboard {
	height: 100%;
	background-color: $background-grid;
	color: $text-grid;
	min-height: 100vh;
	border-radius: 0 0 16px 16px;

	&__loading{
		min-height: 300px;
	}
}
