@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.alert {
	
	@include media-extra-small-only() {
		width: 90vw;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 16px;
		line-height: 24px;
		cursor: default;
		color: $common-white;
		white-space: pre-wrap;
  	word-break: break-word;
	}

	&__icon_box {
		width: 20px;
		height: 20px;
		cursor: pointer;
		margin-left: 10px;
	}

	&__icon {
		path {
			stroke: $common-white;
		}
	}

	&__theme_red {
		background-color: $error-main !important;
	}

	&__theme_green {
		background-color: $success-main !important;

	}
}
