@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";



.player_scorm_lite {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding-bottom: 7%;
	background-color: $background-player;

	&:fullscreen {
		.player_html__document {
			height: 100vh;
		}
	}

	&::before,
	&::after {
		content: "";
		flex: 1;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	@include media-extra-small-down() {
		height: 210px;
	}

	@include media-extra-small-only2() {
		height: 366px;
	}

	&__document {
		aspect-ratio: 16/9;
		margin: 0 auto;
		overflow: hidden;
		height: 650px;
		display: block;
		border: none;
		position: relative;
		width: 100%;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__btn_play {
		text-decoration: none;
		@include primary_button();
		padding: 12px 24px 8px 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 18px;
		font-weight: 700;
		z-index: 10;
	}

	&__btn_play span {
		height: 28px;
	}

	&__icon {
		height: 24px;
		width: 24px;
	}

	&__box {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(128, 128, 128, .9);
		z-index: 0;
	}

	&__btn {
		background-color: transparent;
		cursor: pointer;
		padding: 0;
		border: none;
		width: 24px;
		height: 24px;
		z-index: 1;
	}

	&__btn_fullscreen {
		position: absolute;
		right: 15px;
		bottom: 15px;
		z-index: 10;
	}

	&__icon_compress {
		height: 18px;
		width: 18px;

		path {
			fill: $common-white;
		}

		@media screen and (max-width: $breakpoint-extra-small - 1px) and (orientation: landscape) {
			path {
				fill: $common-black;
			}
		}

		@media screen and (min-width: $breakpoint-extra-small) and (max-width: $breakpoint-small - 1px) and (orientation: landscape) {
			path {
				fill: $common-black;
			}
		}
	}

	&__icon_full {
		path {
			stroke: $common-white;
		}

		@include media-extra-small-down() {
			path {
				stroke: $common-black;
			}
		}

		@include media-extra-small-only2() {
			path {
				stroke: $common-black;
			}
		}
	}

	&__hide {
		display: none;
	}
}
