@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/profile.scss";
@import "src/styles/header.scss";
@import "src/styles/mixins.scss";


.assign_groups_container{
	h4 {
		margin-bottom: 4px;
	}

	ul {
		margin: 0;
		padding-left: 15px;
	}

	&__group_item {
		margin: 5px 0;
	}
}
