@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.course_card_box {
	max-height: 0;
	transition: max-height 0.5s ease-out;
	width: 100%;
	position: relative;

	&__show {
		max-height: 500px;
		transition: max-height 0.5s ease-in;
	}
}
