@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.alert {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 80px;
	transform: translateX(-50%);
	padding: 12px 16px;
	box-shadow: 0 5px 20px rgba(242, 79, 79, 0.23);
	border-radius: 8px;
	z-index: 1000;
	width: 50vw;
	word-break: break-word;
	white-space: break-spaces;

	@include media-extra-small-only() {
		width: 90vw;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 16px;
		line-height: 24px;
		cursor: default;
		color: $common-white;
	}

	&__icon_box {
		width: 20px;
		height: 20px;
		cursor: pointer;
		margin-left: 10px;
	}

	&__icon {
		path {
			stroke: $common-white;
		}
	}

	&__theme_red {
		background-color: $error-main;

	}

	&__theme_green {
		background-color: $success-main;

	}
}
