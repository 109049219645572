@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/animation";

.recommendations_alert {
	position: absolute;
	top: 14px;
	right: 0;
	width: 180px;
	z-index: 1000;
	transition: .7s ease;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-color: $secondary;
	border-radius: 16px 0 0 16px;
	padding: 10px 16px;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;

	&__show {
		width: 210px;
		opacity: 1;
		visibility: visible;
	}

	&__text {
		color: $text-secondary;
		font-weight: 500;
		font-size: 16px;
		flex: 1;
		text-align: center;
		cursor: pointer;
	}

	&__close_icon {
		cursor: pointer;
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;

		path {
			stroke: $text-secondary;
		}

		&:hover {
			path {
				stroke-width: 2;
			}
		}
	}
}
