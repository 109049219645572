@import "src/styles/colors";
@import "src/styles/adaptive";

.avatar_container {
	display: flex;
}

.avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: default;
	background: linear-gradient(180deg, $avatar-empty 0%, $avatar-empty 100%);
	border-radius: 50%;

	&__text {
		color: $text-general;
		font-weight: 500;

		&_small {
			font-size: 16px;
			line-height: 16px;
		}

		&_medium {
			font-size: 32px;
			line-height: 32px;
		}

		&_compact {
			font-size: 20px;
			line-height: 20px;
		}
	}

	&__size {
		&_small {
			width: 36px;
			height: 36px;
			min-width: 36px;
		}

		&_medium {
			width: 58px;
			height: 58px;
			min-width: 58px;
		}

		&_compact {
			width: 48px;
			height: 48px;
			min-width: 48px;
		}

		&_little {
			width: 32px;
			height: 32px;
			min-width: 32px;
		}

		&_large {
			width: 68px;
			height: 68px;
			min-width: 68px;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
		background: $common-black;
	}

	&__cursor_on {
		cursor: pointer;
	}

	&__empty {
		background: $secondary;
	}

	&__hint_icon {
		margin-left: 2px;
		path {
			opacity: 0.5;
		}
	}
}

.avatar__tooltip {
	width: 300px;
    left: 100%;
    bottom: 100%;

	@include media-extra-small-down() {
		width: 70vw;
		left: -15vw;
    	bottom: 110%;
	}
}
