@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.star_rating {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;

	&__filled {
		path {
			fill: $primary;
			stroke: $primary;
		}
	}
}

.star_icon {
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;

	&__disabled {
		cursor: auto;
	}
}
