@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/header";

.cms_layout {
	@include header;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: $background-layout;

	&__body {
		flex: 1;
		width: 100%;
		padding: 20px 60px;
		background-color: $background-layout;
		color: $text-general;
		max-width: 1930px;

		@include media-extra-small-down() {
			padding: 8px 0 0 0;
		}

		@include media-extra-small-only2() {
			padding: 10px 0 0 0;
		}
	}
}

