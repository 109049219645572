@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.ai_summary_button {
	
	display: block;
	font-size: 16px;
	font-weight: 400;
	padding: 0;
	background: transparent;
	border: none;
	color: $primary;
	cursor: pointer;
	@include hoverable;

	&__link {
		margin-bottom: 10px;
		margin-top: 10px;

		&:hover {
			background-color: transparent;
		}
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		  }
		  25% {
			transform: scale(1.05);
			opacity: 1;
		  }
		  50% {
			transform: scale(0.9);
			opacity: 0.8;
		  }
		  75% {
			transform: scale(1.05);
			opacity: 1;
		  }
		  100% {
			transform: scale(1);
			opacity: 1;
		  }
	}

	&__icon {
		animation: pulse 1s infinite ease-in-out;
	}
}
