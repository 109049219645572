.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
	bottom: 5px;
	right: 5px;
}

.react-grid-item.react-grid-placeholder {
	background-color: var(--background-layout, #ffffff);
	opacity: 0.5;
	border-radius: 16px;
}
