@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/animation";

.discussion {
	margin: 8px 4px 24px;
	background-color: $background-tile;
	color: $text-tiles;
	border-radius: 16px 16px 16px 0;
	padding: 16px;
	position: relative;
	width: calc(100% - 10px);
	height: auto;

	&__header {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		margin-bottom: 16px;
	}

	&__inner_group {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		cursor: pointer;
	}

	&__footer {
		margin-top: 16px;
	}

	&__edit_icon {
		width: 20px;
		height: 20px;

		path {
			fill: $text-tiles;
		}
	}

	&__text_info {
		font-weight: 500;
		font-size: 14px;
	}

	&__corner {
		position: absolute;
		left: 0;
		bottom: -24px;
		height: 24px;
		width: 24px;
		transform: scaleY(-1);
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.15px;
		word-break: break-word;
		white-space: pre-line;
		overflow-y: auto;

		@include fadeIn(0.5s);
	}

	&__form {
		@include fadeIn(0.7s);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}

	&__field_title {
		margin-bottom: 24px;
		width: 100%;

		& textarea {
			height: 148px;
		}
	}

	&__btn_group {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		column-gap: 16px;
		width: 100%;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__btn_cancel {
		width: 50%;
	}

	&__btn_save {
		width: 50%;
	}

	&__icon_spinner {
		@include spinner();
	}
}
