@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";

.input_field {
	position: relative;

	::-webkit-calendar-picker-indicator {
		filter: invert(1);
		cursor: pointer;
	}

	&__textarea textarea {
		padding: 22px 16px 9px 16px;
		background: $input-background;
		border-radius: 8px;
		border: 1px solid $text-input-primary;
	}

	& #{&}__invalid {
		border: 1px solid $error-main;
	}
}

.input_field__input {
	@include scrollbar(4px, rgba(#DCDCDC, 0.5));
	appearance: none;
	outline: none;
	width: 100%;
	font-family: $font-family-base;
	font-size: 16px;
	color: $text-input-primary;
	padding: 22px 16px 9px 16px;
	transition: all 0.2s;
	background: $input-background;
	border-radius: 8px;
	border: 1px solid $text-input-primary;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;
	resize: none;
	height: 100%;

	&__with_prefix {
		padding-left: 30px;
	}

	&:disabled {
		opacity: 0.6;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background: $input-background;
		border: 1px solid $text-input-primary;
		-webkit-text-fill-color: $text-input-primary;
		transition: background-color 5000s ease-in-out 0s;
		-webkit-box-shadow: 0 0 0 1000px $input-background inset !important;
	}

	&:focus {
		cursor: text;
	}

	&::placeholder {
		color: transparent !important;
		user-select: none !important;
	}

	&--invalid {
		border: 1px solid $error-main;
	}

	&__input_icon {
		position: absolute;
		right: 17px;
		top: 27px;
		transform: translateY(-50%);
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	&__textarea {
		padding: 0;
		border-radius: unset;
	}

	&[type=number]::-webkit-inner-spin-button,
	&[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input.input_field__input {
	height: 52px;
}

.input_field__field_label {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	transform-origin: 0 0;
	transform: translate3d(16px, 17px, 0);
	transition: transform 0.2s;
	font-family: $font-family-base;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;
	color: $text-input-primary;
	opacity: 0.6;
}

.input_field__input:focus + .input_field__field_label,
.input_field__input:-webkit-autofill + .input_field__field_label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.input_field__input:not(:placeholder-shown) + .input_field__field_label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.input_field__input_error {
	padding-top: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	left: 0;

	&_box {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&_text {
		font-family: $font-family-base;
		font-size: 13px;
		color: $error-main;
		cursor: default;
	}
}

.input_field_prefix {
	position: absolute;
	transform: translate(15px, 24px);
	font-size: 16px;
	color: $text-input-primary;
	opacity: 0.6;
}
