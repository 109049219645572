@import "src/styles/colors.scss";

.facebook_login_button {
	width: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	margin-top: 12px;
	font-weight: 700;
	white-space: nowrap;
	letter-spacing: 0.07em;
	text-transform: uppercase;
	background: $facebook-blue;
	color: $common-white;
	height: 48px;
	border: 0;
	border-radius: 8px;
	transition: none !important;
}

.facebook_logo_icon {
	margin: 5px;
}
