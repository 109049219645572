@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.goals_histogram {
	background-color: $background-tile;
	width: 100%;
	border-radius: 16px;
	align-self: stretch;
	height: 340px;
	padding: 32px;

	@include media-extra-small-down() {
		min-width: auto;
		height: 385px;
	}

	@include media-extra-small-only2() {
		min-width: auto;
	}

	&__tooltip {
		background-color: white;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 25%);
		border-radius: 15px;
		padding: 10px;
	}

	&__tooltip_label {
		font-size: 15px;
	}

	&__tooltip_original {
		font-weight: 500;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 24px;
		width: 100%;
		gap: 25px;
	}

	&__title {
		color: $text-tiles;
		font-weight: 500;
		font-size: 36px;
		line-height: 36px;
		margin: 0;

		@include media-extra-small-down() {
			font-size: 18px;
			font-weight: 500;
			line-height: 26px;
		}

		@include media-extra-small-only2() {

		}
	}

	&__info_tooltip {
		width: 500px;
    	transform: translate(-40%, -125%);

		@include media-small-only() {
			transform: translate(-50%, -120%);
		}

		@include media-extra-small-only2() {
			transform: translate(-30%, -120%);
		}
		@include media-extra-small-down() {
			max-width: 90vw;
			transform: translate(-60%, -120%);
		}
	}

	&__tooltip__content {
		border: 1px solid $common-black;
		font-weight: 500;
		font-size: 14px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		background-color: $input-background;
		color: $text-input-primary;
	}

	&__info_icon {
		path {
			fill: $text-tiles;
		}
	}
}
