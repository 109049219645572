@import "src/styles/colors.scss";

.button_close {
	cursor: pointer;

	&__close_icon {
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
		padding: 0;
		border: none;
		cursor: pointer;

		path {
			stroke: $text-general;
		}
	}
}
