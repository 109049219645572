@import "src/styles/colors.scss";
@import "src/styles/adaptive";
@import "src/styles/animation";

.filter_menu{
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    background-color: $input-background;
	width: fit-content;
	min-width: 250px;
    border: solid 1px;
	margin-top: 5px;
	transform: translateX(-60%);

	@include media-extra-small-down() {
		max-width: 340px;
		width: 100%;
		transform: none;
	}

	@include media-extra-small-only2() {
		margin: 2px 0;
	}
}

.hide_menu {
	display: none;
}

.menu_button {
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
	width: fit-content;
	border-radius: 20px;
	padding: 0 10px;
	height: 100%;
	color: $text-secondary;
	background-color: $secondary;

	@include media-extra-small-down() {
		margin: 2px 0;
		width: 100%;
	}

	@include media-extra-small-only2() {
		margin: 2px 0;
	}

	&__clear_button {
		background-color: $secondary;
		color: $text-secondary;
		@include fadeIn(0.5s);
	}
}

.menu_button_container {
	width: fit-content;
	cursor: pointer;
}

.arrow_up {
	path {
		stroke: $common-black;
		stroke-width: 2;
	}
		transform: rotate(90deg);
}

.arrow_down {
	path {
		stroke: $common-black;
		stroke-width: 2;
	}
		transform: rotate(270deg);
}

.filter_icon {
	path {
		fill: $text-secondary;
		stroke-width: 1;
	}
	margin-left: 6px;
}


