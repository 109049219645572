@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.user_info {
	display: flex;
	position: relative;
	align-items: center;
	gap: 10px;
	cursor: pointer;

	&__details {
		max-width: 180px;

		&__subtitle {
			color: $background-navbar;
			filter: invert(0.6) brightness(120%);
		}

		@include media-extra-small-down() {
			display: none;
		}
	}

	& #{&}__avatar {
		cursor: pointer;
	}

	&__details__subtitle {
		@include ellipsis();
		max-width: 175px;
	}
}
