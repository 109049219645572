@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.player_html {
	position: relative;
	overflow: hidden;
	min-height: 640px;
	background-color: $background-player;

	&:fullscreen {
		.player_html__document {
			height: 100vh;
		}
	}

	@include media-extra-small-down() {
		height: 210px;
	}

	@include media-extra-small-only2() {
		height: 366px;
	}

	&__document {
		aspect-ratio: 16/9;
		margin: 0 auto;
		overflow: hidden;
		height: 640px;
		display: block;
		border: none;
		width: 100%;
	}
}
