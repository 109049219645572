@import "src/styles/colors.scss";

.google_login_button {
	background-color: $background-white !important;
	height: 48px !important;
	width: 100% !important;
	border-radius: 8px;
	border: 1px solid $text-primary;
	text-transform: uppercase;
}

.google_logo_icon {
	margin: 5px;
}
