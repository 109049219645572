@import "src/styles/colors.scss";

.search_filter {
	position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	flex: 1;
}

.search_reindex {
	position: absolute;
	display: flex;
	align-items: center;
	bottom: -30px;
	left: -5px;
}

.reindex_button {
	font-size: 14px;
	color: $secondary;
}

.spinner {
	margin-left: -10px;
}
