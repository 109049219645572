@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/profile.scss";

.payment_result_page {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	width: 100%;
	overflow: hidden;

	@include media-extra-small-down() {
		width: 100%;
	}


	&__content {
		color: $text-general;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@include media-extra-small-down() {
			padding: 10px;
		}
		&:first-child {
			margin-top: 56px;
		}
	}

	&__image {
		width: 25vw;
		height: 25vh;
		path {
			stroke: $success-main;
		}
	}
	&__text {
		font-size: 32px;
    	font-weight: 500;
	}

}
