@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

@mixin profile {
	max-width: $content-width-max;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-height: 100%;
	margin: 0 auto 40px;

	padding-left: $private-layout-margin-big;
	padding-right: $private-layout-margin-big;

	@include media-medium-only {
		padding-left: $private-layout-margin-medium;
		padding-right: $private-layout-margin-medium;
		margin: 30px 0;
	}

	@include media-small-only {
		padding-left: $private-layout-margin-small;
		padding-right: $private-layout-margin-small;
		margin: 20px 0;
	}

	@include media-extra-small-only {
		padding-left: $private-layout-margin-extra-small;
		padding-right: $private-layout-margin-extra-small;
		margin: 10px 0;
	}

	&__tile {
		width: 100%;
		height: fit-content;
		background-color: $background-grid;
		color: $text-grid;
		border-radius: 16px;
		border: 1px solid $background-gray;
		padding: 32px;
		margin-bottom: 16px;

		@include media-extra-small-down {
			padding: 12px;
		}
	}

	&__tile_label {
		font-size: 24px;
		line-height: 24px;
		margin: 0 0 24px;
	}

	&__label_container {
		display: flex;
		justify-content: space-between;
		align-content: center;
	}

	&__pictures_container {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		@include media-extra-small-only {
    		justify-content: flex-start;
    		width: 100%;
		}

		@include media-extra-small-down {
    		justify-content: space-around;
    		width: 100%;
		}

	}

	&__avatar {
		margin-right: 2px;
	}

	&__change_avatar_button {
		border-radius: 110px;
		background-color: $background-dark;
		color: $common-white;
		height: 32px;
		width: 132px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:hover {
			background-color: $other-outline-gray;
		}
	}

	&__change_avatar_input {
		display: none;
	}

	&__remove_avatar_button {
		margin-left: 16px;
		font-size: 14px;
	}

	&__content_container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		row-gap: 6px;
		column-gap: 6px;
		@include media-extra-small-only {
			flex-direction: column;
		}
	}

	&__form_field {
		width: 49%;
		margin-bottom: 24px;
		@include media-extra-small-only {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	&__save_button_container {
		display: flex;
		justify-content: flex-end;
	}

	&__save_button {
		@include media-extra-small-only {
			width: 100%;
		}
	}
}
