@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.goals_form {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	padding-bottom: 10px;
	width: 100%;
	animation-name: fadeIn;
	animation-duration: 0.8s;

	&__slider {
		width: 95%;
		margin: 0 auto 16px auto;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		column-gap: 23px;
		padding-right: 5px;
	}

	&__cancel {
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.06em;
		text-align: left;
		padding: 4px ;
		width: 70px;
	}

	&__save {
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.06em;
		text-align: left;
		padding: 4px 16px;
		width: 100px;
		height: 32px;
	}
}

