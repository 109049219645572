@import "src/styles/colors.scss";
@import "src/styles/mixins";

.button {
	$height-small: 30px;
	$height-common: 40px;

	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	box-sizing: border-box;
	padding: 0 20px;
	white-space: nowrap;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.06em;
	width: fit-content;

	&__size_default {
		height: 36px;
	}

	&__size_small {
		height: $height-small;

	}

	&__size_common {
		height: $height-common;
	}

	&__form_rounded {
		border-radius: 110px;
	}

	&__type_primary {
		color: $text-primary;
		background-color: $primary;
	}

	&__type_secondary {
		color: $text-secondary;
		background-color: $secondary;
	}

	&__type_outlined {
		background-color: transparent;
		border: 1px solid $secondary;
		padding: 6px 20px;
		color: $secondary;
	}

	&__type_info {
		color: $text-secondary;
		background-color: $secondary;
		border: $text-secondary 1px solid;
	}

	&__type_text {
		padding: 0 4px ;
	}

	&__type_icon {
		padding: 0;
	}

	&__type_link {
		color: $text-secondary;
		text-decoration: underline;
	}

	&__label {
		@include ellipsis();
		color: $text-primary;
	}
}

.button_icon {
	margin-right: 8px;
	display: flex;

	&__right {
		margin-right: 0;
		margin-left: 10px;
	}

	&__small {
		height: 50%;
	}

	&__icon_only {
		margin-right: 0;
	}

	&__primary {
		path {
			fill: $text-primary;
		}
	}
}

.button_disabled {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
	color: $text-disabled;
}

.hoverable {
	@include hoverable;
}

.label_text_input {
	color: $text-input-primary;
}

.label_text_tile {
	color: $text-tiles;
}

.label_text_primary {
	color: $text-primary;
}

.label_primary {
	color: $primary;
}

.label_secondary {
	color: $secondary;
}

.label_text_secondary {
	color: $text-secondary;
}

.label_text_outline_primary {
	color: $primary;
}

.label_text_outline_secondary {
	color: $secondary;
}

.label_text_layout_header {
	color: $text-layout-header;
}
