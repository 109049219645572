@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.cms_check_btn {
	cursor: pointer;

	&__icon__check_on, &__icon__check_part {
		path {
			fill: $primary;
		}
	}

	&__icon__check_off {
		path {
			fill: $secondary;
		}
	}
}
