@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";

.navigation {
	display: flex;
	width: 100%;
	padding: 0 60px;
	justify-content: space-between;
	align-items: center;
	background-color: $background-navbar;
	color: $text-tiles;
	position: fixed;
	z-index: 1000;
	left: 0;
	right: 0;
	height: $navbar-height;

	@include media-extra-small-down() {
		padding: 6px 16px;
		width: 100vw;
	}

	@include media-extra-small-only2() {
		padding: 12px 32px;
		width: 100vw;
	}

	&__logo {
		min-height: 30px;
		width: 17%;
		margin-right: 10px;

		@include media-extra-small-down() {
			width: 40%;
		}
	}

	&__box_center {
		text-transform: uppercase;
		display: flex;
		width: 100%;
    	justify-content: space-around;

		@include media-extra-small-only() {
			width: 100%;
			min-height: 60px;
			background-color: $background-navbar;
			justify-content: space-around;
			align-items: baseline;
			position: fixed;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			padding-top: 10px;
		}

		@include media-extra-small-down() {
			width: 100vw;
			left: 0;
			transform: unset;
		}

		@include media-extra-small-only2() {
			width: 100vw;
			left: 0;
			transform: unset;
		}
	}

	&__text {
		font-family: $font-family-base;
		font-size: 18px;
		font-weight: 500;
		color: $text-tiles;

		@include media-extra-small-only() {
			font-size: 10px;
		}

		@include media-compact-only() {
			font-size: 12px;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		gap: 10px;
		text-decoration: none;
		position: relative;

		@include media-extra-small-only() {
			flex-direction: column;
			gap: 4px;
			flex-basis: 20%;
		}
	}

	&__button_hide {
		display: none;
	}

	&__box_right {
		display: flex;
		gap: 20px;
		align-items: center;
		margin-left: 5px;
		max-width: 20vw;
		justify-content: space-around;
		width: 17%;
	}

	&__active {
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: calc(200% - 3px);
			display: inline-block;
			z-index: 0;
			border-bottom: 3px solid $common-white;

			@include media-extra-small-only() {
				display: none;
			}
		}
	}

	&__text_desk {
		color: $navbar-desk;
	}

	&__icon_desk {
		path {
			stroke: $navbar-desk;
		}
	}

	&__active_desk {
		&:after {
			border-bottom: 3px solid $navbar-desk;
		}
	}

	&__text_influencer {
		color: $navbar-influencers;
	}

	&__icon_influencers {
		path {
			fill: $navbar-influencers;
		}
	}

	&__active_influencer {
		&:after {
			border-bottom: 3px solid $navbar-influencers;
		}
	}

	&__text_cms {
		color: $navbar-cms;
	}

	&__icon_cms {
		path {
			stroke: $navbar-cms;
		}
	}

	&__active_cms {
		&:after {
			border-bottom: 3px solid $navbar-cms;
		}
	}

	&__text_reports {
		color: $navbar-reports;
	}

	&__icon_reports {
		path {
			fill: $navbar-reports;
		}
	}

	&__active_reports {
		&:after {
			border-bottom: 3px solid $navbar-reports;
		}
	}

	&__active_peers {
		&:after {
			border-bottom: 3px solid $navbar-peers;
		}
	}

	&__icon_peers {
		path {
			fill: $navbar-peers;
		}
	}

	&__text_peers {
		color: $navbar-peers;
	}

	&__text_home {
		color: $navbar-home;
	}

	&__icon_home {
		path {
			fill: $navbar-home;
		}
	}

	&__active_home {
		&:after {
			border-bottom: 3px solid $navbar-home;
		}
	}

	&__text_myData {
		color: $navbar-myData;
	}

	&__icon_myData {
		path {
			fill: $navbar-myData;
		}
	}

	&__active_myData {
		&:after {
			border-bottom: 3px solid $navbar-myData;
		}
	}

	&__search_icon {
		cursor: pointer;

		path {
			stroke: $text-layout-header;
		}

		filter: invert(0.6) brightness(120%);
	}

	&__search_icon {
		cursor: pointer;
	}
}
