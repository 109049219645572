@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/tile.scss";

.analytics_page {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	color: $text-tiles;
	padding: 4vh 10vw;
	gap: 20px;

	@include media-extra-small-down() {
		padding: 0 16px;
		flex-direction: column;
		gap: 10px;
	}

	@include media-extra-small-only2() {
		padding: 0 24px;
		flex-direction: column;
		width: 100vw;
		gap: 16px;;
	}

	&__counter_block {
		display: flex;
		align-items: center;

		@include media-extra-small-only2() {
			flex-basis: 25%;
		}
	}

	&__counter_box {
		display: flex;
		align-items: center;
	}

	&__count {
		display: flex;
		flex-direction: column;
		font-size: 24px;
		color: $text-tiles;

		@include media-small-down() {
			flex-direction: column;
			justify-content: center;
			width: 100%;
			gap: 8px;
		}

		@include media-extra-small-down() {
			flex-direction: column;
			justify-content: center;
			align-items: baseline;
			width: 100%;
			gap: 8px;
		}
	}

	&__count_title {
		font-size: 10px;
		text-transform: uppercase;
		margin: 0;
		color: $text-tiles;

		@include media-extra-small-down() {
			font-size: 14px;
		}

		@include media-extra-small-only2() {
			font-size: 14px;
		}
	}

	&__charts_container {
		width: 100%;
		flex-direction: column;
	}

	&__heads_container {
		display: flex;

		@include media-extra-small-down() {
			flex-direction: column;
		}
	}

	&__head_box {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		max-width: 49%;

		@include media-big-only() {
			max-width: 49%;
		}

		@include media-small-down() {
			max-width: 60%;
			width: 60vw;
			height: auto;
			margin: 0;
		}

		@include media-extra-small-down() {
			max-width: 100%;
			width: 100%;
			margin: 0;
			height: auto;
		}

	}





	&__info_tooltip {
		width: max-content;
		bottom: 100%;
		transform: translate(40px, 30px);

		@include media-extra-small-down() {
			width: 60vw;
			transform: translate(18%, 60%);
			z-index: 102;
		}

		@include media-extra-small-only2() {
			width: 50vw;
			transform: translate(44px, 55%);
			z-index: 102;
		}
	}

	&__info_tooltip__text_position_left {
		@include media-extra-small-down() {
			width: 65vw;
			transform: translate(-100%, 65%);
			z-index: 102;
		}

		@include media-extra-small-only2() {
			width: 50vw;
			transform: translate(-100%, 65%);
			z-index: 102;
		}
	}

	&__info_tooltip__text_position_top {
		@include media-extra-small-down() {
			width: 65vw;
			transform: translate(-40%, -5%);
			z-index: 102;
		}
	}

	&__info_icon {
		path {
			fill: $other-outline-gray;
		}

		margin: 0 10px;
	}

	&__title_group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 500;
		font-size: 28px;
		color: $text-tiles;
		gap: 10px;

		@include media-extra-small-down() {
			font-size: 22px;
			width: 100%;
		}

		@include media-extra-small-only2() {
			font-size: 28px;
			width: 100%;
		}
	}

	&__z1_dropdown {
		background: transparent;

		@include media-extra-small-down() {
			flex-basis: 70%;
			z-index: 101;
		}

		@include media-extra-small-only2() {
			flex-basis: 70%;
			z-index: 101;
		}

	}

	&__percentage {
		display: flex;
		width: 100%;
		font-size: 30px;
		font-weight: 500;

		@include media-extra-small-down() {
			font-size: 30px;
		}

		@include media-extra-small-only2() {
			font-size: 30px;
		}
	}

	&__percentage_sign {
		@include media-extra-small-down() {
			font-size: 20px;
			display: inline-block;
			margin-top: 8px;
		}
	}

	&__percentage_box {
		width: 22vw;
		position: relative;
		top: -20px;

		@include media-extra-small-down() {
			width: 100%;
			top: 0;
		}

		@include media-extra-small-only2() {
			width: 50%;
		}
	}

	&__chart_top_box {
		width: 100%;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 28px;
		color: $text-tiles;
		margin-bottom: 8px;

		@include media-extra-small-down() {
			font-size: 26px;
		}

		@include media-extra-small-only2() {
			font-size: 24px;
		}
	}

	&__percentage_head {
		height: 80%;
		position: relative;
		bottom: -10%;

		path {
			fill: $background-tile;
		}
	}

	&__percentage_icon {
		position: absolute;
		z-index: 2;
		top: 30%;
		max-width: 120px;
		width: 100%;

		@include media-extra-small-only2() {
			width: 50%;
			height: 50%;
		}
	}

	&__timing_container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 10px;

		@include media-extra-small-down() {
			height: auto;
			max-width: 100%;
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			flex-direction: row;
			height: auto;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 24px;
			max-width: 90vw;
			width: 100%;
		}

	}

	& #{&}__time_tile {
		justify-content: flex-start;
		align-items: center;
		width: 100%;

		@include media-extra-small-down() {
			width: 100%;
			height: 100px;
			max-width: 100;
		}

		@include media-extra-small-only2() {
			height: 120px;
			margin: 0;
			flex-basis: 48%;
		}
	}

	&__interactions_container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__interactions_info_box {
		display: flex;
		justify-content: space-between;
		margin-top: 12px;

		@include media-small-down() {
			flex-wrap: wrap;
			margin-top: 12px;
		}

		@include media-extra-small-down() {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 10px;
		}
	}

	&__interaction_info {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		@include media-extra-small-down() {
			margin-bottom: 16px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 16px;
			flex-basis: 48%;
		}
	}

	&__interaction_avatar {
		margin-right: 10px;
		cursor: pointer;

		@include media-extra-small-down() {
			margin-right: 24px;
			width: 68px;
			height: 68px;
			min-width: 68px;
		}

		@include media-extra-small-only2() {
			margin-right: 24px;
			width: 68px;
			height: 68px;
			min-width: 68px;
		}
	}

	&__icon {
		width: 35px;
		height: 35px;
		margin-right: 24px;

		path {
			fill: $primary;
		}

		rect {
			stroke: none;
		}

		@include media-extra-small-down() {
			width: 52px;
			height: 52px;
			min-width: 52px;
		}

		@include media-extra-small-only2() {
			width: 52px;
			height: 52px;
			min-width: 52px;
		}
	}

	&__close_icon {
		cursor: pointer;
		width: 16px;
		height: 16px;

		path {
			stroke: $common-white
		}
	}

	&__type_icon {
		width: 68px;
		height: 68px;
		margin-right: 24px;

		@include media-extra-small-down() {
			width: 64px;
			height: 64px;
			min-width: 64px;
		}

		@include media-extra-small-only2() {
			width: 64px;
			height: 64px;
			min-width: 64px;
		}
	}

	&__fill_icon {
		path {
			stroke: $primary;
			fill: transparent;
		}
	}

	&__spinner_box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 12px;
		width: 24px;
	}

	&__loading_spinner {
		animation: spinner 0.75s linear infinite;
		width: 24px;
		height: 24px;

		path {
			fill: $other-medium-gray;
		}
	}

	@keyframes spinner {
		to {
			transform: rotate(1turn);
		}
	}

	&__centered {
		justify-content: space-evenly;
	}

	& #{&}__common_counters {
		display: flex;
		justify-content: space-around;
		width: 100%;

		@include media-extra-small-down() {
			width: 100%;
			align-items: flex-start;
			padding-left: 24px;
			gap: 24px;
			height: auto;
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			gap: 24px;
			height: auto;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			max-width: 90vw;
			width: 100%;
		}
	}

	&__count_digits {
		@include media-extra-small-down() {
			font-weight: 500;
			font-size: 24px;
		}

		@include media-extra-small-only2() {
			font-weight: 500;
			font-size: 24px;
		}
	}


	&__filters_container {
		display: flex;
		gap: 10px;
	}
}

.tile {
	display: flex;
	background-color: $background-tile;
	border-radius: 16px;
	padding: 14px;

	@include media-extra-small-down() {
		margin-bottom: 16px;
	}

	@include media-extra-small-only2() {
		margin: 0 0 16px 0;
		max-width: 90vw;
		width: 100%;
	}
}

.motif_meter {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 10px;

	&__insight_section {
		margin-top: 20px;
		padding: 15px;
		border: 1px solid;
		border-radius: 6px;
	}

	&__insight_text {
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 16px;
	}
}

.motif_item {
	display: flex;
	align-items: center;
	margin-bottom: 14px;

	&__name {
		width: 100%;
		max-width: 70px;
		overflow-wrap: break-word;
	}

	&__value {
		min-width: 25px;
   		max-width: 35px;
    	width: 100%;
		text-align: end;
	}


	&__progress_bars_container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__progress_bar_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.learning_path {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 600px;
	gap: 1em;

	&__transition_label {
		max-width: 275px;
    	text-wrap: nowrap;
    	text-overflow: ellipsis;
    	overflow: hidden;
	}

	&__tooltip {
		transform: translate(10px, -90px);
		max-width: 310px;
		width: max-content;

		&__content {
			font-size: clamp(1rem, 2.0vw, 2rem);
		}
	}

}

.phantom_tile {
	@include common-tile();
	display: flex;
    justify-content: center;
    align-items: center;
	background: transparent;
	border: 4px dashed $text-tiles;
	opacity: 0.3;
	font-size: 3.5rem;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
		font-size: 4rem;
	}

}
