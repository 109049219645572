@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/player";

.certificate_screen {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: $background-white;
	width: 100%;
	max-height: 640px;
    height: 600px;
	padding: 20px 40px;
	overflow: hidden;

	@include media-extra-small-down() {
		flex-direction: column;
		padding: 20px 20px;
		justify-content: center;
	}

	&__container {
		display: flex;
		height: 100%;
	}

	//* Temp styles. Remove when certificates generation fix was ready.

	&__temp_container {
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&__temp_text {
		color: $text-general;
		font-size: 16px;
		font-weight: 400;
	}
	//* -------------------------

	&__final_message {
		font-size: 24px;
		p {
			margin: 0;
		}
	}

	&__left_container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-height: 200px;
		margin: 40px;

		@include media-extra-small-down() {
			margin: 10px;
		}
	}

	&__message {
		color: $text-general;
		width: fit-content;
		margin: 10px 0;
	}

	&__link {
		color: $primary;
		font-size: 20px;
		font-weight: 400;
		width: fit-content;
		margin: 10px;
	}

	&__certificate_preview {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-extra-small-down() {
			height: 60%;
		}
	}

	&__certificate_image {
		width: 100%;
		max-width: 675px;
		filter: drop-shadow(2px 4px 12px black);
	}

	&__btn {
		background-color: transparent;
		cursor: pointer;
		padding: 0;
		border: none;
		z-index: 1;
	}

	&__btn_back_wrap {
		@include playerArrows();
		position: absolute;
		left: 0;
		display: flex;
		transition: all 0.5s;
		cursor: pointer;
		z-index: 100;

	}

	&__btn_icon {
		width: $player-arrows-dimension;
		height: $player-arrows-dimension;
	}

}

.arrow_tooltip {
	width: max-content;
	max-width: 320px;
	transform: translate(50px, -80px);

	&__content {
		min-width: fit-content;
	}
}
.loading_container {
	z-index: 0;
}
