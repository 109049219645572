@import "src/styles/cms";

.cms_content {
	@include status;
}

.retry_btn {
	display: flex;
	cursor: pointer;
	background-color: $error-light;
	color: $error-dark;
	border: 1px solid $error-dark;
	width: fit-content;
	padding: 6px 8px;
	border-radius: 13px;
	text-transform: uppercase;
	font-size: 14px;
}

.retry_label {
	display: flex;
}

.retry_icon {
	margin-left: 4px;
}

