@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/tile.scss";
@import "src/styles/mixins";

.course_card {
	@include common-tile();
	width: 100%;
	max-width: 1220px;
	padding: 0 40px 32px;

	@include media-small-down() {
		padding: 10px;
		border-radius: 0;
		width: 100vw;
	}

	@include media-extra-small-down() {
		padding: 10px;
		border-radius: 0;
		width: 100vw;
	}

	@include media-extra-small-only2() {
		padding: 32px;
		border-radius: 0;
		width: 100vw;
	}

	&__header {
		padding: 0;
		margin: 24px 0;
	}

	&__main_info_block {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 24px;

		@include media-extra-small-down() {
			margin-top: 18px;
		}

		@include media-extra-small-only2() {
			margin-top: 18px;
		}
	}

	&__left_section {
		margin-right: 0;
		width: 100%;
	}

	&__duration_block {
		display: flex;
		align-items: center;
	}

	&__type_icon {
		circle {
			fill: none;
		}

		path {
			fill: none;
			stroke: $common-white;
		}

		&__dark {
			path {
				fill: none;
				stroke: $other-background-dark;
			}
		}
	}

	&__icon_scorm {
		circle {
			fill: rgb(215, 66, 33);
		}

		path {
			fill: white;
			stroke: none;
		}
	}

	&__content {
		margin: 18px 0;

		&__thumbnail {
			width: 100%;
			height: 216px;
			object-fit: fill;
		}
	}

	&__description_container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		column-gap: 24px;

		@include media-extra-small-down() {
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
		}
	}

	&__description_box {
		flex: 1;
		min-width: 0;

		@include media-extra-small-down() {
			width: 100%
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__course_name {
		font-weight: 500;
		font-size: 18px;
		letter-spacing: 0.75px;
		margin: 12px 0;
		word-wrap: break-word;
	}

	&__ai_summary_button {
		margin: 0 0 16px 0;
		background-color: $primary;
		color: $text-primary;
		padding: 4px 8px;
		border-radius: 37px;
		&:hover {
			opacity: 0.7;
			background-color: $primary;
		}
	}

	&__content_name {
		font-weight: 500;
		font-size: 36px;
		line-height: 48px;
		margin-bottom: 16px;

		@include media-extra-small-down() {
			@include no_ellipsis();
			line-height: normal;
			font-size: 30px;
		}

		@include media-extra-small-only2() {
			@include no_ellipsis();
			line-height: normal;
			font-size: 30px;
		}
	}

	&__description {
		font-size: 16px;
		line-height: 26px;
		word-break: break-word;
	}

	&__description_picture {
		display: none;
		width: 300px;
		height: 200px;
		border-radius: 8px;
		overflow: hidden;
		cursor: none;

		@include media-extra-small-down() {
			width: 100%;
			margin-bottom: 16px;
		}

		@include media-extra-small-only2() {
			width: 100%;
			margin-bottom: 16px;
			height: auto;
		}

		&__landing {
			display: block;
			cursor: auto;
		}
	}

	&__description_img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__color_bar {
		height: 6px;
		border-radius: 63px;
		display: flex;
    justify-content: flex-end;
		overflow: hidden;

		&__incompleted {
			background-color: $common-white;
			height: 6px;
			opacity: 0.38;
		}
	}

	&__stats_section {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-extra-small-down() {
			flex-direction: column;
			gap: 16px;
			align-items: flex-start;
		}

		@include media-extra-small-only2() {
			flex-direction: row;
			gap: 16px;
			align-items: center;
		}
	}

	&__likes {
		display: flex;
		align-items: center;
	}

	&__like_icon {
		cursor: pointer;

		&:hover {
			scale: $icons-hover-scale;
		}

		path {
			fill: $primary;
			stroke: $primary;
		}

		&__disliked {
			path {
				fill: transparent;
				stroke: $text-tiles;
			}
		}
	}

	&__zStats {
		display: flex;
		color: $text-primary;

		:first-child {
			margin-right: 8px;
		}
	}

	&__zStat {
		width: 74px;
		height: 24px;
		background: $primary;
		border-radius: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $text-primary;
	}

	&__content_info_section {
		margin-top: 16px;
		display: flex;
		flex-wrap: wrap;
	}

	&__content_info {
		margin: 0 8px 4px 0;
		padding: 0 12px;
		border-radius: 37px;
		font-size: 14px;
		line-height: 24px;
		color: $text-secondary;
		background-color: $secondary;
	}

	&__price_and_rating {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;

		@include media-extra-small-down() {
			flex-direction: row;
			justify-content: space-between;
			gap: 8px;
		}

		@include media-extra-small-only2() {
			flex-direction: row;
			justify-content: space-between;
			gap: 8px;
			padding-top: 10px;
		}
	}

	&__digits_info_container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 20px;
	}

	&__rating_block {
		@include media-extra-small-down() {
			width: 50%;
		}

		@include media-extra-small-only2() {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			gap: 16px;
			flex-direction: column;
		}
	}

	&__price_block {
		display: flex;
		align-items: center;

		@include media-extra-small-only2() {
			flex-basis: 120px;
		}
	}

	&__price {
		font-weight: 500;
		font-size: 64px;
		line-height: 48px;
		margin-bottom: 16px;
		display: block;

		@include media-extra-small-down() {
			font-size: 36px;
			line-height: normal;
		}

		@include media-extra-small-only2() {
			font-size: 48px;
			line-height: normal;
		}
	}

	&__start_button {
		width: 100%;
		max-width: 180px;
		color: $text-primary;
		background-color: $primary;
	}

	&__rating_digits_section {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include media-extra-small-down() {
			padding-bottom: 16px;
			gap: 8px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 0;
			gap: 8px;
		}
	}

	&__rating_label {
		font-size: 14px;
		line-height: normal;
		margin: 0 0 14px 0;

		@include media-extra-small-down() {
			margin: 0 0 8px 0;
		}

		@include media-extra-small-only2() {
			margin: 0 0 8px 0;
		}
	}

	&__stars {
		white-space: nowrap;

		@include media-small-down() {
			width: max-content;
		}
	}

	&__rating {
		font-size: 64px;
		line-height: 48px;
		margin: 16px 16px 16px 0;
		font-weight: 500;

		@include media-extra-small-down() {
			margin: 0;
			font-size: 36px;
		}

		@include media-extra-small-only2() {
			margin: 0;
			font-size: 48px;
		}
	}

	&__views {
		font-size: 24px;
		color: $text-tiles;
		display: block;
		margin: 0;
		font-weight: 300;

		@include media-extra-small-down() {
			margin: 0;
		}
	}

	&__btn_box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 32px;

		@include media-extra-small-down() {
			flex-wrap: wrap;
			gap: 8px;
		}

		@include media-extra-small-only2() {
			flex-wrap: wrap;
			gap: 8px;
		}
	}

	&__progress_box {
		display: flex;
		align-items: center;
	}

	&__z1_alert_tooltip {
		@include media-extra-small-down() {
			transform: translate(5px, -55px);
		}
	}

	&__outdated_progress_tooltip {
		width: 300px;
		transform: translate(15px, -75px);

		@include media-extra-small-down() {
			transform: translate(-35px, -75px);
		}
	}

	&__btn {
		padding: 0;
		margin-right: 12px;

		& span {
			font-weight: 500;
			font-size: 14px;
			line-height: normal;
		}
	}

	&__btn_assignements {
		path {
			fill: white;
		}
	}

	&__wrap {
		justify-content: space-between;
		width: 100%;
		display: grid;
		grid-template-columns: minmax(400px, 1fr) 180px;
		column-gap: 40px;
		padding-bottom: 15px;
		margin-bottom: 10px;
		border-bottom: 1px solid $text-tiles;

		@include media-extra-small-down() {
			grid-template-columns: minmax(300px, 1fr);
		}

		@include media-extra-small-only2() {
			grid-template-columns: minmax(300px, 1fr);
			padding-bottom: 0;
		}
	}

	&__inner_block {
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 32px;
		width: 100%;

		&_two {
			padding-bottom: 0;
			gap: 24px;
		}

		@include media-extra-small-down() {
			gap: 16px;
		}

		@include media-extra-small-only2() {
			gap: 16px;
		}
	}

	&__inner_icon {
		width: 26px;
		height: 26px;
	}

	&__icon_video {
		path {
			stroke: $text-tiles;
			fill: $background-tile;
		}
	}

	&__icon_book {
		path {
			fill: $text-tiles;
		}
	}

	&__edit_icon {
		cursor: pointer;

		path {
			fill: $text-tiles;
		}

	}

	&__inner_group {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
	}

	&__header_text {
		font-weight: 400;
		font-size: 14px;
		text-transform: uppercase;
	}

	&__text_info {
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.06em;
	}

	&__icon_share {
		cursor: pointer;

		path {
			fill: $text-tiles;
		}

	}

	&__star_rating_block {
		display: block;
	}

	&__icon {
		path {
			fill: $text-tiles;
		}
	}

	&__box_hidden{
		overflow: hidden;
	}
}

.divider {
	width: 100%;
	border: 1px solid $text-tiles;
	margin: 20px 0;
	background-color: $text-tiles;

	@include media-extra-small-down() {
		margin: 16px 0;
	}

	@include media-extra-small-only2() {
		margin: 16px 0;
	}
}

.hidden {
	display: none;
}
