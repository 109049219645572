@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";

.widget {
	border: 1px solid $background-layout;
	padding: 24px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 16px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	&__title {
		@include ellipsis();
		font-weight: 500;
		font-size: 24px;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
	}

	&__buttons__hide {
		display: none;
	}

	&__icon_close {
		width: 24px;
		height: 24px;
		cursor: pointer;

		path {
			stroke: $text-general;
		}
	}

	&__icon_collapse {
		width: 18px;
		height: 18px;
		cursor: pointer;

		path {
			fill: $text-general;
		}
	}

	&__icon_expand {
		width: 18px;
		height: 18px;
		cursor: pointer;

		path {
			fill: $text-general;
		}
	}

	&__body {
		@include ellipsis();
		height: calc(100% - 48px);
	}

	&__frame {
		margin: auto;
		overflow-y: auto;
		display: block;
		border: none;
		width: 100%;
		height: 100%;
	}
}
