@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.profile_notes {
	@include cell_name;
	@include cell_avatar;
	@include cell_text;

	padding: 4vh 3vw;

	&__carousel {
		overflow: hidden;
		position: relative;
		padding-bottom: 36px;

		@include media-extra-small-down() {
			width: 95vw;
		}

		@include media-extra-small-only2() {
			width: 95vw;
		}
	}

	&__loading {
		height: 500px;
		width: 100%;
	}

	&__lesson_tile {
		margin-right: 32px;
		@include media-extra-small-down() {
			margin-right: 0;
		}
	}

	&__cell_picture {
		margin-right: 12px;
	}

	&__body {
		padding: 28px 0;

		@include media-extra-small-down() {
			padding: 24px 0;
		}

		@include media-extra-small-only2() {
		}
	}

	&__table {
		td {
			&:nth-child(1) {
				padding-left: 12px;
				width: 10%;
				@include media-extra-small-only2() {
					width: 10vw;
				}
			}

			&:nth-child(2) {
				padding-left: 12px;
				max-width: 30vw;
				width: 30vw;

				@include media-extra-small-only2() {
					text-overflow: ellipsis;
				}
			}

			&:nth-child(3) {
				text-align: center;
				width: 10%;
				@include media-extra-small-only2() {
					display: none;
				}
			}

			&:nth-child(4) {
				max-height: fit-content;
				white-space: break-spaces;
				padding-right: 4px;
				padding-left: 8px;
				font-size: 14px;

				@include media-extra-small-only2() {
					max-height: fit-content;
				}

			}


			@include media-extra-small-only2() {
				&:nth-child(1) {
					padding-left: 24px;
				}

				&:nth-child(2) {
					padding-right: 24px;
				}

				&:nth-child(3) {
					padding-right: 24px;
				}
			}
		}

		th {
			&:nth-child(3) {
				@include media-extra-small-only2() {
					display: none;
				}
			}
		}
	}
}

.filter_menu {
	width: fit-content;
}

.controll_cell {
	display: flex;
	justify-content: space-around;
	align-items: center;
	max-width: 10vw;
}

.play_icon {
	path {
		stroke: $common-black;
	}
}



.content_type_tooltip {
	transform: translate(30%, -150%);
	text-align: center;
}

