@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";

.invitation {
	background: $background-layout;
	flex: 1;
	border-radius: 20px;
	max-width: 578px;
	padding: 48px 68px;

	@include media-extra-small-down() {
		padding: 9px 16px;
		border-radius: 0;
		min-height: 100vh;
	}

	&__logo_box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 20px;
		flex-direction: column;
	}

	&__title {
		font-family: $font-family-base;
		font-weight: bold;
		font-size: 24px;
		line-height: 36px;
		color: $text-general;
		padding-bottom: 10px;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		cursor: default;
	}

	&__text_inner {
		padding-right: 5px;
	}

	&__link {
		font-family: $font-family-base;
		font-size: 14px;
		line-height: 24px;
		background: none;
		border: none;
		padding: 0;
		color: $text-general;
		text-decoration: underline;
		cursor: pointer;
	}

	&__fields_wrap {
		padding-bottom: 32px;
	}

	&__field_inner {
		padding-bottom: 12px;
	}

	&__password_text {
		color: $text-general;
		margin-bottom: 12px;
		padding: 0 6px;
	}

	&__field_inner_two {
		padding-bottom: 0;
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	& #{&}__btn_sign_in {
		background: $success-dark;
		padding: 12px;
		border-radius: 8px;
		text-transform: none;
		margin-bottom: 24px;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.06em;
		line-height: 24px;
		width: 100%;
		border: 0;
		cursor: pointer;

		span {
			color: $common-white;
		}
	}


	&__spiral {
		display: block;
		user-select: none;
		cursor: default;
		width: 224px;
		height: 103px;
		margin: 0 0 33px 0;
		mix-blend-mode: lighten;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

