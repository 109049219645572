@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/animation";

.comment_add {
	padding: 32px;
	border:$background-tile 1px solid;
	border-radius: 16px;

	&__compact {
		padding: 24px 16px;
	}

	&__simple {
		border: none;
		border-radius: 0;
		padding: 0;
		@include fadeIn(1s);
		margin-left: 32px;

		@include media-extra-small-down() {
			padding: 24px 16px;
			margin-left: 16px;
			width: calc(100% - 16px);
		}

		@include media-extra-small-only2() {
			padding: 24px 16px;
			margin-left: 16px;
			width: calc(100% - 16px);
		}
	}

	@include media-extra-small-down() {
		padding: 24px 16px;
		margin-left: 0;
	}

	@include media-extra-small-only2() {
		padding: 24px 16px;
		margin-left: 0;
	}

	&__edit {
		border: none;
		border-radius: 0;
		@include fadeIn(1s);
		padding: 0;
	}

	&__plain {
		padding: 24px 16px;
	}

	&__plain_secondary {
		padding: 24px 16px;
		margin-left: 16px;
	}


	&__head {
		margin: 0;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		text-align: left;
		padding-bottom: 24px;
		color: $secondary;

		&__compact {
			padding-bottom: 16px;
		}

		&__simple {
			display: none;
		}

		@include media-extra-small-down() {
			padding-bottom: 16px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 16px;
		}
	}

	&__field_title {
		margin-bottom: 24px;

		& textarea {
			transition: height .5s ease-in-out;
			height: 52px;
		}

		&__compact {
			margin-bottom: 16px;
		}

		&__edit {
			margin-bottom: 8px;
		}

		@include media-extra-small-down() {
			margin-bottom: 16px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 16px;
		}
	}

	&__field_focus {
		& textarea {
			height: 148px;
		}
	}

	&__footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__compact {
			flex-direction: column;
		}

		@include media-extra-small-down() {
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
		}
	}

	&__upload_group {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 16px;
		margin-top: 10px;

		&__compact {
			flex-direction: column;
			column-gap: 0;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 16px;
		}

		@include media-extra-small-down() {
			flex-direction: column;
			column-gap: 0;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 16px;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			column-gap: 0;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 16px;
		}
	}

	&__images_group {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 16px;

		&__compact {
			gap: 4px;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}

		@include media-extra-small-down() {
			gap: 4px;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}

		@include media-extra-small-only2() {
			gap: 4px;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__uploaded_image {
		border-radius: 14px;
		background: transparent;
		opacity: 0.9;
		position: relative;
		:hover {
			filter: brightness(40%);
		}

	}

	&__uploaded_image img {
		border-radius: 14px;;
		background: transparent;
		opacity: 0.5;


	}

	&__upload_button {
		background-color: $secondary;
		padding: 4px 16px;

		&__compact {
			margin-bottom: 16px;
			max-width: 128px;
		}

		@include media-extra-small-down() {
			margin-bottom: 16px;
			max-width: 128px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 16px;
			max-width: 128px;
		}
	}

	&__upload_button span {
		font-family: $font-family-base;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.06em;
		text-align: left;
		line-height: 24px;
		color: $text-secondary;
		overflow: unset;
		white-space: unset;
	}

	&__uploaded_block {
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__uploaded_icon {
		path {
			stroke: $common-white;
		}
	}

	&__btn_next {
		&__compact {
			width: 100%;
		}

		&__plain {
			max-width: 50%;
		}

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}

	&__info {
		color: $common-gray;

		&__edit {
			color: white;
		}

		&__plain_edit {
			color: $common-gray;
		}
	}

	&__btn_group {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		column-gap: 16px;
		width: 100%;

		@include media-extra-small-down() {
			width: 100%;
		}

		@include media-extra-small-only2() {
			width: 100%;
		}
	}


	&__icon_spinner {
		@include spinner();
	}
}
