@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.cms_users {
	@include status;
	@include cell_name;

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		@include media-extra-small-down() {
			padding: 0 16px;
			justify-content: space-between;
		}

		@include media-extra-small-only2() {
			padding: 0 32px;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;

		@include media-extra-small-down() {
			display: none;
		}

		@include media-extra-small-only2() {
		}
	}

	&__btn_add {
		text-decoration: none;
		@include primary_button();
		@include media-extra-small-down() {
			display: none;
		}
	}

	&__btn_add_mobile {
		display: none;

		@include media-extra-small-down() {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			@include primary_button();
		}

	}

	&__bulk {
		margin-right: 10px;
	}

	&__checkbox {
		display: flex;
		align-content: center;
		justify-content: center;
	}

	&__filters_group {
		display: flex;
		gap: 10px;
	}

	&__search {
		@include search_field();
	}

	&__body {
		padding: 24px 0;
	}

	&__table {
		td {
			&:nth-child(1) {
				padding-left: 24px;
				width: 20%;

				@include media-compact-only() {
					padding-left: 5px;
				}
			}

			&:nth-child(2) {
				max-width: 300px;
				width: 25%;
				@include ellipsis();

				@include media-compact-only() {
					max-width: 150px;
				}
			}

			&:nth-child(3) {
				max-width: 200px;
				width: 25%;

				& div {
					@include ellipsis();
				}

				@include media-compact-only() {
					max-width: 150px;
				}
			}

			&:nth-child(4) {
				padding-right: 24px;
				width: 10%;

				& div {
					@include ellipsis();
				}

				@include media-compact-only() {
					max-width: 100px;
					padding-right: 5px;
				}
			}

			&:nth-child(5) {
				padding-right: 24px;
				width: 15%;

				& div {
					@include ellipsis();
				}

				@include media-compact-only() {
					max-width: 100px;
					padding-right: 5px;
				}
			}

			&:nth-child(6) {
				padding-right: 24px;
				width: 5%;

				@include media-compact-only() {
					padding-right: 5px;
				}
			}


			@include media-extra-small-only2() {
				&:nth-child(1) {
					padding-left: 32px;
				}

				&:nth-child(2) {
					max-width: 200px;
				}

				&:nth-child(3) {
					max-width: 150px;
				}

				&:nth-child(4) {
					padding-right: 32px;
				}
			}
		}
	}

	&__alert {
		top: 100px;
	}

	&__cell_avatar {
		margin-right: 24px;

		& > img {
			background: none;
		}

		@include media-extra-small-down() {
			margin-right: 0;
		}

		@include media-extra-small-only2() {
			margin-right: 5px;
		}

		@include media-compact-only() {
			margin-right: 5px;
		}
	}

	&__cell_text {
		@include ellipsis();
		max-width: 150px;

		@include media-extra-small-down() {
			width: 100%;
			max-width: 170px;
		}

		@include media-extra-small-only2() {
			max-width: 125px;
		}

		@include media-compact-only() {
			max-width: 100px;
		}
	}
}

.delete_icon {
	padding: 5px;
	font-size: 20px;
	cursor: pointer;
}
