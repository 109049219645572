@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/player";

.peers_topic {
	@include nodata;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__header {
		padding: 0;
		position: relative;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 16px;

		@include media-extra-small-down() {
			padding: 0;
			margin-bottom: 27px;
		}

		@include media-extra-small-only2() {
			padding: 0;
			margin-bottom: 27px;
		}
	}

	&__header_content {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__header_box {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 10px 60px;
		background-color: $background-layout-header;

		@include media-extra-small-down() {
			padding: 10px 16px;
		}

		@include media-extra-small-only2() {
			padding: 10px 16px;
		}
	}

	&__btn_back_out {
		max-width: 32px;
		padding: 0;
		display: block;
	}

	&__page {
		flex: 1 1;
		width: 100%;
		padding: 56px 8px 8px 8px;
		max-width: 1169px;
		margin: 0 auto;
		color: black;

		@include media-extra-small-down() {
			padding: 0 16px;
		}

		@include media-extra-small-only2() {
			padding: 16px 32px 40px;
		}
	}

	&__comment_add {
		background-color: transparent;
		margin-bottom: 52px;

		@include media-extra-small-down() {
			margin-bottom: 30px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 30px;
		}
	}

	&__icon {
		path {
			stroke: $text-layout-header;
		}
	}

	&__nodata {
		color: black;
		margin: 0 auto;
		max-width: 1200px;
	}
}
