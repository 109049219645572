@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/player";

.course_page {
	@include nodata;
	margin-top: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: black;

	@include media-extra-small-down() {
		margin-top: 0;
	}

	@include media-extra-small-only2() {
		margin-top: 0;
	}

	&__box {
		display: grid;
		grid-template-columns: minmax(880px, 1220px);
		margin-right: 72px;
		margin-left: 72px;
		margin-bottom: 56px;

		@include media-extra-small-down() {
			grid-template-columns: 1fr;
			margin-right: 0;
			margin-left: 0;
			margin-bottom: 24px;
		}

		@include media-extra-small-only2() {
			grid-template-columns: 1fr;
			margin-right: 0;
			margin-left: 0;
			margin-bottom: 24px;
		}
	}

	&__nodata {
		color: black;
		margin: 0 auto;
		max-width: 1200px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}

	&__container {
		width: 100%;
		overflow: hidden;
	}
}
