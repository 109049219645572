@import "src/styles/colors";
@import "src/styles/variables";

.input_password {
	position: relative;
}

.input_password__input {
	appearance: none;
	outline: none;
	width: 100%;
	outline: 0;
	font-family: $font-family-base;
	font-size: 16px;
	color: $text-input-primary;
	padding: 22px 16px 9px 16px;
	transition: all 0.2s;
	background: $input-background;
	border-radius: 8px;
	border: 1px solid $text-input-primary;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background: $input-background;
		border: 1px solid $text-input-primary;
		-webkit-text-fill-color: $text-input-primary;
		transition: background-color 5000s ease-in-out 0s;
		-webkit-box-shadow: 0 0 0 1000px $input-background inset !important;
	}

	&:focus {
		border: 1px solid $other-outline-gray;
		cursor: text;
	}

	&::placeholder {
		color: transparent !important;
		user-select: none !important;
	}

	&--invalid {
		border: 1px solid $error-main;
	}

	&__show_password {
		position: absolute;
		right: 17px;
		top: 27px;
		transform: translateY(-50%);
		cursor: pointer;
		display: flex;
		align-items: center;
	}
}

.input_password__field_label {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	transform-origin: 0 0;
	transform: translate3d(16px, 17px, 0);
	transition: transform .2s;
	font-family: $font-family-base;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;
	color: $text-input-primary;
	opacity: 0.6;
}

.input_password__input:focus + .input_password__field_label, .input_password__input:-webkit-autofill + .input_password__field_label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.input_password__input:not(:placeholder-shown) + .input_password__field_label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.input_password__input_error {
	padding-top: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	&_box {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&_text {
		font-family: $font-family-base;
		font-size: 14px;
		color: $error-main;
		cursor: default;
	}
}
