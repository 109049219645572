@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/player";
@import "src/styles/mixins";

.player_pdf {
	position: relative;
	overflow: hidden;
	min-height: 640px;
	background-color: $background-player;

	&__document {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&:fullscreen {
		.player_pdf__document {
			max-height: 100vh;
			height: 100%;
		}
	}

	@include media-extra-small-down() {
		max-height: 70vh;
		min-height: auto;
		width: 100vw;
	}

	@include media-extra-small-only2() {
		max-height: 70vh;
		min-height: auto;
		width: 100vw;
	}

	&__document {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		max-height: 640px;
		overflow-y: scroll;
		opacity: 1;
		transition: all .5s ease-in-out;

		@include media-extra-small-down() {
			max-height: 70vh;
			min-height: auto;
			width: 100vw;
		}

		@include media-extra-small-only2() {
			max-height: 70vh;
			min-height: auto;
			width: 100vw;
		}
	}

	&__page {
		margin: 0;

		canvas {
			margin: 0 auto;
		}

		& > div {
			display: none;
		}
	}

	&__alert {
		top: 100px;
	}

	&__hide {
		opacity: 0;
	}
}
