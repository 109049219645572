@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/profile.scss";

.influencers {
	&__content {
		color: $text-general;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		@include media-extra-small-down() {
			padding: 10px;
		}

		&:first-child {
			margin-top: 56px;
		}
	}


	&__section_type {
		margin: 0 0 6px 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		letter-spacing: 0.06em;
		color: $primary;
	}

	&__section_label {
		margin: 0 0 32px 0;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
		color: $text-general;

		&__dark {
			color: $background-layout;
		}

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&__tiles_section {
		display: flex;
	}

	&__influencer_tile {
		margin-right: 32px;
	}
}
