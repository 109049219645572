@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.profile_purchases {
		@include status;
		@include cell_name;
		@include cell_avatar;
		@include cell_text;

		padding: 4vh 5vw;

		@include media-extra-small-down() {
			padding-top: 2vh;
		}

		&__header {
				display: flex;
				align-items: center;
				justify-content: center;

				@include media-extra-small-down() {
						padding: 0 16px;
						justify-content: space-between;
				}

				@include media-extra-small-only2() {
						padding: 0 32px;
				}
		}

		&__title {
				font-family: $font-family-base;
				font-size: 36px;
				font-weight: 700;
				line-height: 36px;
				margin-right: auto;

				@include media-extra-small-down() {
					font-size: 26px;
					font-weight: 500;
					line-height: 24px;
					margin-right: 10px;
				}

		}

		&__tooltip {
			transform: translate(20px, -70px);
		}

		&__play_icon {
			path {
				stroke: $common-black;
			}
		}

		&__cell_picture {
			margin-right: 12px;
		}

		&__filters_group {
			display: flex;
		}

		&__export_button {
			color: $text-primary;
			background-color: $primary;
			margin-left: 8px;
		}

		&__search {
				margin-right: 24px;
				min-width: 413px;

				@include media-extra-small-down() {
						min-width: auto;
						margin-right: 16px;
						flex: 1;
				}

				@include media-extra-small-only2() {
						min-width: 170px;
						margin-right: 16px;
				}
		}

		&__body {
				padding: 28px 0;

				@include media-extra-small-down() {
						padding: 24px 0;
				}

				@include media-extra-small-only2() {
				}
		}

		&__table {
				td {
						&:nth-child(1) {
							width: 5%;
    						padding: 0 18px;
					}
						&:nth-child(2) {
							padding-left: 20px;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							width: 65%;
							white-space: break-spaces;
							padding-right: 18px;
						}
						&:nth-child(3) {
								width: 10%;
								padding-right: 18px;
						}
						&:nth-child(4) {
								max-width: 200px;
								width: 10%;
						}

						&:nth-child(5) {
							max-width: 200px;
							width: 10%;
							padding-right: 18px;
						}


						@include media-extra-small-only2() {
								&:nth-child(1) {
										padding-left: 24px;
								}

								&:nth-child(2) {
										padding-right: 24px;
								}

								&:nth-child(3) {
										padding-right: 24px;
								}
						}
				}
		}
}

.filter_menu {
	width: fit-content;
}

.icon_disabled {
	pointer-events: none;
	opacity: 0.5;
}

