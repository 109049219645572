@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.goals_item {
	color: $text-tiles;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 10px;
	column-gap: 16px;

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 16px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
	}

	&__color {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: $primary-red;
		min-width: 16px;
	}

	&__name {
		@include ellipsis();
		color: $text-tiles;
	}

	&__weight {
		color: $text-tiles;
		margin-left: auto;
	}

	&__wrap_one {
		width: calc(100% - 74px);
	}

	&__wrap_two {
		column-gap: 10px;
		min-width: 58px;
	}

	&__icon {
		cursor: pointer;
	}

	&__icon_remove {
		width: 24px;
		height: 24px;

		& path {
			stroke: $text-tiles;
		}
	}

	&__icon_edit {
		& path {
			fill: $text-tiles;
		}
	}
}
