@import "src/styles/colors.scss";

.status_filter {
		display: flex;
		align-content: center;
		justify-content: center;
		width: 100%;
		border-radius: 0;

}
