@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.report_page {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;

	&__loading {
		min-height: 300px;
	}

	&__body {
		height: 100%;
		background-color: $background-grid;
		color: $text-grid;
		min-height: 100vh;
		border-radius: 0 0 16px 16px;
		padding: 0 16px 16px 16px;
	}

	&__form_field {
		width: 100%;
	}

	&__fields_wrap {
		width: 100%;
		border: 1px solid $background-layout;
		border-radius: 16px;
		padding: 24px;
		max-width: 75%;

		@include media-extra-small-down() {
			padding: 12px;
			max-width: none;
		}

		@include media-extra-small-only2() {
			padding: 12px;
			max-width: none;
		}
	}

	&__frame {
		margin: auto;
		overflow-y: auto;
		display: block;
		border: none;
		width: 100%;
		height: 100%;
		min-height: 100vh;
	}
}
