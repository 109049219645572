@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/adaptive";

.image_cropper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	&__image {
		max-height: 60vh;
		max-width: 60vw;

		@include media-small-down {
			max-height: 55vh;
			max-width: 100%;
		}
	}

	&__image_container {
		display: flex;
	}

	&__button_container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 10px 0;
	}


}

