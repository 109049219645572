@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/player";

.player_control_mini {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $common-black 100%);
	height: 62px;
	transition: bottom 0.5s;
	z-index: 100;

	&__hidden {
		bottom: -62px;
	}

	&__btn {
		background-color: transparent;
		cursor: pointer;
		padding: 0;
		border: none;
		width: 24px;
		height: 24px;
		z-index: 1;
	}

	&__btn_volume {
		position: relative;
	}

	&__btn_volume:hover &__volume_level {
		display: flex;
	}

	&__btn_fullscreen {
		margin-left: auto;
	}

	&__icon_compress {
		height: 18px;
		width: 18px;

		path {
			fill: $common-white;
		}
	}

	&__progress_wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-bottom: 12px;
	}

	&__progress_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__progress_box_right {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 20px;

		@include media-extra-small-down() {
			column-gap: 7px;
		}
	}

	&__progress_inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 20px;

		@include media-extra-small-down() {
			column-gap: 7px;
		}
	}

	&__progress {
		width: 100%;
		height: 4px;
		cursor: pointer;
		color: $primary;
		background: $background-gray;

		&::-webkit-progress-value {
			background: $primary;
			border-radius: 4px;
		}

		&::-moz-progress-bar {
			background: $background-gray;
			border-radius: 4px;
		}
	}

	&__timecode {
		cursor: default;
	}

	&__text {
		font-family: $font-family-base;
		font-size: 16px;
		color: $common-white;
	}

	&__indent {
		margin-right: 5px;
	}

	&__volume_level {
		position: absolute;
		left: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5px);
		border-radius: 8px;
		align-items: center;
		justify-content: flex-end;
		width: 113px;
		height: 36px;
		transform-origin: center;
		padding-right: 11px;
		transform: rotate(-90deg) translate(31%, -125%);
		z-index: -1;
		display: none;
	}

	&__volume_range {
		width: 68px;
		height: 4px;
		align-items: baseline;
		display: flex;
		margin: 0;
		padding: 0;
		cursor: pointer;
		-webkit-appearance: none;

		&:focus {
			outline: none;
		}

		&::-webkit-slider-runnable-track {
			width: 100%;
			height: 3px;
			cursor: pointer;
			box-shadow: 0 0 0 $common-black;
			background: $common-white;
			border-radius: 2px;
			border: 0 solid $common-black;
		}

		&::-webkit-slider-thumb {
			box-shadow: 0 0 1px $common-black;
			border: 0 solid $common-black;
			height: 10px;
			width: 10px;
			border-radius: 17px;
			background: $common-white;
			cursor: pointer;
			-webkit-appearance: none;
			margin-top: -3px;
		}

		&:focus::-webkit-slider-runnable-track {
			background: $common-white;
		}

		&::-moz-range-track {
			width: 100%;
			height: 2px;
			cursor: pointer;
			box-shadow: 0 0 0 $common-black;
			background: $common-white;
			border-radius: 2px;
			border: 0 solid $common-black;
		}

		&::-moz-range-thumb {
			box-shadow: 0 0 1px $common-black;
			border: 0 solid $common-black;
			height: 10px;
			width: 10px;
			border-radius: 17px;
			background: $common-white;
			cursor: pointer;
		}

		&::-ms-track {
			width: 100%;
			height: 2px;
			cursor: pointer;
			background: transparent;
			border-color: transparent;
			color: transparent;
		}

		&::-ms-fill-lower {
			background: $common-white;
			border: 0 solid $common-black;
			border-radius: 4px;
			box-shadow: 0 0 0 $common-black;
		}

		&::-ms-fill-upper {
			background: $common-white;
			border: 0 solid $common-black;
			border-radius: 4px;
			box-shadow: 0 0 0 $common-black;
		}

		&::-ms-thumb {
			box-shadow: 0 0 1px $common-black;
			border: 0 solid $common-black;
			height: 10px;
			width: 10px;
			border-radius: 17px;
			background: $common-white;
			cursor: pointer;
		}

		&:focus::-ms-fill-lower {
			background: $common-white;
		}

		&:focus::-ms-fill-upper {
			background: $common-white;
		}
	}

	&__slider {
		cursor: pointer;
	}

	&__disabled {
		pointer-events: none;
		opacity: 0.4;
	}

	&__btn_box {
		position: absolute;
		top: 0;
		height: calc(100% - 62px);
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.5s;
		z-index: 10;
	}

	&__btn_box__left {
		left: 0;
		@include playerArrows();
	}

	&__btn_box__right {
		right: 0;
		@include playerArrows();
	}

	&__icon_nav {
		width: 50px;
		height: 50px;
	}

	&__btn_skip {
		width: 50px;
		height: 50px;
	}

	&__btn_box__left_hidden {
		left: -50px;
	}

	&__btn_box__right_hidden {
		right: -50px;
	}
}

.arrow_tooltip {
	&__right {
		width: max-content;
		max-width: 320px;
		transform: translate(-100%, -80px);
	}
	&__left {
		width: max-content;
		max-width: 320px;
		transform: translate(50px, -80px);
	}

	&__content {
		min-width: fit-content;
	}
}
