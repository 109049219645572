@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.cms_spreadsheet {
    &__table {
        td[data-column-id="selection"] {
            max-width: 3%;
            min-width: 35px;
            padding-left: 10px;
        }

        td[data-column-id="accountName"] {
            max-width: 200px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

		td[data-column-id="author"] {
            max-width: 350px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

        td[data-column-id="name"] {
            width: 40%;
            max-width: 200px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

        td[data-column-id="tags"] {
            max-width: 250px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

        td[data-column-id="companyName"] {
            max-width: 200px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

        td[data-column-id="courseTitle"] {
            max-width: 400px;
            padding-left: 10px;

            @include media-extra-small-only2() {
                width: auto;
                padding-left: 5px;
            }
        }

        td:first-child[data-column-id="name"] {
            padding-left: 24px;
        }

        td[data-column-id="details"] {
            div {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                -webkit-box-pack: center;
                white-space: break-spaces;
                max-height: 85px;
                padding-right: 24px;
                cursor: default;
            }

            @include media-extra-small-only2() {
                display: none;
            }
        }

        td[data-column-id="last_updated"] {
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        td[data-column-id="updated"] {
            max-width: 200px;
            padding-right: 32px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 24px;
            }
        }

        td[data-column-id="price"] {
            cursor: default;
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
        }

        td[data-column-id="title"] {
            padding-left: 20px;
        }

        td[data-column-id="firstName"] {
            max-width: 200px;
            padding-left: 20px;
        }

        td[data-column-id="company"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        td[data-column-id="roles"] {
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        td[data-column-id="registringDate"] {
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        td[data-column-id="isActive"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 12px;
            }
        }

        td[data-column-id="email"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 12px;
            }
        }

        td[data-column-id="Activity"] {
            max-width: 200px;
            padding-right: 24px;
            padding-left: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 12px;
                padding-left: 12px;
            }
        }

        td[data-column-id="account"] {
            max-width: 200px;
        }



        tr[data-column-id="email"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        tr[data-column-id="company"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        tr[data-column-id="roles"] {
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        tr[data-column-id="registringDate"] {
            max-width: 200px;
            width: 10%;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 32px;
            }
        }

        tr[data-column-id="isActive"] {
            max-width: 200px;
            padding-right: 24px;
            cursor: default;

            @include media-extra-small-only2() {
                padding-right: 12px;
            }
        }


        th[data-column-id="firstName"] {
            padding-left: 20px;
        }

        th[data-column-id="selection"] {
            padding-right: 18px;
        }

        th[data-column-id="name"] {
            padding-left: 10px;

            @include media-extra-small-down() {
                display: none;
            }

            @include media-extra-small-only2() {
                display: none;
            }
        }

        th[data-column-id="date"] {
            padding-left: 12px;
        }

        th[data-column-id="account"] {
            max-width: 200px;
        }

        th:first-child[data-column-id="name"] {
            padding-left: 24px;
        }

        th[data-column-id="details"] {
            padding-left: 5px;
        }
    }

    &__cell_icon_wrap {
        margin-right: 24px;
    }

    &__cell_title {
        font-family: $font-family-base;
        font-weight: 500;
        font-size: 16px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 72px;
        white-space: break-spaces;
        flex: 1;
        overflow: hidden;
    }

    & #{&}__tooltip {
        left: 50%;
        top: calc(100% + 4px);
        transform: translateX(-46%);
    }

    &__loading {
        min-height: 300px;
    }

    &__footer {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $input-background;
        color: $text-input-primary;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 0 0 16px 16px;
        position: relative;

        @include media-extra-small-down() {
            border-radius: 0;
            background-color: $input-background;
        }

        @include media-extra-small-only2() {
            border-radius: 0;
        }
    }

    &__no_data {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 24px;
		line-height: 24px;
		color: $text-layout-header;
		border: none;
		text-transform: uppercase;
		margin-left: 9%;

	}
}

