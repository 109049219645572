@import "styles/variables";
@import "styles/fonts";
@import '../node_modules/modern-normalize/modern-normalize.css';

html, body {
	margin: 0;
	padding: 0;
	font-size: $font-size-base;
	font-family: $font-family-base;
}

* {
	box-sizing: border-box;
}

:root {
	--text-general: #151515;
	--text-primary: #ffffff;
	--text-secondary: #B6B6B6;
	--text-layout-header: #FFFFFF;
	--text-table-header: #FFFFFF;
	--text-input-primary: #FFFFFF;
	--text-tiles: #FFFFFF;
	--text-dropdown-hover: #FFFFFF;

	--background-navbar: #151515;
	--background-tile: #151515;
	--background-layout: #e7e7e7;
	--background-profile-body: #ffffff;
	--background-layout-header: #212121;
	--background-dropdown-hover: #B6B6B6;

	--primary: #e50914;
	--secondary: #151515;
	--avatar-empty: #9020E8;

	--navbar-home: #e50914;
	--navbar-desk: #27de6a;
	--navbar-peers: #C60CF4;
	--navbar-influencers: #0ab3ff;
	--navbar-cms: #ff9721;
	--navbar-myData: #EF5DA8;
	--navbar-reports: #D462FD;

	--input-background: #333333;

	--comment-colors: #FF0000, #169CDE, #FF9721, #00B843, #C60CF4;

	--grid: #FFFFFF;
	--text-grid: #151515;

}
