.logo {
		user-select: none;
		cursor: default;
		max-width: 20vw;
		height: 100%;
    	width: 100%;
		margin: 0;

		&__img {
				width: 100%;
				height: 100%;
				object-fit: contain;
		}

		&__cursor_on {
				cursor: pointer;
		}
}
