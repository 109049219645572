@import "src/styles/colors.scss";
@import "src/styles/variables.scss";

.button_bookmark {
	&__bookmark_icon {
		&__bookmarkable {
			cursor: pointer;
			&:hover {
				scale: $icons-hover-scale
			}
		}

		path {
			stroke: $text-tiles;
		}

		&__bookmarked {
			path {
				fill: $primary;
				stroke: $primary;
			}
		}

		&__dark {
			path {
				stroke: $background-tile;
			}

			&__bookmarked {
				path {
					fill: $primary;
					stroke: $primary;
				}
			}
		}

		&__secondary {
			path {
				stroke: $secondary;
			}
		}
	}
}
