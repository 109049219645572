$font-size-base: 14px;
$font-family-base: "Roboto", Arial, sans-serif;

$content-width-max: 1930px;
$content-width-min: 320px;

$navbar-height: 72px;

$table-chapter-row-height: 40px;

$private-header-height: 160px;
$mobile-header-height: 84px;

$carousel-slide-width: 400px; // should be the same as the tile width

$comment-attachment-pic-height: 133px;
$comment-attachment-pic-height-mobile: 90px;

$button-hover-opacity: 0.7;
$icons-hover-scale: 1.15;
$tiles-hover-scale: 1.014;

$player-arrows-dimension: 50px;
