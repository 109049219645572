@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.loading_indicator {
	position: relative;
	height: 100%;
	width: 100%;

	&__fade {
		position: absolute;
		min-height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 999;
		display: flex;
		justify-content: center;

	}

	&__fullscreen {
		height: 100vh;
	}

	&__spinner_center {
		> .loading_indicator__fade {
			align-items: center;
		}

		.loader {
			margin-top: 5px;
		}
	}

	&__spinner_top {
		> .loading_indicator__fade {
			align-items: flex-start;
		}
	}

	&__spinner_fixed {
		> .loading_indicator__fade {
			position: fixed;
			top: 50%;
		}
	}

	&__bg_none {
		> .loading_indicator__fade {
			background: none;
		}
	}

	&__bg_default {
		> .loading_indicator__fade {
			background-color: rgba(255, 255, 255, 0.7);
		}
	}

	&__bg_white {
		> .loading_indicator__fade {
			background: white;
		}
	}

	&__bg_blur {
		> .loading_indicator__fade {
			backdrop-filter: brightness(0.7) blur(3px);
		}
	}

	&__bg_light_gray {
		> .loading_indicator__fade {
			background: $background-gray;
		}
	}

	&__bg_theme_dark {
		> .loading_indicator__fade {
			background-color: rgba($background-dark, 0.4);
		}
	}

	&__bg_theme_light {
		> .loading_indicator__fade {
			background-color: rgba($background-gray, 0.3);
		}
	}

}

.loader {
	display: inline-block;
	margin-top: 20%;

	&:after {
		content: " ";
		display: block;
		width: 30px;
		height: 30px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid $ocean-blue;
		border-color: $ocean-blue transparent $ocean-blue transparent;
		animation: loader 1.2s linear infinite;
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
