@import "src/styles/colors.scss";

.external_api_login_button {
	background-color: $background-white !important;
	height: 48px !important;
	width: 100% !important;
	border-radius: 8px;
	border: 1px solid $text-primary;
	text-transform: uppercase;
	margin-top: 12px;
}

