@import "src/styles/colors.scss";
@import "src/styles/adaptive";

.filter_button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	width: 100%;
	height: 35px;
	background-color: $input-background;
	border: none;
	padding: 0 8px 0 10px;

	@include media-extra-small-down() {
		margin: 2px 0;
		width: 100%;
		justify-content: space-between;
	}

	@include media-extra-small-only2() {
		margin: 2px 0;
		width: 100%;
		justify-content: space-between;
	}
}

.arrow_up {
	path {
		stroke-width: 2;
		stroke: $text-input-primary;
	}
	transform: rotate(90deg);
}

.arrow_down {
	path {
		stroke-width: 2;
		stroke: $text-input-primary;
	}

	transform: rotate(270deg);

}

