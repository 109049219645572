@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";

.cms_table {
	width: 100%;
	color: $text-input-primary;
	padding-top: 12px;
	border-radius: 16px 16px 0 0;
	border-spacing: 0;
	border-collapse: collapse;
	background-color: $input-background;

	@include media-extra-small-down() {
		display: none;
	}

	@include media-extra-small-only2() {
		border-radius: 0;
	}

	&__head {
		position: inherit;
	}

	&__head_column {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 12px;
		color: $text-input-primary;
		text-transform: uppercase;
		text-align: left;
		padding: 12px 0;
	}


	&__row {
		background-color: $background-grid;
		border-bottom: 2px solid $background-layout;
		position: relative;

		> td {
			color: $text-grid;
			background-color: $background-grid;
		}
	}

	&__row_hoverable {
		&:hover {
			background-color: $input-background;

			> td, > td a {
				color: $text-input-primary;
				background-color: $input-background;
			}
		}
	}

	&__disabled_cursor {
		cursor: default;
	}

	&__cell {
		padding: 10px 5px 10px 0;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: $text-grid;
		overflow-wrap: anywhere;
		@include ellipsis();

		&:first-child {
			overflow: unset;

			@include media-extra-small-only2() {
				border-left: none;
			}
		}

		&:last-child {
			@include media-extra-small-only2() {
				border-right: none;
			}
		}
	}


	&__cell_picture {
		width: 114px;
		height: 76px;
		margin-right: 16px;
	}

	&__cell_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $common-white;
	}

	&__cell_icon_wrap {
		margin-right: 24px;
	}

	&__cell_title {
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		color: $text-general;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 72px;
		white-space: break-spaces;
		flex: 1;
		overflow: hidden;
	}

	&__mobile {
		@include media-extra-small-down() {
			display: block;
		}
	}

	&__outline {
		border-radius: 16px;
		background-color: $background-grid;
	}

	&__hide_chapter {
		display: none;
	}

	&__row_select {
		background-color: $background-layout;

		& > td {
			background-color: $background-layout;
		}
	}

	th:nth-child(1) {
		padding-left: 24px;
	}
}

.expand_cell {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.expand_text {
	overflow-wrap: anywhere;
}


.show_more_btn {
	color: $sky-blue;
	font-weight: 500;
	padding: 0;
	margin-top: 5px;
}

.arrow {
	cursor: pointer;

	path {
		stroke: $common-black;
	}

}

.arrow_up {
	transform: rotate(90deg);
}

.arrow_down {
	transform: rotate(270deg);
}

.arrow_disabled {
	opacity: 0.5;
	cursor: default;
}

.chapter_row {
	width: 100%;
	border: 1px solid $background-gray;
	background-color: $common-gray-light;
	height: $table-chapter-row-height;
}


.chapter_cell {
	width: 99.9% !important;
	height: $table-chapter-row-height;
	color: $text-general;
	border: 1px solid $background-gray;
	position: absolute;
	padding: 10px;
	background-color: $common-gray-light;
	display: flex;
	justify-content: space-between;

}

.chapter_control_container {
	display: flex;
}

.chapter_name {
	cursor: pointer;
	font-weight: 500;
	margin-left: 20px;

	&__disable_cursor {
		cursor: default;
	}
}

.delete_icon {
	path {
		stroke: $secondary;
	}

	cursor: pointer;
}

.archive_icon {
	cursor: pointer;
	transform: scale(0.9);
}

.chapter_icons_container {
	width: 8%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
