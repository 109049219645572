@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";
@import "src/styles/cms";

.cms_courses {
	@include status;
	@include cell_name;

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		@include media-extra-small-down() {
			padding: 0 16px;
			justify-content: space-between;
		}

		@include media-extra-small-only2() {
			padding: 0 32px;
			align-items: flex-start;
			flex-wrap: wrap;
			justify-content: flex-end;
		}
	}

	&__title {
		font-family: $font-family-base;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		margin-right: auto;

		@include media-extra-small-down() {
			display: none;
		}

	}



	&__btn_add_mobile {
		display: none;

		@include media-extra-small-down() {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			@include primary_button();
			align-self: flex-end;
			margin-left: 10px;
		}

	}

	&__body {
		padding: 28px 0;

		@include media-extra-small-down() {
			padding: 24px 0;
		}
	}


	&__cell_picture {
		width: 114px;
		height: 76px;
		margin-right: 16px;
		min-width: 114px;
	}

	&__cell_image {
		@include ellipsis();
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $text-grid;
		font-size: 8px;
		min-height: 100%;
		max-width: 114px;
		min-width: 114px;
	}

	&__cell_name {
		cursor: pointer;
	}

	&__cell_title {
		@include ellipsis();
	}

	&__checkbox {
		display: flex;
		align-content: center;
		justify-content: center;
	}

	&__bulk {
		margin-right: 10px;
	}
}

a.btn_add {
	text-decoration: none;
	@include primary_button();
	@include hoverable();
	padding: 8px 24px;

	@include media-extra-small-down() {
		display: none;
	}
}

.delete_icon {
	padding: 5px;
	font-size: 20px;
	cursor: pointer;
}
