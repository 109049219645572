@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";
@import "src/styles/notifications";

.bulk_assignment_popup {
	width: 100%;

	&__recipients {
		width: 40vw;
		max-width: 50vw;
		display: flex;

		@include media-extra-small-down() {
			max-width: 80vw;
		}

		@include media-extra-small-only2() {
			max-width: 70vw;
		}

	}


}
