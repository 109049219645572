@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/tile";
@import "src/styles/mixins";

.influencer_tile {
	@include common-tile();
	display: grid;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: unset;
	padding: 18px 0;


	&__default {
		height: 500px;
		grid-template-rows: 10px 216px 1fr;
		grid-template-columns: 100%;
		gap: 18px;

		&:hover {
			scale: $tiles-hover-scale;
		}
	}

	&__horiz {
		min-width: 100%;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 54px 1fr 104px 30px;
		height: 500px;
	}

	&__vert {
		grid-template-rows: 10px 0.3fr 1fr;
		grid-template-columns: 100%;
		gap: 18px;
		height: 1032px;
		width: 100%;
	}

	@include media-extra-small-down() {
		width: calc(100vw - 40px);
	}

	@include media-extra-small-only2() {
		margin: 0 auto;
	}

	&__header {
		padding-left: 16px;
		font-weight: bold;
		font-size: 18px;

		&__horiz {
			padding-left: 0;
		}
	}

	&__picture_container {
		padding: 18px 0;
		height: 100%;
		background-color: $background-dark;

		&__default {
			padding: 0;
		}

		&__horiz {
			height: 100%;
			grid-column-start: 1;
			grid-row: 1 / span 2;
			margin: 0 32px 0 0;
			padding: 0;
		}

		&__vert {
			height: 100%;
			padding: 0;
		}

		&__pic {
			width: 100%;
			aspect-ratio: 16/9;
			object-fit: cover;
			cursor: pointer;
		}
	}

	&__info_container {
		height: 100%;
		display: flex;
		flex-direction: column;
		max-width: 100%;

		&__horiz {
			@include ellipsis();
			grid-column: 2/3;
			grid-row: 1/3;
			justify-content: center;
			height: 100%;
			padding: 0 18px 0 0;
		}

		&__vert {
			padding: 0 18px;
		}

		@media screen and (max-width: 400px) {
			max-width: calc(100vw - 40px);
		}
	}

	&__title {
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		text-decoration: none;
		color: $text-tiles;
		min-height: 26px;
		cursor: default;

		@include media-small-down() {
			font-size: 16px;
			line-height: 22px;
		}

		@media screen and (max-width: 400px) {
			max-width: calc(100vw - 40px);
		}

		&__dark {
			color: $text-general;
		}

		&__author {
			font-size: 36px;
			line-height: 43px;
			font-weight: 700;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			white-space: break-spaces;
			padding-bottom: 10px;
			&::-webkit-scrollbar{
				display: none;
			}
		}

		&__description {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			white-space: break-spaces;
			&::-webkit-scrollbar{
				display: none;
			}
		}
	}

	&__clickable {
		width: 100%;
		overflow-x: hidden;
		cursor: pointer;
	}

	&__color_bar {
		width: 100%;
		height: 8px;
		border-radius: 63px;
		margin: 10px 0 12px;

		&__horiz {
			grid-row: 1/2;
			grid-column: 1/-1;
		}
	}

	&__description_section {
		text-align: start;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__vert {
			display: grid;
			grid-template-rows: 1fr 32px;
			grid-template-columns: 1fr;
			gap: 18px;
		}
	}

	&__description {
		display: block;
		height: 100%;
		max-height: 85px;
		overflow-y: auto;
		&::-webkit-scrollbar{
			display: none;
		}

		&__horiz {
			grid-column: 1/-1;
			padding-left: 18px;
			display: block;
			height: 100%;
		}

		&__vert {
			display: block;
			height: 100%;
			max-height: 795px;
		}
	}

	&__stats_section {
		display: flex;
		width: 100%;
		align-items: center;
		align-self: flex-end;
		justify-content: space-between;

		&__horiz {
			grid-row-start: 4;
			grid-column: 1/-1;
			padding: 0 20px;
		}
	}

	&__likes {
		display: flex;
		align-items: center;
	}

	&__like_icon {
		cursor: pointer;
		margin-right: 9px;

		&:hover {
			scale:1.15;
		}

		path {
			fill: $primary;
			stroke: $primary;
		}

		&__disliked {
			path {
				fill: transparent;
				stroke: $text-tiles;
			}
		}

		&__light {
			path {
				stroke: $background-dark;
			}
		}
	}

	&__follow_button {
		height: 32px;

	}
}

.no_image {
	object-fit: none;
}
