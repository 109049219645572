@import "src/styles/colors.scss";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/adaptive";

.select_field {
	position: relative;
}

.select_field__control {
	appearance: none;
	outline: none;
	min-height: 50px;
	background-color: $input-background;
	border: 1px solid $common-black;
	border-radius: 8px;
	display: flex;
}

.select_field__compact {
	height: 45px;
}

.select_field__label {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	transform-origin: 0 0;
	transform: translate3d(16px, 17px, 0);
	transition: transform 0.2s;
	font-family: $font-family-base;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-align: left;
	color: $text-input-primary;
	opacity: 0.6;
}

.select_field__control:focus + .select_field__label,
.select_field__control:-webkit-autofill + .select_field__label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.select_field__control:not(:placeholder-shown) + .select_field__label {
	transform: translate3d(16px, 30%, 0) scale(0.8);
}

.select_field__is_error {
	border-color: $error-main;
}

.select_field__error {
	padding-top: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	left: 0;

	&_box {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&_text {
		font-family: $font-family-base;
		font-size: 13px;
		color: $error-main;
		cursor: default;
	}
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
