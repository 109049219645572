@import "src/styles/colors.scss";
@import "src/styles/adaptive.scss";
@import "src/styles/variables.scss";

.adv_search {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;

	@include media-extra-small-down() {
		width: 100vw;
	}

	@include media-extra-small-only2() {
		width: 100vw;
	}


	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: $background-layout-header;
		width: 100%;
		height: 100%;
		padding: 10px;
	}


	&__results {
		flex-direction: column;
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	&__submenu_row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		width: 100%;
		padding-top: 12px;
		flex-direction: column;

		@include media-extra-small-down() {
			flex-direction: row;
			justify-content: flex-start;
			padding: 0;
		}
	}

	&__submenu {
		@include media-extra-small-down() {
			padding-left: 10px;
		}
	}

	&__submenu a {
		@include media-extra-small-down() {
			text-align: center;
		}
	}

	&__new_tab_toggler_container {
		display: flex;
		align-items: center;
		margin-right: 20px;
		color: $text-layout-header;
	}

	&__new_tab_toggler {
		margin-left: 10px;
	}

	&__top_controls_container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-right: 5%;
	}

	&__close_icon {
		cursor: pointer;
		margin-top: 3px;
		width: 24px;
		height: 24px;
		transition: transform .3s ease-in-out;

		path {
			stroke: $text-layout-header;
		}

		&:hover {
			transform: rotate(180deg);
		}

		@include media-extra-small-down() {
			top: 10px;
			right: 10px;
		}

		@include media-extra-small-only2() {
			top: 10px;
			right: 10px;
		}
	}

	@include media-extra-small-down() {
		flex-wrap: wrap;
	}

	@include media-small-only() {
		flex-wrap: wrap;
	}

	@include media-extra-small-only2() {
		flex-wrap: wrap;
	}

}
