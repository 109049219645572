@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.myDesk {
	width: 100%;
	height: 100%;
	color: $text-general;
	overflow: hidden;

	&__course_card_container {
		display: flex;
		justify-content: center;
		margin: 32px 0 15px;
		transition: all 500s ease-in-out;

		@include media-small-down() {
			margin-top: 0;
		}

		@include media-extra-small-down() {
			margin-top: 0;
		}
	}



	&__courses_section {
		margin-top: 30px;

		@include media-small-down() {
			margin-top: 20px;
		}

		@include media-extra-small-down() {
			margin-bottom: 10px;
		}
	}


	&__section_label {
		margin: 0 0 32px 0;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&__tiles_section {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
	}

	&__carousel_section {
		flex-direction: column;
	}

	&__carousel_tile {
		margin-right: 32px;
		@include media-small-down() {
			margin-right: 0;
		}
	}

	&__wrap {
		display: grid;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		grid-template-columns: minmax(800px, 1fr) minmax(266px, 460px);
		position: relative;

		@include media-extra-small-down() {
			grid-template-columns: 1fr;
		}

		@include media-extra-small-only2() {
			grid-template-columns: 1fr;
		}

		@include media-compact-only() {
			grid-template-columns: 1fr;
		}
	}

	&__wide_container {
		display: flex;
		width: 100%;
		position: relative;
	}

	&__nav_container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 24px;
		background-color: $background-layout-header;

		@include media-small-down() {
			padding: 0 12px;
		}
	}

	&__link {
		padding: 12px;
		display: flex;
		text-decoration: none;
		font-weight: 500;
		font-size: 20px;
		text-align: start;
		letter-spacing: 0.06em;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		color: $text-layout-header;
	}

	&__back_btn_label {
		width: 150px;
	}

	&__stack_icon {
		path {
			fill: $text-layout-header;
		}
	}

	&__text {
		margin: 0;
	}
}


.chapters_list {
	&__chapters_section {
		@include scrollbar(5px, $primary);
		display: flex;
		width: 65%;
		margin: 0 30px;
		padding-top: 10px;
		overflow-x: auto;

		@include media-medium-only() {
			width: 55%;
		}

		@include media-middle-only() {
			width: 45%;
		}

		@include media-small-down() {
			max-width: 70%;
			margin: 0 10px;
		}


	}

	&__chapter_wrapper {
		position: relative;
	}

		&__chapter_preview_image {
		width: 114px;
    	height: 74px;
		border: 1px solid $text-layout-header;
		background-color: $text-layout-header;
		margin-right: 20px;
		object-fit: cover;
		cursor: pointer;

		&:hover {
			transform: scale(1.05);
		}
	}

	&__inactive_chapter {
		opacity: 0.7;
	}

	&__completed_chapter {
		border: 3px solid $primary-green;
	}

	&__check_icon {
		position: absolute;
    top: 5px;
    right: 26px;
	}

	&__chapter_tile {
		position: absolute;
		z-index: 1000;

		@include media-small-down() {
			left: 40%;
		}

		@include media-extra-small-down() {
			left: 10px;
		}
	}
}
