@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/profile.scss";

.history {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	width: 100%;
	overflow: hidden;

	@include media-extra-small-down() {
		width: 100%;
	}


	&__content {
		color: $text-general;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		@include media-extra-small-down() {
			padding: 10px;
		}
		&:first-child {
			margin-top: 20px;
		}
	}

	&__lessons_section {
		margin-bottom: 56px;
		padding: 0 70px;
		@include media-small-down() {
			padding: 0 32px;
		}

		@include media-extra-small-down() {
			padding: 0 16px;
			margin-bottom: 32px;
		}
	}

	&__section_label {
		margin: 0 0 32px 0;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;

		@include media-extra-small-down() {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&__tiles_section {
		display: flex;
	}

	&__lesson_tile {
		margin-right: 32px;
		min-height: 500px;
	}
}
