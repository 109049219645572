@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";

.peers_list {
	color: black;

	&__compact {
		overflow-y: auto;
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		padding: 8px 8px 8px 4px;
	}

	&__nocomments {
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		padding-top: 8px;
		padding-bottom: 8px;
	}
}
