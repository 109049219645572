@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/adaptive";

.toggler_activation {
	&__wrap {
		width: 50px;
	}

	& #{&}__content {
		min-width: 85px;
		text-align: center;
	}

	&__theme_left {
		top: -100%;
		transform: translateY(50%);
		left: -180%!important;
	}

	&__theme_top {
		top: -150%;
		transform: translateX(-50%);
		left: 50%!important;
	}

	&__theme_default {
		top: -150%;
		transform: translateX(-50%);
		right: 50%;
	}
}
