@import "src/styles/colors";
@import "src/styles/adaptive";

.public_layout {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $background-layout;
	color: $text-general;

	@include media-extra-small-down() {
		align-items: flex-start;
	}

	@include media-extra-small-only2() {
		align-items: center;
	}
}
