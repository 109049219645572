@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/animation";

.chapter_roster {
	display: grid;
	grid-template-columns: minmax(880px, 1220px);
	padding-left: 72px;
    padding-right: 72px;
	margin-bottom: 56px;

	&__inTileStyle {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin: 0;
	}

	@include media-extra-small-down() {
		padding: 0 16px 16px 16px;
		margin-bottom: 24px;
		grid-template-columns: minmax(300px, 100vw);
		margin-left: 0;
		margin-right: 0;
	}

	@include media-extra-small-only2() {
		padding: 0 16px 16px 16px;
		margin-bottom: 24px;
		grid-template-columns: minmax(300px, 100vw);
		margin-left: 0;
		margin-right: 0;
	}

	&__title {
		margin: 0;
		padding: 12px 0;
		@include ellipsis();

		@include media-extra-small-down() {
			padding-bottom: 16px;
		}

		@include media-extra-small-only2() {
			padding-bottom: 16px;
		}
	}

	&__row {
		@include fadeIn(1s);
		display: grid;
		justify-content: space-between;
		grid-template-columns: 40px minmax(200px, 1fr) 24px;
		grid-gap: 16px;
		align-items: center;
		cursor: pointer;
		padding: 2px 0;

		&__inactive {
			filter: opacity(38%);
		}

		&__is_new_chapter {
			filter: none;
		}

		@include media-extra-small-down() {
			align-items: flex-start;
			padding: 4px 0;
		}

		@include media-extra-small-only2() {
			align-items: flex-start;
			padding: 4px 0;
		}
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
		font-size: 14px;
		font-weight: 500;
		color: $text-general;

		&__light {
			color: $text-tiles;
		}

		@include media-extra-small-down() {
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}
	}

	&__text {
		@include ellipsis();
		font-size: 16px;
		font-weight: 500;
		color: $text-general;

		&__light {
			color: $text-tiles;
		}
	}

	&__duration {
		white-space: nowrap;
		min-width: 50px;
		text-align: right;

		@include media-extra-small-down() {
			min-width: unset;
		}

		@include media-extra-small-only2() {
			min-width: unset;
		}
	}

	&__author {
		@include ellipsis();
	}

	&__wrap {
		display: grid;
		justify-content: space-between;
		grid-template-columns: minmax(200px, 1fr) 200px;
		gap: 8px;

		@include media-extra-small-down() {
			grid-template-columns: minmax(200px, 1fr);
			gap: 4px;
		}

		@include media-extra-small-only2() {
			grid-template-columns: minmax(200px, 1fr);
			gap: 4px;
		}
	}

	&__check {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__check_icon {
		width: 19px;
		height: 15px;

		path {
			fill: $other-outline-gray;
		}

		&__active {
			path {
				fill: $success-main;
			}
		}
	}

	&__content_icon > div {
		margin: 0;
	}
}

.version_tooltip {
	transform: translate(-100%, -70px);
    width: 300px;
}

.version_tooltip_first {
	transform: translate(-100%, 0);
}
