@import "src/styles/colors";
@import "src/styles/adaptive";
@import "src/styles/variables";
@import "src/styles/mixins";

.chapters_list {
	color: black;
	width: 100%;

	&__header {
		font-weight: 700;
		font-size: 36px;
		color: $text-general;
		margin: 0;
		padding-bottom: 24px;
		padding-left: 70px;

		@include media-extra-small-down() {
			padding-left: 16px;
			padding-bottom: 16px;
			font-size: 24px;
			font-weight: 500;
		}

		@include media-extra-small-only2() {
			padding-left: 16px;
			padding-bottom: 16px;
			font-size: 24px;
			font-weight: 500;
		}
	}

	&__head {
		margin-bottom: 56px;

		@include media-extra-small-down() {
			margin-bottom: 24px;
		}

		@include media-extra-small-only2() {
			margin-bottom: 24px;
		}
	}

	&__chapter_tile {
		margin-right: 32px;
	}

	&__carousel {
		height: 350px;
		margin-bottom: 56px;
		padding: 0 70px;

		@include media-extra-small-down() {
			padding: 0;
			margin-bottom: 24px;
		}

		@include media-extra-small-only2() {
			padding: 0 16px;
			margin-bottom: 24px;
		}
	}
}

.chapters_roster_full {
	color: $text-tiles;
	padding: 5px 10px;

	&__progress_bar {
		display: flex;
		align-items: center;
	}

	&__progress_title {
		margin-right: 10px;
		white-space: nowrap;
	}

	&__list {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		overflow-y: scroll;
		max-height: 450px;
		padding-right: 10px;
	}
}
