@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/cms";

.cms_tile_course {
	@include status;

	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	background-color: $background-white;
	color: $text-general;
	padding: 16px;
	position: relative;
	z-index: 0;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: inline-block;
		z-index: 1;
		border-bottom: 1px solid $background-gray;
	}

	&:first-child {
		border-top: 1px solid $background-gray;
	}

	&__wrap {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		flex: 1;
		max-width: calc(100% - 134px);
	}

	&__cell_picture {
		width: 86px;
		height: 60px;
		margin-right: 16px;
		flex-basis: 102px;
	}

	&__cell_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
		border: solid 1px $common-white;
	}

	&__cell_title {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: break-spaces;
		overflow: hidden;
		overflow-wrap: anywhere;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: 16px;
		color: $text-general;
		margin-bottom: 8px;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
	}

	&__status {
		font-size: 14px;
		padding: 3px 12px;
		margin-right: 12px;
	}

	&__icon_wrap {
		margin-right: 12px;
		width: 24px;
		height: 24px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__date {
		@include ellipsis;
		font-family: $font-family-base;
		font-size: 14px;
		color: $text-secondary;
	}

	&__price {
		margin-left: 10px;
	}
}
