@import "src/styles/adaptive.scss";

.goals {
	padding: 4vh 10vw;

	@include media-extra-small-down() {
		padding: 0 16px;
	}

	@include media-extra-small-only2() {
		padding: 0 16px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 24px;
		width: 100%;

		@include media-extra-small-down() {
			flex-direction: column;
		}

		@include media-extra-small-only2() {
			flex-direction: column;
		}

		@include media-compact-only() {
			flex-direction: column;
			gap: 24px;
		}
	}
}
