@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/adaptive";
@import "src/styles/mixins";

.course_card_assignments {
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
	animation-name: fadeIn;
	animation-duration: 0.7s;

	&__no_data {
		width: 100%;
		font-weight: 500;
		font-size: 16px;
		padding-top: 25px;
	}

	&__assignments_container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding-top: 20px;
	}


	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin: 24px 0 12px;

	}

	&__body {
		@include scrollbar(4px, rgba(#DCDCDC, 0.5));
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		max-height: 300px;
		overflow-y: auto;

		@include media-extra-small-down() {
			flex-direction: column;
		}

	}

	&__assignment {
		display: flex;
		align-items: center;
		padding: 4px;
		width: 220px;

		@include media-extra-small-down() {
			width: calc(100% - 32px);
		}

		@include media-extra-small-only2() {
			width: 50%;
		}
	}

	&__checkbox {
		outline: 2px solid $common-gray;
		border-radius: 2px;
		background: transparent;

		&_requested {
			outline: 2px solid $primary;
		}

		&_payed {
			outline: 2px solid $common-gray;
		}
	}

	&__avatar {
		margin: 0 10px;
	}

	&__label {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 22px;
		line-height: 36px;
	}

	&__assignment_name {
		overflow-x: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		@include media-extra-small-down() {
			width: 250px;
		}

		@include media-extra-small-only2() {
			min-width: 250px;
		}
	}

	&__info_icon {
		margin: 0 10px;

		path {
			fill: $other-outline-gray;
		}
	}

	&__info_tooltip {
		width: max-content;
		transform: translate(10%, -60%);

		@include media-extra-small-down() {
			width: 50vw;
		}
	}

	&__button_icon {
		path {
			stroke: $text-tiles;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 8px;
		max-width: 100%;

		@include media-extra-small-down() {
			grid-template-columns: repeat(1, calc(100vw - 32px));
		}

		@include media-extra-small-only2() {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-small-only() {
			grid-template-columns: repeat(3, 1fr);
		}

		@include media-medium-only() {
			grid-template-columns: repeat(4, 1fr);
		}

	}
}

.popup {
	&__checkbox {
		display: flex;
		flex-direction: column;

		&_info {
			display: flex;
			align-items: center;
		}

		&_text {
			margin-left: 10px;
		}
	}
}

.inf_scroll {
	overflow-x: hidden;
	&__end_of_list_message {
		text-align: center;
	}
}

