@import "colors";

@mixin header {
	&__header:first-child {
		height: auto;
		padding-top: 40px;

		@include media-extra-small-only2() {
			padding-top: 20px;
		}

		@include media-extra-small-only() {
			padding-top: 10px;
			margin-bottom: 10px;
		}
	}

	&__header_content:first-child {
		flex-direction: column;
		align-items: flex-start;

		@include media-extra-small-down() {
			padding-left: 8px;
			padding-right: 8px;
		}

		@include media-extra-small-only2() {
			padding-left: 16px;
		}
	}

	&__header_label:first-child {
		@include media-extra-small-down() {
			font-size: 24px;
    		line-height: 36px;
    		padding-top: 10px;
    		margin-bottom: 14px;
    		font-weight: 500;
		}
	}
}

@mixin header_line {
	&__header_content {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 87px;
			width: 100%;
			height: 0;
			display: inline-block;
			z-index: 0;
			border-bottom: 1px solid $background-layout;

			@include media-extra-small-down() {
				top: 60px;
			}

			@include media-extra-small-only2() {
				top: 55px;
			}
		}
	}
}

@mixin header_dart {
	&__btn_back_out {
		position: absolute;
		left: 0;
		bottom: 9px;
		padding: 0 0 0 30px;

		@include media-extra-small-down() {
			bottom: unset;
			top: 12px;
			padding: 0 0 0 16px;
		}
	}

	&__icon {
		cursor: pointer;
		path {
			stroke: $text-layout-header;
			filter: invert(0.6) brightness(120%);
		}
	}
}
