$breakpoint-big: 1920px;
$breakpoint-transitional: 1600px;
$breakpoint-medium: 1440px;
$breakpoint-compact: 1280px;
$breakpoint-small: 1024px;
$breakpoint-extra-small: 768px;
$breakpoint-little-mobile: 375px;

$public-layout-margin-big: 100px;
$public-layout-margin-medium: 80px;
$public-layout-margin-small: 45px;
$public-layout-margin-extra-small: 20px;

$private-layout-margin-big: 320px;
$private-layout-margin-medium: 60px;
$private-layout-margin-small: 30px;
$private-layout-margin-extra-small: 16px;

@mixin media-big-only() {
	@media screen and (min-width: $breakpoint-big) {
		@content;
	}
}

@mixin media-medium-only() {
	@media screen and (min-width: $breakpoint-medium) and (max-width: $breakpoint-big - 1px) {
		@content;
	}
}

@mixin media-middle-only() {
	@media screen and (min-width: $breakpoint-compact) and (max-width: $breakpoint-medium - 1px) {
		@content;
	}
}

@mixin media-small-only() {
	@media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1px) {
		@content;
	}
}

@mixin media-compact-only() {
	@media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-compact - 1px) {
		@content;
	}
}

@mixin media-extra-compact-only() {
	@media screen and (max-width: $breakpoint-compact - 1px) {
		@content;
	}
}

@mixin media-extra-small-only() {
	@media screen and (max-width: $breakpoint-small - 1px) {
		@content;
	}
}

@mixin media-extra-small-only2() {
	@media screen and (min-width: $breakpoint-extra-small) and (max-width: $breakpoint-small - 1px) {
		@content;
	}
}

@mixin media-big-down() {
	@media screen and (max-width: $breakpoint-big - 1px) {
		@content;
	}
}

@mixin media-transitional-down() {
	@media screen and (max-width: $breakpoint-transitional - 1px) {
		@content;
	}
}

@mixin media-medium-down() {
	@media screen and (max-width: $breakpoint-medium - 1px) {
		@content;
	}
}

@mixin media-small-down() {
	@media screen and (max-width: $breakpoint-small - 1px) {
		@content;
	}
}

@mixin media-extra-small-down {
	@media screen and (max-width: $breakpoint-extra-small - 1px) {
		@content;
	}
}

@mixin media-little-mobile-down() {
	@media screen and (max-width: $breakpoint-little-mobile - 1px) {
		@content;
	}
}
